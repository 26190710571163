import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import tinycolor from "tinycolor2";

import { getOverviewIntensive, getOverviewConsumption } from "./globalViewSlice";
import { displayTitleFromUnit } from "./components/globalview/GraphicIntensive";
import Palette from "modules/common/components/graphic/Colors";
import { co2ConversionRate, priceConversionRate } from "modules/common/utils";

//only globalview usage
export const display_color = { avg: "#AA8811", min: "#0000AA", max: "#AA0000" };

export const rewriteCalendarType = (calendar_type) => {
    if (_.size(calendar_type) === 1) {
        if (calendar_type[0] === 1) {
            return 1;
        }
        if (calendar_type[0] === 2) {
            return 2;
        }
    }
    return 0;
};

/**
 * Processing data for Radial Chart in globalview
 * @function proccess_area_calculation
 * @param {object} zones - filtered zones by site_id used in request
 * @param {object} measurements - List of measurements from reference request
 * @returns
 */
const proccess_area_calculation = (zones, measurements) => {
    const process_by_area = _.reduce(
        zones,
        (res, zone) => {
            const zone_info = {
                name: zone.name,
                site: zone.site_name,
                id: zone?.id ?? -1 //Put fake id for unspecified zone
            };
            const measures = _.chain(measurements)
                .filter((measure) => measure.zone === zone.id)
                .value();
            const all_total = _.flatMap(measures, "total_consumption");
            const all_total_null = _.every(all_total, (data) => data === null);
            if (all_total_null) {
                res.push({ ...zone_info, total_consumption: null });
            } else {
                res.push({ ...zone_info, total_consumption: _.sum(all_total) });
            }
            return res;
        },
        []
    );

    const global_all_zones_consumptions = _.chain(process_by_area)
        .reduce((res, zone) => {
            if (_.isFinite(zone.total_consumption)) {
                res = res + zone.total_consumption;
            }
            return res;
        }, null)
        .value();

    const radial_series =
        global_all_zones_consumptions !== null
            ? _.reduce(
                  process_by_area,
                  (res, zone) => {
                      if (_.isFinite(zone.total_consumption)) {
                          //Remove negative consumptions. Maybe need to implement Radial for production
                          const percent = _.round((zone.total_consumption / Math.abs(global_all_zones_consumptions)) * 100, 2);
                          const color_idx = zone.id === -1 ? Palette.circles.length - 1 : (zone.id - 1) % Palette.circles.length;
                          if (percent >= 0) {
                              res.push({
                                  id: zone.id,
                                  title: `${zone.name}(${zone.site})`,
                                  zone: zone.name,
                                  site: zone.site,
                                  disabled: false,
                                  angle: percent,
                                  percent,
                                  color: tinycolor(Palette.circles[color_idx]).setAlpha(0.6).toString(),
                                  strokeWidth: 20
                              });
                          }
                      }
                      return res;
                  },
                  []
              )
            : [];

    if (_.every(radial_series, (serie) => serie.percent === 0)) {
        return [];
    }
    return radial_series;
};

/**
 * Processing data for Radial Chart in globalview
 * @function proccess_usage_calculation
 * @param {object} usages - List of usages from server
 * @param {object} measurements - List of measurements from reference request
 * @returns
 */
const proccess_usage_calculation = (usages, measurements) => {
    //add extra usage 'undefined'
    const usages_with_unspecified = [{ name: t`undefined`, id: null }, ...usages];

    const process_by_usage = _.reduce(
        usages_with_unspecified,
        (res, usage) => {
            const usage_info = {
                name: usage.name,
                id: usage?.id ?? -1 //Put fake id for unspecified usage
            };
            const measures = _.chain(measurements)
                .filter((measure) => measure.usage === usage.id)
                .value();
            const all_total = _.flatMap(measures, "total_consumption");
            const all_total_null = _.every(all_total, (data) => data === null);
            if (all_total_null) {
                res.push({ ...usage_info, total_consumption: null });
            } else {
                res.push({ ...usage_info, total_consumption: _.sum(all_total) });
            }
            return res;
        },
        []
    );

    //Process global consumption of all usages
    const global_all_usages_consumptions = _.chain(process_by_usage)
        .reduce((res, usage) => {
            if (_.isFinite(usage.total_consumption)) {
                res = res + usage.total_consumption;
            }
            return res;
        }, null)
        .value();

    //Process each usage for radial display
    const radial_series =
        global_all_usages_consumptions !== null
            ? _.reduce(
                  process_by_usage,
                  (res, usage) => {
                      if (_.isFinite(usage.total_consumption)) {
                          const percent = _.round((usage.total_consumption / Math.abs(global_all_usages_consumptions)) * 100, 2);
                          const color_idx = usage.id === -1 ? Palette.circles.length - 1 : (usage.id - 1) % Palette.circles.length;
                          if (percent >= 0) {
                              res.push({
                                  id: usage.id,
                                  title: usage.name,
                                  disabled: false,
                                  angle: percent,
                                  percent,
                                  color: tinycolor(Palette.circles[color_idx]).setAlpha(0.6).toString(),
                                  strokeWidth: 20
                              });
                          }
                      }
                      return res;
                  },
                  []
              )
            : [];

    return radial_series;
};

/**
 * Processing data for synthesis panel
 * @function process_synthesis_graphic
 * @param {object} store - redux store object
 * @param {object} measurements - List of measurements from reference request
 * @param {object} filter - Selected parameters define by user in frontend
 * @param {object} measurements_compare - List of measurements from compare request
 * @returns
 */
const process_synthesis_graphic = (store, measurements, filter, measurements_compare) => {
    const { tag, category, dataflow, zone, site, measurementtype, unit } = store.getState();
    const { usages, tags, aggregate_type, zones, sites, calendar_type } = filter;
    const usages_list = filter?.usages_list ?? [];
    const site_ids = _.get(filter, "site", []);
    // Possible value: null | 'cost' | 'co2'
    const chosen_filter = _.get(filter, "filter", null);

    const process_measurements = _.chain(measurements)
        .orderBy((item) => {
            const df = _.find(dataflow.dataflows, { id: item?.measure?.dataflow });
            return df?.name;
        })
        .reduce((res, item) => {
            const df = _.find(dataflow.dataflows, { id: item?.measure?.dataflow });
            //retrieve site from globalview filter
            const m_site = _.find(site.sites, { id: df?.site });
            const mt_type = _.find(measurementtype.measurementtypes, { id: item?.measure?.measurementtype });
            const display_unit = _.find(unit.units, { id: item?.measure?.display_unit || mt_type?.unit });
            const conversions = m_site?.conversions ?? null;
            let data_wrap = _.chain(item?.consumptions ?? []);

            let minimum = null;
            let min_day = null;
            let average = null;
            let maximum = null;
            let max_day = null;
            let total = null;
            let total_opening = null;
            let total_closing = null;

            if (data_wrap.size().value() > 0) {
                switch (rewriteCalendarType(calendar_type)) {
                    case 1:
                        //opening
                        total = data_wrap.sumBy((record) => record[1]).value(); // total is total_opening
                        average = data_wrap.meanBy((record) => record[1]).value();
                        minimum = data_wrap
                            .minBy((record) => record[1])
                            .get("[1]", null)
                            .value();
                        min_day = data_wrap
                            .minBy((record) => record[1])
                            .get("[0]", null)
                            .value();
                        maximum = data_wrap
                            .maxBy((record) => record[1])
                            .get("[1]", null)
                            .value();
                        max_day = data_wrap
                            .maxBy((record) => record[1])
                            .get("[0]", null)
                            .value();
                        break;
                    case 2:
                        //closing
                        total = data_wrap.sumBy((record) => record[2]).value(); // total is total_closing
                        average = data_wrap.meanBy((record) => record[2]).value();
                        minimum = data_wrap
                            .minBy((record) => record[2])
                            .get("[2]", null)
                            .value();
                        min_day = data_wrap
                            .minBy((record) => record[2])
                            .get("[0]", null)
                            .value();
                        maximum = data_wrap
                            .maxBy((record) => record[2])
                            .get("[2]", null)
                            .value();
                        max_day = data_wrap
                            .maxBy((record) => record[2])
                            .get("[0]", null)
                            .value();
                        break;
                    default:
                        //total
                        total_opening = data_wrap.sumBy((record) => record[1]).value(); // Need for synthesis table display
                        total_closing = data_wrap.sumBy((record) => record[2]).value(); // Need for synthesis table display
                        total = total_closing + total_opening;
                        data_wrap = data_wrap.map((record) => {
                            if (rewriteCalendarType(calendar_type) === 0) {
                                return [record[0], record[1] + record[2]];
                            } else {
                                return record;
                            }
                        });
                        average = data_wrap.meanBy((record) => record[1]).value();
                        minimum = data_wrap
                            .minBy((record) => record[1])
                            .get("[1]", null)
                            .value();
                        min_day = data_wrap
                            .minBy((record) => record[1])
                            .get("[0]", null)
                            .value();
                        maximum = data_wrap
                            .maxBy((record) => record[1])
                            .get("[1]", null)
                            .value();
                        max_day = data_wrap
                            .maxBy((record) => record[1])
                            .get("[0]", null)
                            .value();
                        break;
                }
            }

            const category_name = _.find(category.categories, { id: df?.dataflowspec })?.name;
            const mt_type_name = mt_type?.name;

            let total_filter = total;
            let total_opening_filter = total_opening;
            let total_closing_filter = total_closing;

            if (chosen_filter === "cost") {
                total_filter = priceConversionRate(total, conversions, category_name, mt_type_name, null, false);
                total_opening_filter = priceConversionRate(total_opening, conversions, category_name, mt_type_name, null, false);
                total_closing_filter = priceConversionRate(total_closing, conversions, category_name, mt_type_name, null, false);
                average = priceConversionRate(average, conversions, category_name, mt_type_name, null, false);
                minimum = priceConversionRate(minimum, conversions, category_name, mt_type_name, null, false);
                maximum = priceConversionRate(maximum, conversions, category_name, mt_type_name, null, false);
            } else if (chosen_filter === "co2") {
                total_filter = co2ConversionRate(total, conversions, category_name, mt_type_name, null, false);
                total_opening_filter = co2ConversionRate(total_opening, conversions, category_name, mt_type_name, null, false);
                total_closing_filter = co2ConversionRate(total_closing, conversions, category_name, mt_type_name, null, false);
                average = co2ConversionRate(average, conversions, category_name, mt_type_name, null, false);
                minimum = co2ConversionRate(minimum, conversions, category_name, mt_type_name, null, false);
                maximum = co2ConversionRate(maximum, conversions, category_name, mt_type_name, null, false);
            }

            const record = {
                id: item?.id,
                name: `${df?.name} ${chosen_filter ? `(${display_unit?.symbol})` : ""}`,
                total: total_filter,
                total_opening: total_opening_filter,
                total_closing: total_closing_filter,
                minimum,
                min_day,
                average,
                maximum,
                max_day,
                category: df?.dataflowspec ?? null,
                usage: df?.usage ?? null,
                zone: df?.zone ?? null,
                site: df?.site ?? null,
                tags: df?.tag_set ?? [],
                ref_consumptions: item?.consumptions ?? [],
                category_name,
                mt_type_name,
                measure: { ...item?.measure, site: m_site }
            };

            if (filter.todo_compare) {
                const measure_comp = _.find(measurements_compare, { id: item?.id });
                const data_comp_wrap = _.chain(measure_comp).get("consumptions", []);

                let total_comp = null;
                let total_opening_comp = null;
                let total_closing_comp = null;
                if (data_comp_wrap.size().value() > 0) {
                    switch (rewriteCalendarType(calendar_type)) {
                        case 1:
                            total_comp = data_comp_wrap.sumBy((record) => record[1]).value();
                            break;
                        case 2:
                            total_comp = data_comp_wrap.sumBy((record) => record[2]).value();
                            break;
                        default:
                            total_comp = data_comp_wrap.sumBy((record) => record[1] + record[2]).value();
                            total_opening_comp = data_comp_wrap.sumBy((record) => record[1]).value();
                            total_closing_comp = data_comp_wrap.sumBy((record) => record[2]).value();
                            break;
                    }
                }

                let total_filter_comp = total_comp;
                let total_opening_filter_comp = total_opening_comp;
                let total_closing_filter_comp = total_closing_comp;

                if (chosen_filter === "cost") {
                    total_filter_comp = priceConversionRate(total_comp, conversions, category_name, mt_type_name, null, false);
                    total_opening_filter_comp = priceConversionRate(total_opening_comp, conversions, category_name, mt_type_name, null, false);
                    total_closing_filter_comp = priceConversionRate(total_closing_comp, conversions, category_name, mt_type_name, null, false);
                } else if (chosen_filter === "co2") {
                    total_filter_comp = co2ConversionRate(total_comp, conversions, category_name, mt_type_name, null, false);
                    total_opening_filter_comp = co2ConversionRate(total_opening_comp, conversions, category_name, mt_type_name, null, false);
                    total_closing_filter_comp = co2ConversionRate(total_closing_comp, conversions, category_name, mt_type_name, null, false);
                }

                res.push({
                    ...record,
                    total_comp: total_filter_comp,
                    total_opening_comp: total_opening_filter_comp,
                    total_closing_comp: total_closing_filter_comp,
                    compare_consumptions: _.chain(measure_comp).get("consumptions", []).value()
                });
            } else {
                res.push(record);
            }
            return res;
        }, [])
        .value();

    /**
     * Process server data for react-vis mapping graphic
     * @function proccess_graphic_data
     * @param {object} series - List of measurements with 'ref_consumptions' && 'compare_consumptions' array of data retrieve from server
     * @param {string} type - get specific array of data 'ref or compare'
     * @param {string} color - color for react-vis color serie
     * @returns
     */
    const process_graphic_data = (series, type, color, serie_title = undefined) => {
        return _.reduce(
            series,
            (res, item) => {
                const conversions = _.get(item, "measure.site.conversions", null);
                const data_serie = _.chain(item).get(type).value();
                if (_.size(data_serie) > 0) {
                    return _.chain(item)
                        .get(type)
                        .map((record, idx) => {
                            const existing_record = _.find(res, { t: moment(record[0]).unix() * 1000 });
                            let y_val = null;
                            let y_opening_val = null;
                            let y_closing_val = null;
                            switch (rewriteCalendarType(calendar_type)) {
                                case 1:
                                    if (_.isFinite(record[1])) y_val = record[1];
                                    break;
                                case 2:
                                    if (_.isFinite(record[2])) y_val = record[2];
                                    break;
                                default:
                                    if (_.isFinite(record[1]) && _.isFinite(record[2])) y_val = record[1] + record[2];
                                    y_opening_val = _.isFinite(record[1]) ? record[1] : null;
                                    y_closing_val = _.isFinite(record[2]) ? record[2] : null;
                                    break;
                            }

                            if (chosen_filter === "cost") {
                                y_val = priceConversionRate(y_val, conversions, item.category_name, item.mt_type_name, null, false);
                                y_opening_val = priceConversionRate(y_opening_val, conversions, item.category_name, item.mt_type_name, null, false);
                                y_closing_val = priceConversionRate(y_closing_val, conversions, item.category_name, item.mt_type_name, null, false);
                            } else if (chosen_filter === "co2") {
                                y_val = co2ConversionRate(y_val, conversions, item.category_name, item.mt_type_name, null, false);
                                y_opening_val = co2ConversionRate(y_opening_val, conversions, item.category_name, item.mt_type_name, null, false);
                                y_closing_val = co2ConversionRate(y_closing_val, conversions, item.category_name, item.mt_type_name, null, false);
                            }
                            if (_.isUndefined(existing_record)) {
                                return {
                                    title: _.isUndefined(serie_title) ? _.get(item, "name", "-") : serie_title,
                                    x: filter.todo_compare ? idx : moment(record[0]).unix() * 1000,
                                    t: moment(record[0]).unix() * 1000,
                                    y: y_val,
                                    y_real: y_val,
                                    y_opening: y_opening_val,
                                    y_closing: y_closing_val,
                                    color: tinycolor(color).setAlpha(1).toString(),
                                    index: idx
                                };
                            } else {
                                return {
                                    ...existing_record,
                                    y: _.isFinite(y_val) ? existing_record.y + y_val : existing_record.y,
                                    y_real: _.isFinite(y_val) ? existing_record.y_real + y_val : existing_record.y_real,
                                    y_opening: _.isFinite(y_opening_val) ? existing_record.y_opening + y_opening_val : existing_record.y_opening,
                                    y_closing: _.isFinite(y_closing_val) ? existing_record.y_closing + y_closing_val : existing_record.y_closing
                                };
                            }
                        })
                        .value();
                }
                return res;
            },
            []
        );
    };

    switch (aggregate_type) {
        case "category": {
            return (
                _.chain(category.categories)
                    .reduce((res, category) => {
                        if (!_.includes([5, 9, 11, 12, 27], category.id)) {
                            res.push(category);
                        }
                        return res;
                    }, [])
                    // Care process all categories but server request return only data for specific categories
                    // maybe filter categories with unit filter
                    .reduce(
                        (res, category) => {
                            const measurements_category = _.chain(process_measurements)
                                .filter((measure) => measure.category === category.id)
                                .value();
                            if (_.size(measurements_category) > 0) {
                                //process color based on database id
                                const color = tinycolor(Palette.circles[(category.id - 1) % Palette.circles.length])
                                    .setAlpha(0.6)
                                    .toString();
                                //graphic part
                                const ref_data = process_graphic_data(measurements_category, "ref_consumptions", color, _.get(category, "name"));

                                const total = _.sumBy(ref_data, "y_real");
                                const total_opening = _.sumBy(ref_data, "y_opening");
                                const total_closing = _.sumBy(ref_data, "y_closing");
                                const minimum = _.minBy(ref_data, "y_real");
                                const maximum = _.maxBy(ref_data, "y_real");
                                const average = _.meanBy(ref_data, "y_real");

                                const record = {
                                    id: category.id,
                                    name: _.get(category, "name"),
                                    total: _.isFinite(total) ? total : null,
                                    total_opening: _.isFinite(total_opening) ? total_opening : null,
                                    total_closing: _.isFinite(total_closing) ? total_closing : null,
                                    minimum: _.get(minimum, "y_real", null),
                                    average: _.isFinite(average) ? average : null,
                                    maximum: _.get(maximum, "y_real", null),
                                    min_day: _.get(minimum, "t", null),
                                    max_day: _.get(maximum, "t", null)
                                };

                                const graphic_record = {
                                    name: _.get(category, "name"),
                                    title: _.get(category, "name"),
                                    disabled: false,
                                    strokeWidth: 20,
                                    data: ref_data,
                                    color
                                };

                                if (filter.todo_compare) {
                                    //graphic part
                                    const comp_data = process_graphic_data(
                                        measurements_category,
                                        "compare_consumptions",
                                        color,
                                        _.get(category, "name")
                                    );
                                    const total_comp = _.sumBy(comp_data, "y_real");
                                    const total_opening_comp = _.sumBy(comp_data, "y_opening");
                                    const total_closing_comp = _.sumBy(comp_data, "y_closing");
                                    res.synthesis.push({ ...record, total_comp, total_opening_comp, total_closing_comp });

                                    res.graphic.ref.push(graphic_record);
                                    res.graphic.compare.push({
                                        ...graphic_record,
                                        data: comp_data
                                    });
                                } else {
                                    res.synthesis.push(record);
                                    //graphic part
                                    res.graphic.ref.push(graphic_record);
                                }
                            }
                            return res;
                        },
                        { synthesis: [], graphic: { ref: [], compare: [] } }
                    )
                    .value()
            );
        }
        case "usage": {
            return _.chain(usages_list.concat({ name: t`undefined`, id: null }))
                .reduce((res, usage) => {
                    if (_.size(usages) === 0) {
                        res.push(usage);
                    } else if (_.includes(usages, usage.id)) {
                        res.push(usage);
                    }
                    return res;
                }, [])
                .reduce(
                    (res, usage) => {
                        const measurements_usage = _.chain(process_measurements)
                            .filter((measure) => measure.usage === usage.id)
                            .value();
                        if (_.size(measurements_usage) > 0) {
                            const color = usage.id
                                ? tinycolor(Palette.circles[(usage.id - 1) % Palette.circles.length])
                                      .setAlpha(0.6)
                                      .toString()
                                : tinycolor(Palette.circles[Palette.circles.length - 4])
                                      .setAlpha(0.6)
                                      .toString();
                            //graphic part
                            const ref_data = process_graphic_data(measurements_usage, "ref_consumptions", color, _.get(usage, "name"));
                            const total = _.sumBy(ref_data, "y_real");
                            const total_opening = _.sumBy(ref_data, "y_opening");
                            const total_closing = _.sumBy(ref_data, "y_closing");
                            const minimum = _.minBy(ref_data, "y_real");
                            const maximum = _.maxBy(ref_data, "y_real");
                            const average = _.meanBy(ref_data, "y_real");

                            const record = {
                                id: usage.id,
                                name: _.get(usage, "name"),
                                total: _.isFinite(total) ? total : null,
                                total_opening: _.isFinite(total_opening) ? total_opening : null,
                                total_closing: _.isFinite(total_closing) ? total_closing : null,
                                minimum: _.get(minimum, "y_real", null),
                                average: _.isFinite(average) ? average : null,
                                maximum: _.get(maximum, "y_real", null),
                                min_day: _.get(minimum, "t", null),
                                max_day: _.get(maximum, "t", null)
                            };

                            const graphic_record = {
                                name: _.get(usage, "name"),
                                title: _.get(usage, "name"),
                                disabled: false,
                                strokeWidth: 20,
                                data: ref_data,
                                color
                            };

                            if (filter.todo_compare) {
                                //graphic part
                                const comp_data = process_graphic_data(measurements_usage, "compare_consumptions", color, _.get(usage, "name"));
                                const total_comp = _.sumBy(comp_data, "y_real");
                                const total_opening_comp = _.sumBy(comp_data, "y_opening");
                                const total_closing_comp = _.sumBy(comp_data, "y_closing");
                                res.synthesis.push({ ...record, total_comp, total_opening_comp, total_closing_comp });
                                res.graphic.ref.push(graphic_record);
                                res.graphic.compare.push({
                                    ...graphic_record,
                                    data: comp_data
                                });
                            } else {
                                res.synthesis.push(record);
                                res.graphic.ref.push(graphic_record);
                            }
                        }
                        return res;
                    },
                    { synthesis: [], graphic: { ref: [], compare: [] } }
                )
                .value();
        }
        case "tag": {
            return _.chain(tag.tags.concat({ name: t`undefined`, id: null, word: t`undefined` }))
                .reduce((res, tag) => {
                    if (_.size(tags) === 0) {
                        res.push(tag);
                    } else if (_.includes(tags, tag.id)) {
                        res.push(tag);
                    }
                    return res;
                }, [])
                .reduce(
                    (res, tag) => {
                        const measurements_tag =
                            tag.id !== null
                                ? _.chain(process_measurements)
                                      .filter((measure) => _.includes(measure.tags, tag.id))
                                      .value()
                                : _.chain(process_measurements)
                                      .filter((measure) => _.size(measure.tags) === 0)
                                      .value();
                        if (_.size(measurements_tag) > 0) {
                            const color = tag.id
                                ? tinycolor(Palette.circles[(tag.id - 1) % Palette.circles.length])
                                      .setAlpha(0.6)
                                      .toString()
                                : tinycolor(Palette.circles[Palette.circles.length - 4])
                                      .setAlpha(0.6)
                                      .toString();

                            //graphic part
                            const ref_data = process_graphic_data(measurements_tag, "ref_consumptions", color, _.get(tag, "word"));
                            const total = _.sumBy(ref_data, "y_real");
                            const total_opening = _.sumBy(ref_data, "y_opening");
                            const total_closing = _.sumBy(ref_data, "y_closing");
                            const minimum = _.minBy(ref_data, "y_real");
                            const maximum = _.maxBy(ref_data, "y_real");
                            const average = _.meanBy(ref_data, "y_real");

                            const record = {
                                id: tag.id,
                                name: _.get(tag, "word"),
                                total: _.isFinite(total) ? total : null,
                                total_opening: _.isFinite(total_opening) ? total_opening : null,
                                total_closing: _.isFinite(total_closing) ? total_closing : null,
                                minimum: _.get(minimum, "y_real", null),
                                average: _.isFinite(average) ? average : null,
                                maximum: _.get(maximum, "y_real", null),
                                min_day: _.get(minimum, "t", null),
                                max_day: _.get(maximum, "t", null)
                            };

                            const graphic_record = {
                                name: _.get(tag, "word"),
                                title: _.get(tag, "word"),
                                disabled: false,
                                strokeWidth: 20,
                                data: ref_data,
                                color
                            };

                            if (filter.todo_compare) {
                                //graphic part
                                const comp_data = process_graphic_data(measurements_tag, "compare_consumptions", color, _.get(tag, "word"));
                                const total_comp = _.sumBy(comp_data, "y_real");
                                const total_opening_comp = _.sumBy(comp_data, "y_opening");
                                const total_closing_comp = _.sumBy(comp_data, "y_closing");
                                res.synthesis.push({ ...record, total_comp, total_opening_comp, total_closing_comp });
                                res.graphic.ref.push(graphic_record);
                                res.graphic.compare.push({
                                    ...graphic_record,
                                    data: comp_data
                                });
                            } else {
                                res.synthesis.push(record);
                                res.graphic.ref.push(graphic_record);
                            }
                        }
                        return res;
                    },
                    { synthesis: [], graphic: { ref: [], compare: [] } }
                )
                .value();
        }
        case "site": {
            return _.chain(sites)
                .reduce((res, site) => {
                    if (_.includes(site_ids, site.id)) {
                        res.push(site);
                    }
                    return res;
                }, [])
                .reduce(
                    (res, site) => {
                        const measurements_site = _.chain(process_measurements)
                            .filter((measure) => measure.site === site.id)
                            .value();
                        if (_.size(measurements_site) > 0) {
                            const color = tinycolor(Palette.circles[(site.id - 1) % Palette.circles.length])
                                .setAlpha(0.6)
                                .toString();
                            //graphic part
                            const ref_data = process_graphic_data(measurements_site, "ref_consumptions", color, _.get(site, "name"));

                            const total = _.sumBy(ref_data, "y_real");
                            const total_opening = _.sumBy(ref_data, "y_opening");
                            const total_closing = _.sumBy(ref_data, "y_closing");
                            const minimum = _.minBy(ref_data, "y_real");
                            const maximum = _.maxBy(ref_data, "y_real");
                            const average = _.meanBy(ref_data, "y_real");

                            const record = {
                                id: site.id,
                                name: _.get(site, "name"),
                                total: _.isFinite(total) ? total : null,
                                total_opening: _.isFinite(total_opening) ? total_opening : null,
                                total_closing: _.isFinite(total_closing) ? total_closing : null,
                                minimum: _.get(minimum, "y_real", null),
                                average: _.isFinite(average) ? average : null,
                                maximum: _.get(maximum, "y_real", null),
                                min_day: _.get(minimum, "t", null),
                                max_day: _.get(maximum, "t", null)
                            };

                            const graphic_record = {
                                name: _.get(site, "name"),
                                title: _.get(site, "name"),
                                disabled: false,
                                strokeWidth: 20,
                                data: ref_data,
                                color
                            };

                            if (filter.todo_compare) {
                                //graphic part
                                const comp_data = process_graphic_data(measurements_site, "compare_consumptions", color, _.get(site, "name"));
                                const total_comp = _.sumBy(comp_data, "y_real");
                                const total_opening_comp = _.sumBy(comp_data, "y_opening");
                                const total_closing_comp = _.sumBy(comp_data, "y_closing");
                                res.synthesis.push({ ...record, total_comp, total_opening_comp, total_closing_comp });
                                res.graphic.ref.push(graphic_record);
                                res.graphic.compare.push({
                                    ...graphic_record,
                                    data: comp_data
                                });
                            } else {
                                res.synthesis.push(record);
                                res.graphic.ref.push(graphic_record);
                            }
                        }
                        return res;
                    },
                    { synthesis: [], graphic: { ref: [], compare: [] } }
                )
                .value();
        }
        case "zone": {
            return _.chain(zone.zones)
                .reduce((res, zone) => {
                    if (_.size(zones) === 0) {
                        res.push(zone);
                    } else if (_.includes(zones, zone.id)) {
                        res.push(zone);
                    }
                    return res;
                }, [])
                .reduce(
                    (res, zone) => {
                        const measurements_zone = _.chain(process_measurements)
                            .filter((measure) => measure.zone === zone.id)
                            .value();
                        if (_.size(measurements_zone) > 0) {
                            const color = tinycolor(Palette.circles[(zone.id - 1) % Palette.circles.length])
                                .setAlpha(0.6)
                                .toString();
                            //graphic part

                            const name = `${_.get(zone, "name")} (${_.get(zone, "site_name", "-")})`;

                            const ref_data = process_graphic_data(measurements_zone, "ref_consumptions", color, name);
                            const total = _.sumBy(ref_data, "y_real");
                            const total_opening = _.sumBy(ref_data, "y_opening");
                            const total_closing = _.sumBy(ref_data, "y_closing");
                            const minimum = _.minBy(ref_data, "y_real");
                            const maximum = _.maxBy(ref_data, "y_real");
                            const average = _.meanBy(ref_data, "y_real");

                            const record = {
                                id: zone.id,
                                name,
                                total: _.isFinite(total) ? total : null,
                                total_opening: _.isFinite(total_opening) ? total_opening : null,
                                total_closing: _.isFinite(total_closing) ? total_closing : null,
                                minimum: _.get(minimum, "y_real", null),
                                average: _.isFinite(average) ? average : null,
                                maximum: _.get(maximum, "y_real", null),
                                min_day: _.get(minimum, "t", null),
                                max_day: _.get(maximum, "t", null)
                            };

                            const graphic_record = {
                                name,
                                title: name,
                                disabled: false,
                                strokeWidth: 20,
                                data: ref_data,
                                color
                            };

                            if (filter.todo_compare) {
                                //graphic part
                                const comp_data = process_graphic_data(measurements_zone, "compare_consumptions", color, name);
                                const total_comp = _.sumBy(comp_data, "y_real");
                                const total_opening_comp = _.sumBy(comp_data, "y_opening");
                                const total_closing_comp = _.sumBy(comp_data, "y_closing");
                                res.synthesis.push({ ...record, total_comp, total_opening_comp, total_closing_comp });
                                res.graphic.ref.push(graphic_record);
                                res.graphic.compare.push({
                                    ...graphic_record,
                                    data: comp_data
                                });
                            } else {
                                res.synthesis.push(record);
                                res.graphic.ref.push(graphic_record);
                            }
                        }
                        return res;
                    },
                    { synthesis: [], graphic: { ref: [], compare: [] } }
                )
                .value();
        }
        default:
            return _.chain(process_measurements)
                .reduce(
                    (res, measure) => {
                        const color = tinycolor(Palette.circles[(measure.id - 1) % Palette.circles.length])
                            .setAlpha(0.6)
                            .toString();
                        res.synthesis.push(measure);
                        const conversions = _.get(measure, "measure.site.conversions", null);
                        //graphic part
                        const record = {
                            name: _.get(measure, "name", "-"),
                            title: _.get(measure, "name", "-"),
                            disabled: false,
                            strokeWidth: 20,
                            data: _.chain(measure)
                                .get("ref_consumptions", [])
                                .map((value, idx) => {
                                    let y_val = null;
                                    let y_opening_val = null;
                                    let y_closing_val = null;
                                    switch (rewriteCalendarType(calendar_type)) {
                                        case 1:
                                            if (_.isFinite(value[1])) y_val = value[1];
                                            break;
                                        case 2:
                                            if (_.isFinite(value[2])) y_val = value[2];
                                            break;
                                        default:
                                            if (_.isFinite(value[1]) && _.isFinite(value[2])) y_val = value[1] + value[2];
                                            y_opening_val = _.isFinite(value[1]) ? value[1] : null;
                                            y_closing_val = _.isFinite(value[2]) ? value[2] : null;
                                            break;
                                    }
                                    if (chosen_filter === "cost") {
                                        y_val = priceConversionRate(y_val, conversions, measure.category_name, measure.mt_type_name, null, false);
                                        y_opening_val = priceConversionRate(
                                            y_opening_val,
                                            conversions,
                                            measure.category_name,
                                            measure.mt_type_name,
                                            null,
                                            false
                                        );
                                        y_closing_val = priceConversionRate(
                                            y_closing_val,
                                            conversions,
                                            measure.category_name,
                                            measure.mt_type_name,
                                            null,
                                            false
                                        );
                                    } else if (chosen_filter === "co2") {
                                        y_val = co2ConversionRate(y_val, conversions, measure.category_name, measure.mt_type_name, null, false);
                                        y_opening_val = co2ConversionRate(
                                            y_opening_val,
                                            conversions,
                                            measure.category_name,
                                            measure.mt_type_name,
                                            null,
                                            false
                                        );
                                        y_closing_val = co2ConversionRate(
                                            y_closing_val,
                                            conversions,
                                            measure.category_name,
                                            measure.mt_type_name,
                                            null,
                                            false
                                        );
                                    }

                                    return {
                                        title: _.get(measure, "name", "-"),
                                        x: filter.todo_compare ? idx : moment(value[0]).unix() * 1000,
                                        t: moment(value[0]).unix() * 1000,
                                        y: y_val,
                                        y_real: y_val,
                                        y_opening: y_opening_val,
                                        y_closing: y_closing_val,
                                        color: tinycolor(color).setAlpha(1).toString(),
                                        index: idx
                                    };
                                })
                                .value(),
                            color
                        };
                        res.graphic.ref.push(record);
                        if (filter.todo_compare) {
                            res.graphic.compare.push({
                                ...record,
                                data: _.chain(measure)
                                    .get("compare_consumptions", [])
                                    .map((value, idx) => {
                                        const conversions = _.get(record, "site.conversions", null);
                                        let y_val = null;
                                        let y_opening_val = null;
                                        let y_closing_val = null;
                                        switch (rewriteCalendarType(calendar_type)) {
                                            case 1:
                                                if (_.isFinite(value[1])) y_val = value[1];
                                                break;
                                            case 2:
                                                if (_.isFinite(value[2])) y_val = value[2];
                                                break;
                                            default:
                                                if (_.isFinite(value[1]) && _.isFinite(value[2])) y_val = value[1] + value[2];
                                                y_opening_val = _.isFinite(value[1]) ? value[1] : null;
                                                y_closing_val = _.isFinite(value[2]) ? value[2] : null;
                                                break;
                                        }

                                        if (chosen_filter === "cost") {
                                            y_val = priceConversionRate(y_val, conversions, measure.category_name, measure.mt_type_name, null, false);
                                            y_opening_val = priceConversionRate(
                                                y_opening_val,
                                                conversions,
                                                measure.category_name,
                                                measure.mt_type_name,
                                                null,
                                                false
                                            );
                                            y_closing_val = priceConversionRate(
                                                y_closing_val,
                                                conversions,
                                                measure.category_name,
                                                measure.mt_type_name,
                                                null,
                                                false
                                            );
                                        } else if (chosen_filter === "co2") {
                                            y_val = co2ConversionRate(y_val, conversions, measure.category_name, measure.mt_type_name, null, false);
                                            y_opening_val = co2ConversionRate(
                                                y_opening_val,
                                                conversions,
                                                measure.category_name,
                                                measure.mt_type_name,
                                                null,
                                                false
                                            );
                                            y_closing_val = co2ConversionRate(
                                                y_closing_val,
                                                conversions,
                                                measure.category_name,
                                                measure.mt_type_name,
                                                null,
                                                false
                                            );
                                        }

                                        return {
                                            title: _.get(measure, "name", "-"),
                                            x: idx,
                                            t: moment(value[0]).unix() * 1000,
                                            y: y_val,
                                            y_real: y_val,
                                            y_opening: y_opening_val,
                                            y_closing: y_closing_val,
                                            color: tinycolor(color).setAlpha(1).toString(),
                                            index: idx
                                        };
                                    })
                                    .value()
                            });
                        }
                        return res;
                    },
                    { synthesis: [], graphic: { ref: [], compare: [] } }
                )
                .value();
    }
};

export const globalviewMiddleware = (store) => (next) => (action) => {
    if (getOverviewIntensive.fulfilled.match(action)) {
        //Intensive process part
        const state_field = displayTitleFromUnit[action.payload.filter.unit.symbol].name;
        const process_data = _.chain(action.payload.data)
            .reduce(
                (res, measures, site_id) => {
                    _.each(measures, (measure) => {
                        const { summaries } = measure;
                        _.each(summaries, (record) => {
                            //minimum part
                            const min_data = _.get(res.min, record[0]);
                            if (min_data === undefined) {
                                res.min[record[0]] = [record[1]];
                            } else {
                                res.min[record[0]].push(record[1]);
                            }
                            //avg part
                            const avg_data = _.get(res.avg, record[0]);
                            if (avg_data === undefined) {
                                res.avg[record[0]] = [record[2]];
                            } else {
                                res.avg[record[0]].push(record[2]);
                            }
                            //max part
                            const max_data = _.get(res.max, record[0]);
                            if (max_data === undefined) {
                                res.max[record[0]] = [record[3]];
                            } else {
                                res.max[record[0]].push(record[3]);
                            }
                        });
                    });
                    return res;
                },
                { min: {}, avg: {}, max: {} }
            )
            .map((data, key) => {
                //remap data directly for react-vis. Need list of {x,y, ...}
                let remap_data = [];
                const color = tinycolor(display_color[key]).setAlpha(0.6).toString();

                switch (key) {
                    case "min":
                        remap_data = _.map(data, (item, date) => {
                            const y_val = _.chain(item)
                                .filter((val) => val !== null)
                                .min()
                                .defaultTo(null)
                                .value();
                            return { x: moment(date).unix() * 1000, y: y_val, y_real: y_val, type: key, color };
                        });
                        break;
                    case "avg":
                        remap_data = _.map(data, (item, date) => {
                            const y_val = _.chain(item)
                                .filter((val) => val !== null)
                                .mean()
                                .defaultTo(null)
                                .value();
                            return { x: moment(date).unix() * 1000, y: y_val, y_real: y_val, type: key, color };
                        });
                        break;
                    case "max":
                        remap_data = _.map(data, (item, date) => {
                            const y_val = _.chain(item)
                                .filter((val) => val !== null)
                                .max()
                                .defaultTo(null)
                                .value();
                            return { x: moment(date).unix() * 1000, y: y_val, y_real: y_val, type: key, color };
                        });
                        break;
                    default:
                        remap_data = [];
                        break;
                }
                return { title: key, data: remap_data, disabled: false, strokeWidth: 20, isEmpty: _.size(remap_data) === 0, color };
            })
            .value();

        action.payload = { field: state_field, data: process_data };
    }

    if (getOverviewConsumption.fulfilled.match(action)) {
        //Non intensive process part
        const { measurement, zone, dataflow } = store.getState();
        const { ref, compare } = action.payload.data;
        const { filter } = action.payload;

        const fill_ref = _.reduce(
            ref,
            (res, consumptions) => {
                //site iterator
                _.each(consumptions, (item) => {
                    //measurement iterator;
                    const measure = _.find(measurement.measurements, { id: item.id });
                    const df = _.find(dataflow.dataflows, { id: measure?.dataflow });
                    if (measure && df) {
                        if (filter.usages.length > 0 && !_.includes(filter.usages, df?.usage)) {
                            return true;
                        }
                        if (filter.tags.length > 0 && _.intersection(filter.tags, df?.tag_set).length === 0) {
                            return true;
                        }
                        if (filter.zones.length > 0 && !_.includes(filter.zones, df?.zone)) {
                            return true;
                        }
                        const total_consumption = _.chain(item) // list of all finite value
                            .get("consumptions")
                            .reduce((res, val) => {
                                switch (rewriteCalendarType(filter?.calendar_type)) {
                                    case 1:
                                        //opening
                                        if (_.isFinite(val[1])) res.push(val[1]);
                                        break;
                                    case 2:
                                        //closing
                                        if (_.isFinite(val[2])) res.push(val[2]);
                                        break;
                                    default:
                                        //total
                                        if (_.isFinite(val[1]) && _.isFinite(val[2])) res.push(val[1] + val[2]);
                                        break;
                                }
                                return res;
                            }, [])
                            .value();
                        const conso = _.chain(total_consumption).sum().value(); //process total_consumption sum
                        if (_.size(total_consumption) > 0) {
                            res.push({
                                ...item,
                                measure,
                                zone: df?.zone,
                                usage: df?.usage,
                                total_consumption: _.isFinite(conso) ? conso : null //store sum consumption or null
                            });
                        }
                    }
                });
                return res;
            },
            []
        );

        const fill_compare = _.reduce(
            compare,
            (res, consumptions) => {
                _.each(consumptions, (item) => {
                    const measure = _.find(measurement.measurements, { id: item.id });
                    const df = _.find(dataflow.dataflows, { id: measure?.dataflow });
                    if (measure) {
                        if (filter.usages.length > 0 && !_.includes(filter.usages, df?.usage)) {
                            //Usages exclusion
                            return true;
                        }
                        if (filter.tags.length > 0 && _.intersection(filter.tags, df?.tag_set).length === 0) {
                            //Tags exclusion
                            return true;
                        }
                        if (filter.zones.length > 0 && !_.includes(filter.zones, df?.zone)) {
                            //Zones exclusion
                            return true;
                        }
                        const total_consumption = _.chain(item)
                            .get("consumptions")
                            .reduce((res, val) => {
                                switch (rewriteCalendarType(filter?.calendar_type)) {
                                    case 1:
                                        //opening
                                        if (_.isFinite(val[1])) res.push(val[1]);
                                        break;
                                    case 2:
                                        //closing
                                        if (_.isFinite(val[2])) res.push(val[2]);
                                        break;
                                    default:
                                        //total
                                        if (_.isFinite(val[1]) && _.isFinite(val[2])) res.push(val[1] + val[2]);
                                        break;
                                }
                                return res;
                            }, [])
                            .value();
                        const conso = _.chain(total_consumption).sum().value();
                        if (_.size(total_consumption) > 0) {
                            res.push({
                                ...item,
                                measure,
                                zone: df?.zone,
                                usage: df?.usage,
                                total_consumption: _.isFinite(conso) ? conso : null
                            });
                        }
                    }
                });
                return res;
            },
            []
        );

        const filtered_zone = _.reduce(
            zone.zones,
            (res, zone) => {
                if (_.includes(action.payload.filter.site, zone.site_id)) res.push(zone);
                return res;
            },
            []
        );

        const radial_zone_series = proccess_area_calculation(filtered_zone, fill_ref); //process area gauge only on ref data
        const radial_usage_series = proccess_usage_calculation(filter?.usages_list ?? [], fill_ref); //process usage gauge only on ref data
        const { synthesis, graphic } = process_synthesis_graphic(store, fill_ref, action.payload.filter, fill_compare);

        action.payload = {
            radialZone: radial_zone_series,
            radialUsage: radial_usage_series,
            synthesis,
            graphic
        };
    }

    return next(action);
};
