import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { Grid, Input, Button, Icon, Popup } from "semantic-ui-react";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { ContentType } from "modules/activityLog/utils";
import { checkStringInclude } from "modules/common/utils";
import { setItemsPerPage, setPage, setSearchNameFilter, setStickPage, resetFilterWithPage } from "modules/import/importSlice";
import { useGetImportsQuery } from "modules/import/importService";
import history_app from "history_app";

import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteImportSource from "modules/import/components/DeleteImportSource";
import MessageDisplay from "modules/common/components/MessageDisplay";

const ImportsSource = () => {
    const [localSearchName, setLocalSearchName] = useState("");

    const { org, auth, notification, imports } = useSelector((state) => state);

    const my_imports = useGetImportsQuery({ org: org.current }, { skip: !org.current });

    const user_rights = auth.rights;

    const dispatch = useDispatch();

    let err_list = [my_imports.isError];
    let status_list = [my_imports.isSuccess];

    useEffect(() => {
        (async () => {
            await setLocalSearchName(imports.filter.searchName);
            imports.pagination.stickPage && (await dispatch(setStickPage(false)));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const can_add = notification.srv_status.db_status === "rw" && (user_rights.is_admin || _.size(auth.rights?.sites_rw) > 0);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "owner_email", label: i18n._(t`owner_email`), textAlign: "left" },
        { id: "protocol", label: i18n._(t`Protocol`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const imports_list = _.chain(my_imports.data)
        .reduce((res, item) => {
            if (imports.filter.searchName === "") {
                res.push(item);
            } else if (checkStringInclude(imports.filter.searchName, item.name)) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const can_actions = user_rights.is_admin || item?.owner === auth.user.user_id;

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                owner_email: {
                    render: <span style={customStyle}>{_.get(item, "owner_email", "-")}</span>,
                    value: _.get(item, "owner_email", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                protocol: {
                    render: <span style={customStyle}>{_.toUpper(_.get(item, "protocol", "-"))}</span>,
                    value: _.toUpper(_.get(item, "protocol", "-")),
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            {can_actions && (
                                <Button.Group>
                                    <Popup
                                        trigger={
                                            <Button
                                                icon={notification.srv_status.db_status === "rw" ? "edit" : "eye"}
                                                onClick={async (e) => {
                                                    await dispatch(setStickPage(true));
                                                    if (notification.srv_status.db_status === "rw") {
                                                        history_app.push(`/imports/${item.id}/change`);
                                                    } else {
                                                        history_app.push(`/imports/${item.id}/view`);
                                                    }
                                                }}
                                            />
                                        }
                                    >
                                        <Popup.Content>
                                            {notification.srv_status.db_status === "rw" && <Trans>Import source edition</Trans>}
                                            {notification.srv_status.db_status === "r" && <Trans>View import source</Trans>}
                                        </Popup.Content>
                                    </Popup>
                                    {notification.srv_status.db_status === "rw" && <DeleteImportSource item={item} />}
                                </Button.Group>
                            )}
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <>
            {(() => {
                if (_.some(err_list)) {
                    return (
                        <Grid>
                            <Grid.Column width={16}>
                                <MessageDisplay
                                    message={i18n._(t`error loading data`)}
                                    level="error"
                                    iconName="warning circle"
                                    isLoading={false}
                                    attached={false}
                                />
                            </Grid.Column>
                        </Grid>
                    );
                } else if (_.every(status_list)) {
                    return (
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search import`)}
                                    onChange={(e, { value }) => {
                                        setLocalSearchName(value);
                                        dispatch(setSearchNameFilter(value));
                                    }}
                                    value={localSearchName}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                {can_add && (
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            await dispatch(resetFilterWithPage());
                                            history_app.push(`imports/add`);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>Add a new import source</Trans>
                                    </Button>
                                )}
                                <Button
                                    icon
                                    labelPosition="left"
                                    onClick={async (e) => {
                                        history_app.push(`activitylogs/${ContentType.FILEIMPORT}`);
                                    }}
                                >
                                    <Icon name="bullhorn" />
                                    <Trans>activitylog</Trans>
                                </Button>
                            </Grid.Column>

                            {/* Table  */}
                            <Grid.Column width={16}>
                                <TableEnhanced
                                    headCells={headCells}
                                    rows={imports_list}
                                    order="asc"
                                    orderBy="name"
                                    page={imports.pagination.page}
                                    rowsPerPage={imports.pagination.itemsPerPage}
                                    setPage={(page) => {
                                        dispatch(setPage(page));
                                    }}
                                    setItemsPerPage={(items) => {
                                        dispatch(setItemsPerPage(items));
                                    }}
                                    textItemPerPages={i18n._(t`items per page`)}
                                />
                            </Grid.Column>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid>
                            <Grid.Column width={16}>
                                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="spinner" isLoading={true} />
                            </Grid.Column>
                        </Grid>
                    );
                }
            })()}
        </>
    );
};

export default React.memo(ImportsSource);
