import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { t, Trans } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import { Icon, Button, Modal, Divider, Segment } from "semantic-ui-react";

import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useUpdateNodeMutation } from "modules/hierarchy/hierarchyService";
import { NodeActionType, remapForReactFlow } from "modules/hierarchy/utils";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

/**
 * Used for form validation. If errors, you can't submit form
 * @function validate
 * @param {object} values
 */
const validate = (values) => {
    const errors = {};
    if (values.equipment === -1 && !values.name) {
        errors.name = <Trans>node name is required</Trans>;
    }
    return errors;
};

const UpdateNode = (props) => {
    const { openModal, setOpenModal, i18n, data } = props;
    const disabled = false;

    const {
        equipments,
        org,
        rangeTime: { start, end }
    } = data.extra;
    const { onNodeAction } = data.actions;
    const current_node = data.node_db;

    const [updateNode, update] = useUpdateNodeMutation();

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update node`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update node`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update, i18n]);

    const onSubmitForm = async (formData) => {
        const updated_data = {
            ...formData,
            equipment: _.includes([null, -1], formData.equipment) ? null : formData.equipment
        };
        const action = await updateNode({
            org,
            data: updated_data,
            node_id: formData.id,
            diagram_id: current_node.diagram,
            start: start.format("YYYY-MM-DD"),
            end: end.format("YYYY-MM-DD")
        });
        const error = _.get(action, "error", null);
        if (!error) {
            const nodes_edges = _.reduce(
                action.data,
                (res, node) => {
                    const { node: remapNode, edge } = remapForReactFlow(node);
                    if (edge) {
                        res.edges.push(edge);
                    }
                    res.nodes.push(remapNode);

                    return res;
                },
                { nodes: [], edges: [] }
            );
            onNodeAction && (await onNodeAction(nodes_edges, NodeActionType.Update, data.extra));
            await setOpenModal(false);
        }
    };

    const eqptsOptions = useMemo(() => {
        return [{ key: -1, value: -1, text: i18n._(t`No equipment`) }].concat(equipments);
    }, [equipments, i18n]);

    const initialValues = useMemo(() => {
        return {
            ...current_node,
            equipment: _.includes([null, -1], current_node.equipment) ? -1 : current_node.equipment
        };
    }, [current_node]);

    return (
        <Form
            onSubmit={onSubmitForm}
            validate={validate}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                return (
                    <Modal
                        open={openModal}
                        centered={false}
                        closeOnDimmerClick
                        closeOnEscape
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                    >
                        <Modal.Header>
                            {!disabled && <Trans>Edit node</Trans>}
                            {disabled && <Trans>View node</Trans>}
                        </Modal.Header>
                        <Modal.Content>
                            <form onSubmit={handleSubmit} className="ui form">
                                <Field
                                    name="name"
                                    placeholder={i18n._(t`enter name of node`)}
                                    label={i18n._(t`name`)}
                                    isRequired={true}
                                    component={InputAdapter}
                                    disabled={disabled}
                                />
                                <Field name="equipment" label={i18n._(t`equipment`)} options={eqptsOptions} component={DropDownAdapter} />
                                <Divider />
                                {values.equipment === -1 && !values.name && (
                                    <Segment basic textAlign="center">
                                        <MessageDisplay
                                            message={i18n._(t`Enter a name if you don't select equipment`)}
                                            level="info"
                                            iconName="info circle"
                                            isLoading={false}
                                            attached={false}
                                        />
                                    </Segment>
                                )}
                                <Segment attached basic textAlign="right">
                                    <Button
                                        type="button"
                                        negative
                                        icon
                                        labelPosition="left"
                                        onClick={(e) => {
                                            setOpenModal(false);
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                    {!disabled && (
                                        <Button positive icon labelPosition="right" type="submit" disabled={submitting || pristine || invalid}>
                                            <Icon name="send" />
                                            <Trans>Confirm</Trans>
                                        </Button>
                                    )}
                                </Segment>
                            </form>
                        </Modal.Content>
                    </Modal>
                );
            }}
        />
    );
};

export default React.memo(UpdateNode);
