import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Grid, Popup } from "semantic-ui-react";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";

import Val24h from "./widgets/Val24h";
import LastVal from "./widgets/LastVal";
import Val7Day from "./widgets/Val7Day";
import UsagePercent from "./widgets/UsagePercent";
import DigitalInput from "./widgets/DigitalInput";
import MinVal from "./widgets/MinVal";
import MaxVal from "./widgets/MaxVal";

const DashboardPower = (props) => {
    const { equipment, measurements } = props;
    const org = useSelector((state) => state.org);

    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const { measurements_with_mt_type, has_e_act_counter, digital_measure } = useMemo(() => {
        return _.chain(measurements)
            .reduce(
                (res, measure) => {
                    const mt_type = _.find(measurementtypes.data, { id: measure.measurementtype });
                    const mt_type_tech = _.find(measurementtypes.data, { id: measure.measurementtype_tech });
                    if (mt_type_tech?.name === "e_act_counter") res.has_e_act_counter.push(measure);
                    if (mt_type_tech?.name === "di")
                        res.digital_measure = { ...measure, measurementtype: mt_type, measurementtype_tech: mt_type_tech };
                    res.measurements_with_mt_type.push({ ...measure, measurementtype: mt_type, measurementtype_tech: mt_type_tech });
                    return res;
                },
                { measurements_with_mt_type: [], has_e_act_counter: [], digital_measure: null }
            )
            .value();
    }, [measurements, measurementtypes]);

    const RenderWidgets = useMemo(() => {
        const totalMeasures = _.size(measurements_with_mt_type);
        return (
            <Grid.Row>
                {_.chain(measurements_with_mt_type)
                    .reduce((res, measure, idx) => {
                        const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
                        if (measure?.measurementtype_tech?.name === "p_act_import" && dataflow?.dataflowspec === 1) {
                            //Retrieve all dataflows base on e_act_counter measure and check if we display expert or non-expert
                            const df_e_act = _.map(has_e_act_counter, (item) => item.dataflow);
                            if (_.includes(df_e_act, measure.dataflow)) {
                                return res;
                            }
                        }

                        switch (totalMeasures) {
                            case 1:
                                res.push(
                                    <React.Fragment key={idx}>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <LastVal measure={measure} />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Val24h equipment={equipment} measure={measure} />
                                        </Grid.Column>
                                        {measure?.measurementtype?.datapoint_type === 3 && (
                                            <>
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    <Val7Day measure={measure} />
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    <UsagePercent measure={measure} />
                                                </Grid.Column>
                                            </>
                                        )}
                                        {measure?.measurementtype?.datapoint_type !== 3 && (
                                            <>
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    {<MinVal measure={measure} />}
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    {<MaxVal measure={measure} />}
                                                </Grid.Column>
                                            </>
                                        )}
                                    </React.Fragment>
                                );
                                return res;
                            case 2:
                                res.push(
                                    <React.Fragment key={idx}>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    <LastVal measure={measure} />
                                                </Grid.Column>
                                            }
                                        >
                                            <Popup.Content>
                                                {_.size(dataflow?.name) > 0 && (
                                                    <div>
                                                        <b>{dataflow?.name}</b>
                                                    </div>
                                                )}
                                            </Popup.Content>
                                        </Popup>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    <Val24h equipment={equipment} measure={measure} />
                                                </Grid.Column>
                                            }
                                        >
                                            <Popup.Content>
                                                {_.size(dataflow?.name) > 0 && (
                                                    <div>
                                                        <b>{dataflow?.name}</b>
                                                    </div>
                                                )}
                                            </Popup.Content>
                                        </Popup>
                                    </React.Fragment>
                                );
                                return res;
                            case 3:
                                res.push(
                                    <React.Fragment key={idx}>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    <LastVal measure={measure} />
                                                </Grid.Column>
                                            }
                                        >
                                            <Popup.Content>
                                                {_.size(dataflow?.name) > 0 && (
                                                    <div>
                                                        <b>{dataflow?.name}</b>
                                                    </div>
                                                )}
                                            </Popup.Content>
                                        </Popup>

                                        {idx === 0 && (
                                            <Popup
                                                position="top center"
                                                trigger={
                                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                                        <Val24h equipment={equipment} measure={measure} />
                                                    </Grid.Column>
                                                }
                                            >
                                                <Popup.Content>
                                                    {_.size(dataflow?.name) > 0 && (
                                                        <div>
                                                            <b>{dataflow?.name}</b>
                                                        </div>
                                                    )}
                                                </Popup.Content>
                                            </Popup>
                                        )}
                                    </React.Fragment>
                                );
                                return res;
                            default:
                                res.push(
                                    <React.Fragment key={idx}>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Grid.Column
                                                    mobile={16}
                                                    tablet={8}
                                                    computer={4}
                                                    style={{ borderBottom: "solid 0.5px #D4D4D5", borderRight: "solid 0.5px #D4D4D5" }}
                                                >
                                                    <LastVal measure={measure} />
                                                </Grid.Column>
                                            }
                                        >
                                            <Popup.Content>
                                                {_.size(dataflow?.name) > 0 && (
                                                    <div>
                                                        <b>{dataflow?.name}</b>
                                                    </div>
                                                )}
                                            </Popup.Content>
                                        </Popup>
                                    </React.Fragment>
                                );
                                return res;
                        }
                    }, [])
                    .value()}
            </Grid.Row>
        );
    }, [dataflows, equipment, measurements_with_mt_type, has_e_act_counter]);

    if (digital_measure) {
        return (
            <Grid.Row>
                <DigitalInput measure={digital_measure} />
            </Grid.Row>
        );
    }

    return RenderWidgets;
};

export default DashboardPower;
