import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";

export const main_default_equipment = {
    name: "",
    equipment_type: null,
    machine: null,
    site: null,
    zone: null,
    usage: null,
    tag_set: [],
    external_id: null
};

export const secondary_default_equipment = {
    internal_ref: "",
    brand: "",
    model: "",
    manufacturer_SN: "",
    manufacture_year: moment().year(),
    maintenance_manager: "",
    description: ""
};

export const syndataflowtypeEnum = {
    DERIVATIVENEGATIVE: 1,
    DERIVATIVEPOSITIVE: 3,
    HEATENERGY: 4,
    GENERICPRODUCT: 5
};

export const formulaTypeEnum = {
    SUMDIFFERENCE: 1,
    HEATENERGY: 2,
    GENERICPRODUCT: 3
};

export const formulaTypeOptions = [
    { key: 1, value: 1, text: t`Sum/Difference` },
    { key: 2, value: 2, text: t`Heat/Refrigeration/Hydraulic energy` },
    { key: 3, value: 3, text: t`Generic Multiplication` }
];

export const syndataflowtypeOptions = [
    { key: 3, value: 3, text: t`derivative must be >= 0` },
    { key: 1, value: 1, text: t`values and derivative can be < 0` }
];

export const equipmenttypeOptions = [
    { key: 1, value: 1, text: t`other` },
    { key: 2, value: 2, text: t`transformer station` },
    { key: 3, value: 3, text: t`LV switchboard` },
    { key: 4, value: 4, text: t`canalis` },
    { key: 5, value: 5, text: t`building main feed` },
    { key: 6, value: 6, text: t`workshop main feed` },
    { key: 7, value: 7, text: t`rotating machine` },
    { key: 8, value: 8, text: t`other machine type` }
];

export const transferTypeOptions = [
    { key: 1, value: 1, text: t`To new equipment` },
    { key: 2, value: 2, text: t`To existing equiment` }
];

/**
 * Use this function for display_unit transformation in equipment null => -1 / -1 => null
 * semantic UI use -1 && database needs null
 * @function process_auto_unit
 * @param {objet} equipement
 * @returns
 */
export const process_auto_unit = (equipment, server = false) => {
    return _.reduce(
        equipment,
        (res, data, field) => {
            if (field === "dataflow_set") {
                res[field] = _.chain(data)
                    .map((dataflow) => {
                        //iteration on dataflows
                        return _.reduce(
                            dataflow,
                            (df_res, df_data, df_field) => {
                                if (df_field === "measurement_set") {
                                    df_res[df_field] = _.chain(df_data)
                                        .map((measure) => {
                                            //iteration on measurements
                                            return _.reduce(
                                                measure,
                                                (m_res, m_data, m_field) => {
                                                    //check display_unit data if -1, null, or id_unit
                                                    if (m_field === "display_unit" && m_data === -1 && server === true) {
                                                        m_res[m_field] = null; //null for server
                                                    } else if (m_field === "display_unit" && m_data === null && server === false) {
                                                        m_res[m_field] = -1; //-1 for semantic and form display
                                                    } else {
                                                        m_res[m_field] = m_data;
                                                    }
                                                    return m_res;
                                                },
                                                {}
                                            );
                                        })
                                        .value();
                                } else {
                                    df_res[df_field] = df_data;
                                }
                                return df_res;
                            },
                            {}
                        );
                    })
                    .value();
            } else {
                res[field] = data;
            }
            return res;
        },
        {}
    );
};

export const standbyThresholdUnit = (unit) => {
    switch (unit) {
        case "kWh":
            return "kW";
        case "m³":
            return "m³";
        case "Nm³":
            return "Nm³";
        default:
            return unit;
    }
};

export const deleteExclusion = [
    "pwa_backend.SynDataFlow_measurements",
    "pwa_backend.DataFlow",
    "pwa_clients.EnergySavingActions_numerator_measurements",
    "pwa_clients.AlertMeasurement",
    "pwa_clients.EquipmentTag",
    "pwa_clients.DiagramNode",
    "pwa_clients.ScheduleExport_measurements"
];

export const modifiedExclusion = [];

export const deleteExclusionDetach = [
    "organization.Site",
    "organization.SiteRight",
    "pwa_clients.CalendarWeek",
    "pwa_clients.SiteStation",
    "pwa_clients.AnalysisSection_sites",
    "pwa_clients.Widget_sites"
];
export const modifiedExclusionDetach = [];

export const analog_cat_whitelist = ["analog", "co2", "water", "gas", "hygrometry", "light", "motion", "pm25", "solar_irradiance", "temperature"];

export const pulse_cat_whitelist = ["pulse", "elec", "gas", "water", "calories", "frigories"];
