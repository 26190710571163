import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { toast } from "react-toastify";
import moment from "moment";
import { Dropdown, Grid, Header, Icon, Segment, Button, Input, Label, Menu, Popup } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHolding } from "@fortawesome/free-solid-svg-icons";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { setRefetchRequest } from "../dashboardDndSlice";
import { useGetDashboardsQuery, usePatchDashboardMutation } from "../dashboardDndService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DashboardChoice from "./DashboardChoice";
import DashboardCreate from "./DashboardCreate";
import DashboardDelete from "./DashboardDelete";
import DashboardUpdate from "./DashboardUpdate";
import WidgetsPanel from "./WidgetsPanel";
import WidgetsCreateUpdatePanel from "./WidgetsCreateUpdatePanel";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

/**
 * Used of customizing input element with semantic UI
 * @function renderInput
 * @param {object} props - extra props for input
 * @param {func} openCalendar - callback when open calendar
 * @param {func} closeCalendar - callback when close calendar
 */
const renderInput = (props, openCalendar, closeCalendar) => {
    const { err, ...rest } = props;
    return (
        <div>
            <Input fluid error={err} icon="calendar" label={{ content: rest.name }} labelPosition="left" {...rest} />
            {err && (
                <Label pointing={"above"} basic color="red">
                    <Trans>invalid day</Trans>
                </Label>
            )}
        </div>
    );
};

export const DashboardDnd = (props) => {
    const dispatch = useDispatch();
    const bottomRef = useRef(null);
    const now = moment();
    const { id } = useParams();
    const { org, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const [selectedDashboard, setSelectedDashboard] = useState(parseInt(id));
    const [openModalWidgetForm, setOpenModalWidgetForm] = useState(false);

    // Time section
    const query = new URLSearchParams(props.location.search);
    const urlDate = query.get("date") ? moment(query.get("date")) : now.clone();

    const [chooseEnd, setChooseEnd] = useState({ end: urlDate, err: false });

    //Always add day+1 for widget data requests. For some depth of history, backend remove 1 day
    const [end, setEnd] = useState({ end: urlDate.clone().add(1, "day"), err: false });
    const [disable, setDisable] = useState(true);
    const disable_btn = end.err || disable;
    // End time section

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });

    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const dashboards = useGetDashboardsQuery({ org: org.current }, { skip: !org.current });
    const [changeDefaultDashboard, chg_default] = usePatchDashboardMutation();
    const [changeOwnershipDashboard, chg_owner] = usePatchDashboardMutation();

    useEffect(() => {
        if (chg_default.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (chg_default.isSuccess) {
            toast.success(i18n._(t`Successful change default dashboard`), toast_options);
        }
        if (chg_default.isError) {
            let error = i18n._(t`Can't change default dashboard`);
            if (chg_default.error?.data && !_.includes(chg_default.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={chg_default.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [chg_default]);

    useEffect(() => {
        if (chg_owner.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (chg_owner.isSuccess) {
            toast.success(i18n._(t`Successful change owner of dashboard`), toast_options);
        }
        if (chg_owner.isError) {
            let error = i18n._(t`Can't change owner of dashboard`);
            if (chg_owner.error?.data && !_.includes(chg_owner.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={chg_owner.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [chg_owner]);

    const onChangeEnd = (date) => {
        if (_.isString(date) || !date.isValid()) {
            setChooseEnd({ end: date, err: true });
            setDisable(true);
        } else {
            setChooseEnd({ end: date, err: false });
            setDisable(false);
        }
    };

    const runAnalyse = () => {
        setEnd({ ...chooseEnd, end: chooseEnd.end.clone().add(1, "day") });
        setDisable(true);
    };

    const err_list = [
        sites.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        categories.isError,
        units.isError,
        equipments.isError,
        dataflows.isError,
        measurementtypes.isError,
        measurements.isError,
        dashboards.isError
    ];

    const status_list = [
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        units.isSuccess,
        measurementtypes.isSuccess,
        equipments.isSuccess,
        dataflows.isSuccess,
        measurements.isSuccess,
        dashboards.isSuccess
    ];

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader no-print">
                    <Grid.Column width={2}>
                        <Back target={"/"} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>customdashboard</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {(() => {
                    if (_.some(err_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else if (_.every(status_list)) {
                        const dashboards_dnd = dashboards?.data ?? []; //For semantic ui
                        const owner_dashboard = _.chain(dashboards_dnd).groupBy("owner_email").pick(auth.user.email).value();

                        const chain_wrapper = _.isEmpty(owner_dashboard)
                            ? _.chain(dashboards_dnd) //Display first natural dashboard based on request (need ordering ? by id ?)
                            : _.chain(owner_dashboard).get(auth.user.email, []).orderBy("default_date", "desc"); //Display recent 'default_date' first is "owner"

                        const default_dashboard_id = chain_wrapper.head().get("id").defaultTo(null).value();

                        const current_dashboard = _.find(dashboards_dnd, {
                            id: selectedDashboard || default_dashboard_id
                        });
                        const disabled_dashboard = auth.user.email !== current_dashboard?.owner_email || notification.srv_status.db_status !== "rw";

                        return (
                            <>
                                {openModalWidgetForm && (
                                    <WidgetsCreateUpdatePanel
                                        current_dashboard={current_dashboard}
                                        org={org.current}
                                        disabled={disabled_dashboard}
                                        setOpenModalWidgetForm={setOpenModalWidgetForm}
                                    />
                                )}
                                <Grid.Row className="no-print" verticalAlign="middle">
                                    <Grid.Column mobile={16} tablet={8} computer={6} largeScreen={5} widescreen={5} textAlign="center">
                                        <Menu compact>
                                            <DashboardChoice
                                                dashboards={dashboards_dnd}
                                                setSelectedDashboard={setSelectedDashboard}
                                                current_dashboard={current_dashboard}
                                                user_email={auth.user.email}
                                                id={id}
                                            />
                                            {notification.srv_status.db_status === "rw" && (
                                                <>
                                                    <Dropdown item icon="bars" simple>
                                                        <Dropdown.Menu>
                                                            {current_dashboard && !disabled_dashboard && (
                                                                <>
                                                                    <Dropdown.Header>
                                                                        - <Trans>Widget</Trans> -
                                                                    </Dropdown.Header>
                                                                    <Dropdown.Item
                                                                        onClick={(e) => {
                                                                            setOpenModalWidgetForm(true);
                                                                        }}
                                                                    >
                                                                        <Icon name="add circle" />
                                                                        <Trans>Add widget</Trans>
                                                                    </Dropdown.Item>
                                                                </>
                                                            )}
                                                            <Dropdown.Header>
                                                                - <Trans>Dashboard</Trans> -
                                                            </Dropdown.Header>
                                                            {/* Dashboard create */}
                                                            <DashboardCreate
                                                                setSelectedDashboard={setSelectedDashboard}
                                                                dashboards={dashboards_dnd}
                                                            />
                                                            {current_dashboard && (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={(e, data) => {
                                                                            if (!disabled_dashboard)
                                                                                changeDefaultDashboard({
                                                                                    org: org.current,
                                                                                    data: {
                                                                                        ..._.pick(current_dashboard, ["id", "default_date"]),
                                                                                        default_date: moment().toISOString()
                                                                                    }
                                                                                });
                                                                        }}
                                                                        disabled={disabled_dashboard}
                                                                    >
                                                                        <Icon name="mouse pointer" />
                                                                        <Trans>Set default dashboard</Trans>
                                                                    </Dropdown.Item>

                                                                    <DashboardUpdate
                                                                        current_dashboard={current_dashboard}
                                                                        disabled={disabled_dashboard}
                                                                        dashboards={dashboards_dnd}
                                                                    />
                                                                    {!disabled_dashboard && (
                                                                        <DashboardDelete
                                                                            org={org.current}
                                                                            current_dashboard={current_dashboard}
                                                                            disabled={disabled_dashboard}
                                                                            setSelectedDashboard={setSelectedDashboard}
                                                                        />
                                                                    )}
                                                                    {auth.rights?.is_admin && current_dashboard?.owner !== auth.user.user_id && (
                                                                        <Dropdown.Item
                                                                            onClick={(e, data) => {
                                                                                if (
                                                                                    auth.rights?.is_admin &&
                                                                                    current_dashboard?.owner !== auth.user.user_id
                                                                                )
                                                                                    changeOwnershipDashboard({
                                                                                        org: org.current,
                                                                                        data: {
                                                                                            ..._.pick(current_dashboard, ["id", "owner"]),
                                                                                            owner: auth.user?.user_id
                                                                                        }
                                                                                    });
                                                                            }}
                                                                            disabled={
                                                                                !(
                                                                                    auth.rights?.is_admin &&
                                                                                    current_dashboard?.owner !== auth.user.user_id
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faHandHolding} className="icon" />
                                                                            <Trans>Take ownership of dashboard</Trans>
                                                                        </Dropdown.Item>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )}
                                        </Menu>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={5} largeScreen={4} widescreen={3}>
                                        {/* DATEPICKER end */}
                                        <Datetime
                                            locale={current_lng}
                                            value={chooseEnd.end}
                                            timeFormat={false}
                                            onChange={onChangeEnd}
                                            renderInput={renderInput}
                                            inputProps={{
                                                name: <Trans>end period</Trans>,
                                                err: chooseEnd.err
                                            }}
                                            isValidDate={(current) => {
                                                return current.isBefore(now);
                                            }}
                                        />
                                        {/* END DATEPICKER end */}
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={16} computer={2} textAlign="center">
                                        <Button disabled={disable_btn} secondary={!disable_btn} onClick={runAnalyse}>
                                            <Trans>Apply</Trans>
                                        </Button>
                                        <Popup
                                            trigger={<Button icon="undo alternate" onClick={() => dispatch(setRefetchRequest(moment().unix()))} />}
                                        >
                                            <Popup.Content>
                                                <Trans>Refresh dashboard</Trans>
                                            </Popup.Content>
                                        </Popup>
                                    </Grid.Column>
                                    <WidgetsPanel
                                        current_dashboard={current_dashboard}
                                        org={org}
                                        disabled_dashboard={disabled_dashboard}
                                        end={end}
                                        categories={categories.data}
                                        sites={sites.data}
                                        zones={zones.data}
                                        tags={tags.data}
                                        usages={usages.data}
                                    />
                                </Grid.Row>
                            </>
                        );
                    } else {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                })()}
            </Grid>
            <div ref={bottomRef} />
        </Segment>
    );
};

export default React.memo(DashboardDnd);
