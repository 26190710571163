import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { getDetail, resetTab, setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions } from "modules/time/utils";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import HeaderWHelper from "modules/common/components/HeaderWHelper";
import GraphicDetail from "../graphic/GraphicDetail";
import { CustomSVGSeries } from "react-vis";
import EventWidget from "modules/common/components/EventWidget";

const TabElecState = (props) => {
    const tab = "tabelecstate";
    const { default_time, idx } = props;
    const measures = _.orderBy(props.measures, "id");
    const dispatch = useDispatch();
    const { org } = useSelector((state) => state);
    const { tabelecstate, powertime } = useSelector((state) => state.overview);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));
    const rangeTime = powertime.time
        ? {
              start: moment(powertime.time.start),
              end: moment(powertime.time.end),
              plage: powertime.time.plage
          }
        : default_time;

    const measures_current_imbalance = _.filter(measures, (measure) => _.get(measure, "measurementtype.name") === "current_imbalance");
    const measures_thd = _.filter(measures, (measure) =>
        _.includes(["thd", "thd_ph1", "thd_ph2", "thd_ph3"], _.get(measure, "measurementtype.name"))
    );
    const measures_i0_ratio = _.filter(measures, (measure) => _.get(measure, "measurementtype.name") === "i0_ratio");
    const measures_power_imbalance = _.filter(measures, (measure) => _.get(measure, "measurementtype.name") === "power_imbalance");
    const measures_i_peak = _.filter(measures, (measure) => _.get(measure, "measurementtype.name") === "i_peak");

    useEffect(() => {
        (async () => {
            if (powertime.time) {
                const { start, end } = powertime.time;
                _.size(measures_current_imbalance) > 0 &&
                    dispatch(getDetail({ org: org.current, measures: measures_current_imbalance, start, end, tab, type: "currentimbalance" }));
                _.size(measures_thd) > 0 && dispatch(getDetail({ org: org.current, measures: measures_thd, start, end, tab, type: "thd" }));
                _.size(measures_i0_ratio) > 0 &&
                    dispatch(getDetail({ org: org.current, measures: measures_i0_ratio, start, end, tab, type: "i0_ratio" }));
                _.size(measures_power_imbalance) > 0 &&
                    dispatch(getDetail({ org: org.current, measures: measures_power_imbalance, start, end, tab, type: "powerimbalance" }));
                _.size(measures_i_peak) > 0 && dispatch(getDetail({ org: org.current, measures: measures_i_peak, start, end, tab, type: "i_peak" }));
            } else {
                _.size(measures_current_imbalance) > 0 &&
                    dispatch(
                        getDetail({
                            org: org.current,
                            measures: measures_current_imbalance,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "currentimbalance"
                        })
                    );
                _.size(measures_thd) > 0 &&
                    dispatch(
                        getDetail({
                            org: org.current,
                            measures: measures_thd,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "thd"
                        })
                    );
                _.size(measures_i0_ratio) > 0 &&
                    dispatch(
                        getDetail({
                            org: org.current,
                            measures: measures_i0_ratio,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "i0_ratio"
                        })
                    );
                _.size(measures_power_imbalance) > 0 &&
                    dispatch(
                        getDetail({
                            org: org.current,
                            measures: measures_power_imbalance,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "powerimbalance"
                        })
                    );
                _.size(measures_i_peak) > 0 &&
                    dispatch(
                        getDetail({
                            org: org.current,
                            measures: measures_i_peak,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "i_peak"
                        })
                    );
            }
        })();
        return () => {
            dispatch(resetTab(tab));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powertime.time, idx]);

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "powertime" }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            {_.size(measures_thd) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>thd</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            La distorsion harmonique de la puissance consommée provient généralement des charges inductives et
                                            capacitives des équipements et contribue a augmenter la puissance apparente et les pertes thermiques
                                            subies dans les cables.
                                        </p>
                                        <p>
                                            A des niveaux excessifs, particulièrement à haute fréquence, la distorsion harmonique peut entrainer des
                                            effets de peau dans les cables associés à des risques de surchauffe.
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_thd) === 0 && (
                            <MessageDisplay message={i18n._(t`no thd measure`)} level="info" iconName="info circle" isLoading={false} />
                        )}
                        {tabelecstate.thd.status === "loading" && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {tabelecstate.thd.status === "failed" && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {tabelecstate.thd.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecstate.thd.data}
                                SerieType={CustomSVGSeries}
                                customComponent="diamond"
                                markSize={6}
                                csvName={_.get(props, "equipment.name", "export")}
                                threshold={30}
                            >
                                <EventWidget
                                    type={<Trans>hours of excessive thd</Trans>}
                                    helper={<Trans>hours of excessive thd helper</Trans>}
                                    events={_.chain(tabelecstate.thd.data)
                                        .reduce((res, serie) => {
                                            const { data } = serie;
                                            _.each(data, (record) => {
                                                //Here threshold = 2
                                                if (_.chain(record).get("y").value() > 30) {
                                                    res.push(1);
                                                }
                                            });
                                            return res;
                                        }, [])
                                        .sum()
                                        .value()}
                                    icon="signal"
                                />
                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_power_imbalance) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>power imbalance</Trans>}
                                helper={
                                    <Trans>
                                        <p>power imbalance helper</p>
                                        <p>power imbalance helper 2</p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_power_imbalance) === 0 && (
                            <MessageDisplay message={i18n._(t`no power imbalance measure`)} level="info" iconName="info circle" isLoading={false} />
                        )}
                        {tabelecstate.powerimbalance.status === "loading" && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {tabelecstate.powerimbalance.status === "failed" && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {tabelecstate.powerimbalance.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecstate.powerimbalance.data}
                                SerieType={CustomSVGSeries}
                                customComponent="diamond"
                                markSize={6}
                                csvName={_.get(props, "equipment.name", "export")}
                                threshold={10}
                            >
                                <EventWidget
                                    type={<Trans>power unbalanced hours</Trans>}
                                    helper={<Trans>power unbalanced hours helper</Trans>}
                                    events={_.chain(tabelecstate.powerimbalance.data)
                                        .reduce((res, serie) => {
                                            const { data } = serie;
                                            _.each(data, (record) => {
                                                //Here threshold = 10
                                                if (_.chain(record).get("y").value() > 10) {
                                                    res.push(1);
                                                }
                                            });
                                            return res;
                                        }, [])
                                        .sum()
                                        .value()}
                                    icon="balance"
                                />

                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_current_imbalance) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>current imbalance</Trans>}
                                helper={
                                    <Trans>
                                        <p>current imbalance helper</p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_current_imbalance) === 0 && (
                            <MessageDisplay message={i18n._(t`no current imbalance measure`)} level="info" iconName="info circle" isLoading={false} />
                        )}
                        {tabelecstate.currentimbalance.status === "loading" && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {tabelecstate.currentimbalance.status === "failed" && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {tabelecstate.currentimbalance.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecstate.currentimbalance.data}
                                SerieType={CustomSVGSeries}
                                customComponent="diamond"
                                markSize={6}
                                csvName={_.get(props, "equipment.name", "export")}
                                threshold={2}
                            >
                                <EventWidget
                                    type={<Trans>current unbalanced hours</Trans>}
                                    helper={<Trans>current unbalanced hours helper</Trans>}
                                    events={_.chain(tabelecstate.currentimbalance.data)
                                        .reduce((res, serie) => {
                                            const { data } = serie;
                                            _.each(data, (record) => {
                                                //Here threshold = 10
                                                if (_.chain(record).get("y").value() > 2) {
                                                    res.push(1);
                                                }
                                            });
                                            return res;
                                        }, [])
                                        .sum()
                                        .value()}
                                    icon="balance"
                                />

                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_i0_ratio) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>neutral current</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            Pour les équipements triphasés équilibrés, les 3 courants circulant dans les 3 phases doivent s’équilibrer
                                            entre eux.
                                        </p>
                                        <p>
                                            Dans le cas contraire et en l’absence de cable de Neutre, des risques de fuite de courant à la terre
                                            existent et peuvent gravement détériorer la machine.
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_i0_ratio) === 0 && (
                            <MessageDisplay message={i18n._(t`no i0 ratio measure`)} level="info" iconName="info circle" isLoading={false} />
                        )}
                        {tabelecstate.i0_ratio.status === "loading" && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {tabelecstate.i0_ratio.status === "failed" && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {tabelecstate.i0_ratio.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecstate.i0_ratio.data}
                                SerieType={CustomSVGSeries}
                                customComponent="diamond"
                                markSize={6}
                                csvName={_.get(props, "equipment.name", "export")}
                                threshold={10}
                            >
                                <EventWidget
                                    type={<Trans>hours current imbalance</Trans>}
                                    helper={<Trans>hours current imbalance helper</Trans>}
                                    events={_.chain(tabelecstate.i0_ratio.data)
                                        .reduce((res, serie) => {
                                            const { data } = serie;
                                            _.each(data, (record) => {
                                                //Here threshold = 10
                                                if (_.chain(record).get("y").value() > 10) {
                                                    res.push(1);
                                                }
                                            });
                                            return res;
                                        }, [])
                                        .sum()
                                        .value()}
                                    icon="balance"
                                />

                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_i_peak) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper text={<Trans>ipeak</Trans>} helper={<Trans>ipeak helper</Trans>} />
                        </Header>
                        {_.size(measures_i_peak) === 0 && (
                            <MessageDisplay message={i18n._(t`no i peak measure`)} level="info" iconName="info circle" isLoading={false} />
                        )}
                        {tabelecstate.i_peak.status === "loading" && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {tabelecstate.i_peak.status === "failed" && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {tabelecstate.i_peak.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecstate.i_peak.data}
                                SerieType={CustomSVGSeries}
                                customComponent="diamond"
                                markSize={6}
                                csvName={_.get(props, "equipment.name", "export")}
                            >
                                <EventWidget helper={<Trans>Ipeak event helper</Trans>} events={0} icon="hotjar" />
                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};

export default TabElecState;
