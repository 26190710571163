import React from "react";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import { Grid, Image, Segment, Button, Divider, Menu } from "semantic-ui-react";

import logo from "images/logo-full.svg";
import i18n from "modules/i18n/i18nConfig";
import { regexEmail } from "modules/common/utils";
import { useFetchResetPasswordMutation } from "../authService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Back from "modules/common/components/back";
import Maintenance from "modules/common/components/Maintenance";

import { InputAdapter } from "modules/common/components/form";
import DropdownI18n from "modules/i18n/components/DropdownI18n";
import ThemeSwitch from "modules/common/components/ThemeSwitch";

export const ForgetPassword = (props) => {
    const { notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const [fetchResetPassword, fetch] = useFetchResetPasswordMutation();

    const onSubmit = async (formValues) => {
        if (notification.srv_status.db_status === "rw") {
            await fetchResetPassword({ data: formValues, lng: current_lng });
        }
    };

    return (
        <Grid textAlign="center" verticalAlign="middle" id="pwaLogin">
            <Grid.Column>
                <Image src={logo} size="medium" centered />
                <Divider hidden />
                <Menu attached style={{ borderBottom: "none" }}>
                    <Menu.Menu position="right">
                        {window.location.hostname === "localhost" && <ThemeSwitch />}
                        <DropdownI18n />
                    </Menu.Menu>
                </Menu>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, pristine, submitting, invalid }) => {
                        return (
                            <Grid centered verticalAlign="middle">
                                <Grid.Column width={16}>
                                    <Segment padded attached tertiary>
                                        <Trans>
                                            If you&lsquo;d like to reset your password, please enter your email here and a link to do so will be sent
                                            to the address you enter.
                                        </Trans>
                                    </Segment>
                                    <form className="ui large form error" onSubmit={handleSubmit}>
                                        <Maintenance />
                                        <Segment padded attached>
                                            <Field
                                                name="email"
                                                type="email"
                                                label={i18n._(t`email`)}
                                                isRequired={true}
                                                placeholder={i18n._(t`email address`)}
                                                component={InputAdapter}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return <Trans>Adresse électronique requise</Trans>;
                                                    } else if (!regexEmail.test(value)) {
                                                        return <Trans>Adresse électronique invalide</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                            {fetch.isError && (
                                                <MessageDisplay
                                                    message={i18n._(t`send request error`)}
                                                    level="error"
                                                    iconName="warning circle"
                                                    isLoading={false}
                                                    attached={false}
                                                />
                                            )}
                                            {fetch.isFetching && (
                                                <MessageDisplay
                                                    message={i18n._(t`email sent to server`)}
                                                    level="info"
                                                    iconName=""
                                                    isLoading={false}
                                                    attached={false}
                                                />
                                            )}
                                            {fetch.isSuccess && (
                                                <MessageDisplay
                                                    message={i18n._(t`the server processes your request. Check your mailbox`)}
                                                    level="success"
                                                    iconName=""
                                                    isLoading={false}
                                                    attached={false}
                                                />
                                            )}
                                            {notification.srv_status.db_status === "rw" && (
                                                <Button disabled={pristine || submitting || invalid} fluid color="olive">
                                                    <Trans>send</Trans>
                                                </Button>
                                            )}
                                        </Segment>
                                    </form>
                                </Grid.Column>
                                <Grid.Column width={16} textAlign="center">
                                    <Back />
                                </Grid.Column>
                            </Grid>
                        );
                    }}
                />
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(ForgetPassword);
