import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Modal, Popup, Segment, Checkbox, Icon, Grid, Divider } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useDeleteMailingMutation } from "../mainlingService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeleteMailingsList = (props) => {
    const { item } = props;

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { org } = useSelector((state) => state);

    const [deleteMailing, deleted] = useDeleteMailingMutation();
    const [waitingDelete, wDeleted] = useDeleteMailingMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete mailing`), toast_options);
            setConfirm(false);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete mailing`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted]);

    const handleClick = async (e) => {
        setOpen(true);
        await waitingDelete({ org: org.current, data: item.id });
    };

    const renderSuccess = useMemo(() => {
        if (wDeleted.isSuccess) {
            const deleteElements = _.omit(wDeleted.data?.objects ?? {}, ["pwa_clients.MailingListMember", "pwa_clients.MailingList"]);

            return (
                <>
                    <Segment basic>
                        <div style={{ color: "#794b02", fontStyle: "italic" }}>
                            {!_.isEmpty(deleteElements) && (
                                <Grid centered>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Trans>
                                                Deleting this mailing list leads to the deletion of several elements related to this site and all data{" "}
                                                <strong>will be erased</strong>.
                                            </Trans>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Trans>Related data that will be deleted:</Trans>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <table>
                                                <tbody>
                                                    {_.map(deleteElements, (item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{i18n._(key)}</td>
                                                                <td>
                                                                    <strong>{item}</strong>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        </div>
                    </Segment>
                    <Segment basic>
                        <Checkbox
                            label={
                                <label>
                                    <Trans>yes, i confirm deletion</Trans>
                                </label>
                            }
                            onChange={(e, data) => setConfirm(data.checked)}
                            checked={confirm}
                        />
                    </Segment>
                </>
            );
        }
        return null;
    }, [wDeleted, confirm]);

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            trigger={
                <Popup trigger={<Button icon="delete" onClick={handleClick} />}>
                    <Popup.Content>
                        <Trans>Delete mailing list</Trans>
                    </Popup.Content>
                </Popup>
            }
            onClose={() => {
                setOpen(false);
                setConfirm(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Trans>want delete mailing list {item.name}</Trans>
            </Modal.Header>
            <Modal.Content>
                {wDeleted.isError && (
                    <MessageDisplay
                        message={
                            <>
                                {/* check 404 not found to prevent incorrect message display */}
                                <Trans>You can't delete this mailing list.</Trans>
                                <Divider />
                                {wDeleted.error?.originalStatus !== 404 && wDeleted?.error?.data !== undefined && (
                                    <RequestErrorRender errors={wDeleted?.error?.data} />
                                )}
                            </>
                        }
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                    />
                )}
                {wDeleted.isFetching && (
                    <MessageDisplay message={i18n._(t`send request to server`)} level="info" iconName="info circle" isLoading={false} />
                )}
                {wDeleted.isSuccess && renderSuccess}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => {
                        setOpen(false);
                        setConfirm(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                {wDeleted.data?.objects && (
                    <Button
                        labelPosition="right"
                        icon
                        positive
                        onClick={(e) => {
                            setOpen(false);
                            deleteMailing({ org: org.current, data: item.id, confirm: true });
                        }}
                        disabled={!confirm}
                    >
                        <Icon name="check" />
                        <Trans>validate</Trans>
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteMailingsList;
