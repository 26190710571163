import { getTheme } from "modules/common/utils";

const theme = getTheme();

class Palette {}

if (theme === "old") {
    Palette.named = {
        blue: "#4878D0",
        orange: "#EE854A",
        green: "#089537",
        red: "#D65F5F",
        violet: "#956CB4",
        brown: "#8C613C",
        pink: "#DC7EC0",
        grey: "#A9A9A9",
        lightGrey: "D3D3D3",
        yellow: "#D5BB67",
        cyan: "#82C6E2",
        black: "#000000",
        darkblue: "#3b5998"
    };

    /*  muted, bright, dark, pastel */
    Palette.circles = [
        "#4878D0",
        "#EE854A",
        "#089537",
        "#D65F5F",
        "#956CB4",
        "#8C613C",
        "#DC7EC0",
        "#D5BB67",
        "#82C6E2",
        "#001C7F",
        "#B1400D",
        "#12711C",
        "#8C0800",
        "#591E71",
        "#592F0D",
        "#A23582",
        "#B8850A",
        "#006374",
        "#FF9F9B",
        "#D0BBFF",
        "#DEBB9B",
        "#FAB0E4",
        "#FFFEA3",
        "#7D4F69",
        "#F19066",
        "#B8E186",
        "#3F729B",
        "#F6C3B7",
        "#8C735B",
        "#B5D8EB",
        "#724F40",
        "#ED95B8",
        "#D7DB90",
        "#58A4B0",
        "#6F45A1",
        "#F49F0A",
        "#5DA529",
        "#E0215D",
        "#B98B60",
        "#236B4E",
        "#A763BB",
        "#F4897D",
        "#8EADFF",
        "#F3B0A6",
        "#6E2B62",
        "#3E685B",
        "#E2B83B",
        "#B76D57",
        "#44A6D8",
        "#C3F364",
        "#9462A8",
        "#F98EAF",
        "#A0C55F",
        "#4E97B4",
        "#CB6284",
        "#EAC8A9",
        "#658CC7",
        "#85D295",
        "#FBB9A8",
        "#A6C8E9",
        "#E5D97B",
        "#CD86B8",
        "#678D7E",
        "#B5A13A",
        "#DE72A5",
        "#CAB0D1",
        "#6F4B4F",
        "#82B9A8",
        "#ECCEA9",
        "#9F5263",
        "#B9F2F0"
    ];

    Palette.ranges = {
        blue: ["#001C7F", "#A1C9F4"],
        orange: ["#B1400D", "#FFB482"],
        green: ["#12711C", "#8DE5A1"],
        red: ["#8C0800", "#FF9F9B"],
        violet: ["#956CB4", "#D0BBFF"],
        brown: ["#592F0D", "#DEBB9B"],
        pink: ["#A23582", "#FAB0E4"],
        grey: ["#3C3C3C", "#CFCFCF"],
        yellow: ["#B8850A", "#FFFEA3"],
        cyan: ["#82C6E2", "#B9F2F0"]
    };
} else {
    Palette.named = {
        blue: "#00445F",
        orange: "#B86300",
        green: "#3C7029",
        red: "#A30F0C",
        violet: "#956CB4",
        brown: "#522C00",
        pink: "#DC7EC0",
        grey: "#666666",
        lightGrey: "#9E9E9E",
        yellow: "#F7D52A",
        cyan: "#1C98B5",
        black: "#212121",
        darkblue: "#3b5998"
    };

    /*  Schaeffler Charts colors */
    Palette.circles = [
        "#00445F",
        "#B86300",
        "#3C7029",
        "#A30F0C",
        "#956CB4",
        "#522C00",
        "#9E9E9E",
        "#F7D52A",
        "#1C98B5",
        "#E9B300",
        "#619685",
        "#AFC2C7",
        "#C9DEA5",
        "#67898F",
        "#20617C",
        "#94BF99",
        "#237864",
        "#8DA8BC",
        "#8CBFD3",
        "#476E75",
        "#73B281",
        "#95B7AB",
        "#D6C7A4",
        "#A1C861",
        "#C4A200"
    ];
    Palette.ranges = {
        blue: ["#001C7F", "#A1C9F4"],
        orange: ["#B1400D", "#FFB482"],
        green: ["#12711C", "#8DE5A1"],
        red: ["#8C0800", "#FF9F9B"],
        violet: ["#956CB4", "#D0BBFF"],
        brown: ["#592F0D", "#DEBB9B"],
        pink: ["#A23582", "#FAB0E4"],
        grey: ["#3C3C3C", "#CFCFCF"],
        yellow: ["#B8850A", "#FFFEA3"],
        cyan: ["#1C98B5", "#B9F2F0"]
    };
}
/*
cyan
'#3b748a'
'#72bfc4'

array([[ 55.32941176,  83.47058824,  94.2       ],
    [ 59.77908497, 116.84313725, 138.6       ],
    [ 64.10849673, 149.31372549, 181.8       ],
    [ 82.82614379, 174.65751634, 201.43660131],
    [114.92026144, 191.50457516, 196.44836601],
    [147.90588235, 208.81960784, 191.32156863]])
*/

export default Palette;
