import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { toast } from "react-toastify";
import moment from "moment";
import { Dropdown, Grid, Header, Icon, Segment, Button, Input, Label, Divider, Menu } from "semantic-ui-react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHolding } from "@fortawesome/free-solid-svg-icons";

import i18n from "modules/i18n/i18nConfig";
import { historic_options } from "../utils";
import { comparison_options, process_analysis_period } from "../utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import { useGetAdvancedAnalysisQuery, usePatchAdvancedAnalysisMutation } from "../analysisAdvancedService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import Back from "modules/common/components/back";
import AnalysisAdvancedCreate from "./AnalysisAdvancedCreate";
import AnalysisSection from "./AnalysisSection";
import MessageDisplay from "modules/common/components/MessageDisplay";
import AnalysisAdvancedUpdate from "./AnalysisAdvancedUpdate";
import AnalysisSectionCreate from "./AnalysisSectionCreate";
import AnalysisAdvancedChoice from "./AnalysisAdvancedChoice";
import AnalysisAdvancedDelete from "./AnalysisAdvancedDelete";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

/**
 * Used of customizing input element with semantic UI
 * @function renderInput
 * @param {object} props - extra props for input
 * @param {func} openCalendar - callback when open calendar
 * @param {func} closeCalendar - callback when close calendar
 */
const renderInput = (props, openCalendar, closeCalendar) => {
    const { err, ...rest } = props;
    return (
        <div>
            <Input fluid error={err} icon="calendar" label={{ content: rest.name }} labelPosition="left" {...rest} />
            {err && (
                <Label pointing={"above"} basic color="red">
                    <Trans>invalid day</Trans>
                </Label>
            )}
        </div>
    );
};

export const AnalysisAdvanced = (props) => {
    const bottomRef = useRef(null);
    const now = moment();
    const { id } = useParams();
    const { org, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const [selectedAdvAnalysis, setSelectedAdvAnalysis] = useState(parseInt(id));

    // Time section
    const query = new URLSearchParams(props.location.search);
    const urlDate = query.get("date") ? moment(query.get("date")) : now.clone().subtract(1, "day");

    const [sectionAdded, setSectionAdded] = useState(false);
    const [chooseEnd, setChooseEnd] = useState({ end: urlDate, err: false });
    const [end, setEnd] = useState({ end: urlDate.clone().add(1, "day"), err: false });
    const [disable, setDisable] = useState(true);
    const disable_btn = end.err || disable;
    // End time section

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const advanced_analysis = useGetAdvancedAnalysisQuery({ org: org.current }, { skip: !org.current });
    const [changeDefaultAnalysis, chg_default] = usePatchAdvancedAnalysisMutation();
    const [changeOwnershipAnalysis, chg_owner] = usePatchAdvancedAnalysisMutation();

    useEffect(() => {
        if (chg_default.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (chg_default.isSuccess) {
            toast.success(i18n._(t`successful change default advanced analysis`), toast_options);
        }
        if (chg_default.isError) {
            let error = i18n._(t`cannot change default advanced analysis`);
            if (chg_default.error?.data && !_.includes(chg_default.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={chg_default.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [chg_default]);

    useEffect(() => {
        if (chg_owner.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (chg_owner.isSuccess) {
            toast.success(i18n._(t`successful change owner of analysis`), toast_options);
        }
        if (chg_owner.isError) {
            let error = i18n._(t`cannot change owner of analysis`);
            if (chg_owner.error?.data && !_.includes(chg_owner.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={chg_owner.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [chg_owner]);

    const onChangeEnd = (date) => {
        if (_.isString(date) || !date.isValid()) {
            setChooseEnd({ end: date, err: true });
            setDisable(true);
        } else {
            setChooseEnd({ end: date, err: false });
            setDisable(false);
        }
    };

    const runAnalyse = () => {
        setEnd({ ...chooseEnd, end: chooseEnd.end.clone().add(1, "day") });
        setDisable(true);
    };

    const err_list = [
        sites.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        categories.isError,
        units.isError,
        equipments.isError,
        dataflows.isError,
        measurementtypes.isError,
        measurements.isError,
        advanced_analysis.isError
    ];

    const status_list = [
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        units.isSuccess,
        measurementtypes.isSuccess,
        equipments.isSuccess,
        dataflows.isSuccess,
        measurements.isSuccess,
        advanced_analysis.isSuccess
    ];

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader no-print">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>advanced analytics</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {(() => {
                    if (_.some(err_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else if (_.every(status_list)) {
                        const adv_analysis = advanced_analysis?.data ?? []; //For semantic ui
                        const owner_analysis = _.chain(adv_analysis).groupBy("owner_email").pick(auth.user.email).value();

                        const chain_wrapper = _.isEmpty(owner_analysis)
                            ? _.chain(adv_analysis) //Display first natural analysis based on request (need ordering ? by id ?)
                            : _.chain(owner_analysis).get(auth.user.email, []).orderBy("default_date", "desc"); //Display recent 'default_date' first is "owner"

                        const default_analysis_id = chain_wrapper.head().get("id").defaultTo(null).value();

                        const current_analysis = _.find(adv_analysis, { id: selectedAdvAnalysis || default_analysis_id });
                        const section_list = current_analysis?.analysissection_set ?? [];
                        const historic = current_analysis?.historic ?? historic_options[1].value;
                        const comparison = current_analysis?.comparison ?? comparison_options[0].value;
                        const time_periods = process_analysis_period(end.end, comparison, historic);
                        const disabled_analysis = auth.user.email !== current_analysis?.owner_email;

                        return (
                            <>
                                <Grid.Row className="no-print">
                                    <Grid.Column width={2} />
                                    <Grid.Column width={12} textAlign="center">
                                        <Menu compact>
                                            <AnalysisAdvancedChoice
                                                adv_analysis={adv_analysis}
                                                setSelectedAdvAnalysis={setSelectedAdvAnalysis}
                                                current_analysis={current_analysis}
                                                user_email={auth.user.email}
                                                id={id}
                                            />
                                            {notification.srv_status.db_status === "rw" && (
                                                <>
                                                    <Dropdown item icon="bars" simple>
                                                        <Dropdown.Menu>
                                                            {current_analysis && (
                                                                <>
                                                                    <Dropdown.Header>
                                                                        - <Trans>Sections</Trans> -
                                                                    </Dropdown.Header>
                                                                    <AnalysisSectionCreate
                                                                        current_advanced={current_analysis}
                                                                        org={org.current}
                                                                        disabled={disabled_analysis}
                                                                        setSectionAdded={setSectionAdded}
                                                                    />
                                                                </>
                                                            )}
                                                            <Dropdown.Header>
                                                                - <Trans>Analysis</Trans> -
                                                            </Dropdown.Header>
                                                            {/* Analysis advanced create */}
                                                            <AnalysisAdvancedCreate setSelectedAdvAnalysis={setSelectedAdvAnalysis} />
                                                            {current_analysis && (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={(e, data) => {
                                                                            if (!disabled_analysis)
                                                                                changeDefaultAnalysis({
                                                                                    org: org.current,
                                                                                    data: {
                                                                                        ..._.pick(current_analysis, ["id", "default_date"]),
                                                                                        default_date: moment().toISOString()
                                                                                    }
                                                                                });
                                                                        }}
                                                                        disabled={disabled_analysis}
                                                                    >
                                                                        <Icon name="mouse pointer" />
                                                                        <Trans>Set analysis default</Trans>
                                                                    </Dropdown.Item>

                                                                    <AnalysisAdvancedUpdate
                                                                        current_advanced={current_analysis}
                                                                        disabled={disabled_analysis}
                                                                    />
                                                                    {!disabled_analysis && (
                                                                        <AnalysisAdvancedDelete
                                                                            org={org.current}
                                                                            current_advanced={current_analysis}
                                                                            disabled={disabled_analysis}
                                                                            setSelectedAdvAnalysis={setSelectedAdvAnalysis}
                                                                        />
                                                                    )}
                                                                    {auth.rights?.is_admin && current_analysis?.owner !== auth.user.user_id && (
                                                                        <Dropdown.Item
                                                                            onClick={(e, data) => {
                                                                                if (
                                                                                    auth.rights?.is_admin &&
                                                                                    current_analysis?.owner !== auth.user.user_id
                                                                                )
                                                                                    changeOwnershipAnalysis({
                                                                                        org: org.current,
                                                                                        data: {
                                                                                            ..._.pick(current_analysis, ["id", "owner"]),
                                                                                            owner: auth.user?.user_id
                                                                                        }
                                                                                    });
                                                                            }}
                                                                            disabled={
                                                                                !(
                                                                                    auth.rights?.is_admin &&
                                                                                    current_analysis?.owner !== auth.user.user_id
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faHandHolding} className="icon" />
                                                                            <Trans>Take ownership of analysis</Trans>
                                                                        </Dropdown.Item>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )}
                                        </Menu>
                                    </Grid.Column>
                                    <Grid.Column width={2} />
                                </Grid.Row>
                                <Grid.Row className="no-print">
                                    <Grid.Column width={15}>
                                        <Divider />
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={7} computer={5} largeScreen={4} widescreen={3}>
                                        {/* DATEPICKER end */}
                                        <Datetime
                                            locale={current_lng}
                                            value={chooseEnd.end}
                                            timeFormat={false}
                                            onChange={onChangeEnd}
                                            renderInput={renderInput}
                                            inputProps={{
                                                name: <Trans>end period</Trans>,
                                                err: chooseEnd.err
                                            }}
                                            isValidDate={(current) => {
                                                return current.isBefore(now);
                                            }}
                                        />
                                        {/* END DATEPICKER end */}
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={2} computer={2} textAlign="center">
                                        <Button fluid disabled={disable_btn} secondary={!disable_btn} onClick={runAnalyse}>
                                            <Trans>toanalyze</Trans>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                {id !== undefined && current_analysis === undefined && (
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                attached={false}
                                                level="warning"
                                                message={i18n._(t`The analysis does not exist or is not accessible`)}
                                                isLoading={false}
                                                iconName="info circle"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {current_analysis && _.size(section_list) === 0 && (
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                attached={false}
                                                level="warning"
                                                message={i18n._(t`no analysis section`)}
                                                isLoading={false}
                                                iconName="info circle"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {current_analysis && _.size(section_list) > 0 && (
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            {_.map(section_list, (section, idx) => {
                                                return (
                                                    <AnalysisSection
                                                        key={idx}
                                                        idx={idx}
                                                        analysis_section_id={section}
                                                        org={org.current}
                                                        auth={auth}
                                                        current_analysis={current_analysis}
                                                        section_list={section_list}
                                                        historic={historic}
                                                        time_periods={time_periods}
                                                        categories_all={categories?.data ?? []}
                                                        sites_all={sites?.data ?? []}
                                                        zones_all={zones?.data ?? []}
                                                        usages_all={usages?.data ?? []}
                                                        tags_all={tags?.data ?? []}
                                                        units_all={units?.data ?? []}
                                                        measurements_all={measurements?.data ?? []}
                                                        notification={notification}
                                                        bottomRef={idx + 1 === _.size(section_list) ? bottomRef : null}
                                                        sectionAdded={sectionAdded}
                                                        setSectionAdded={setSectionAdded}
                                                    />
                                                );
                                            })}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </>
                        );
                    } else {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                })()}
            </Grid>
            <div ref={bottomRef} />
        </Segment>
    );
};

export default React.memo(AnalysisAdvanced);
