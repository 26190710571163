import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import cookie from "react-cookies";
import tinycolor from "tinycolor2";

import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import { defaultBearing } from "modules/predict/utils";
import { maintenanceactiontypes_description } from "./utils";

import Palette from "modules/common/components/graphic/Colors";

export const machineApi = defaultApi.injectEndpoints({
    reducerPath: "apiMachine",
    endpoints: (build) => ({
        //Retrive machines
        getMachines: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `machines?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Machines"]
        }),
        //Retrieve machine events
        getMachineEvents: build.query({
            query: ({ org, id_machine }) => {
                const current_org = _.get(org, "name", null);
                const machine_query = id_machine ? `&machine_id=${id_machine}` : "";
                return `machineevents?org=${current_org}${machine_query}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["MachineEvents"]
        }),
        //Retrieve single machine event
        getMachineEvent: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `machineevents/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: ["MachineEvent"]
        }),
        //Create machine even
        createMachineEvent: build.mutation({
            query: ({ org, data, user }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `machineevents?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: {
                        ...data,
                        owner: user.user_id
                    }
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                }
                return [];
            }
        }),
        kinematicChange: build.mutation({
            query: ({ org, machine, kinematic, event }) => {
                const current_org = _.get(org, "name", null);
                const is_predict = _.get(machine, "is_predict", true);
                const is_pump = _.includes([6, 7], _.get(machine, "machine_type"));

                //change machine data based on supply_form
                const update_machine = {
                    ...machine,
                    machine_supply_type: _.get(kinematic, "supply.machine_supply_type", machine.machine_supply_type),
                    machine_supply_wiring: _.get(kinematic, "supply.machine_supply_wiring", machine.machine_supply_wiring)
                };

                const bearings = _.chain(kinematic)
                    .get("motor_bearings", [])
                    .reduce((res, bearing, index) => {
                        const { by_order, dimension, order, db_info } = bearing;
                        if (db_info) {
                            res.push(bearing);
                        } else {
                            if (by_order) {
                                if (_.isEqual(order, _.get(defaultBearing, "order"))) {
                                    return res;
                                } else {
                                    res.push(bearing);
                                }
                            } else {
                                if (_.isEqual(dimension, _.get(defaultBearing, "dimension"))) {
                                    return res;
                                } else {
                                    res.push(bearing);
                                }
                            }
                        }
                        return res;
                    }, []);

                let update_kinematic = {
                    machine: _.omit(update_machine, ["motor"]),
                    supply: kinematic.supply,
                    motor: kinematic.motor
                };

                if (is_predict) {
                    update_kinematic = {
                        ...update_kinematic,
                        ..._.pick(kinematic, ["transmission", "additonnal_bearings"])
                    };
                    update_kinematic["motor_bearings"] = bearings;
                }
                if (is_pump) {
                    //Use to remap Qi,Hi,Pi values for backend (List: 3 elements {Qi,Hi,Pi} => 3 lists: Qi:[], Hi:[], Pi:[])
                    const pump_values_wrap = _.chain(kinematic).get("pump.values", []);
                    const wrap_info = _.chain(kinematic)
                        .get("pump.values", [])
                        .reduce((res, record) => {
                            res.push(record.Qi);
                            res.push(record.Hi);
                            res.push(record.Pi);
                            return res;
                        }, []);

                    const all_empty_info = wrap_info
                        .every((item) => {
                            return !_.isFinite(item);
                        })
                        .value();

                    if (all_empty_info) {
                        update_kinematic["pump"] = {
                            ..._.omit(kinematic.pump, ["values"])
                        };
                    } else {
                        update_kinematic["pump"] = {
                            ..._.omit(kinematic.pump, ["values"]),
                            Qi: pump_values_wrap.map((item) => _.get(item, "Qi", null)).value(),
                            Hi: pump_values_wrap.map((item) => _.get(item, "Hi", null)).value(),
                            Pi: pump_values_wrap.map((item) => _.get(item, "Pi", null)).value()
                        };
                    }
                }
                update_kinematic["is_confirmation"] = _.get(kinematic, "is_confirmation", false);
                if (event) {
                    update_kinematic["machine_event"] = _.omit(event, ["maintenanceaction_set"]);
                }

                return {
                    url: `/kinematic/change?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: { ...update_kinematic }
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (error === undefined) {
                    if (_.get(arg, "kinematic.is_confirmation", false)) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return ["MachineEvents", "Machines", "Components"];
                    }
                }
                return [];
            }
        }),
        //Delete Machine event
        deleteMachineEvent: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `machineevents/${data}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    localStorage.setItem("refresh_timestamp", moment().unix());
                    return ["MachineEvents"];
                }
                return [];
            }
        }),
        // Retrieve maintenance actions types
        getMaintenanceActionTypes: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `maintenanceactiontypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["MaintenanceActionTypes"],
            transformResponse: (response) => {
                return _.map(response, (maintenance_action) => {
                    const description = _.get(maintenanceactiontypes_description, maintenance_action.name, "");
                    return {
                        ...maintenance_action,
                        key: maintenance_action.id,
                        text: maintenance_action.name,
                        value: maintenance_action.id,
                        type: "maintenance_action",
                        description
                    };
                });
            }
        }),
        getAvailableMaintenanceActions: build.query({
            keepUnusedDataFor: 0,
            query: ({ org, id_machine }) => {
                const current_org = _.get(org, "name", null);
                return `machines/${id_machine}/availablemaintenanceactions?org=${current_org}&${refreshTimestampUrlParam()}`;
            }
        }),
        declareMaintenanceActions: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `machineevents/declarationmaintenanceactions?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (error === undefined) {
                    if (_.get(arg, "data.is_confirmation", false)) {
                        localStorage.setItem("refresh_timestamp", moment().unix());
                        return ["MachineEvents"];
                    }
                }
                return [];
            }
        }),
        getPumpMonitoring: build.query({
            keepUnusedDataFor: 0,
            query: ({ org, id_machine, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `machines/${id_machine}/pump_monitoring?org=${current_org}&start=${start}&end=${end}`;
            },
            skipCache: true,
            transformResponse: (response) => {
                const { available_energy, motor_loss, pump_loss, drive_loss, flow_head, bep_dist, bep_dist_bins } = response;
                const factor = 1;
                const color_pelec = tinycolor(Palette.circles[0]).setAlpha(0.4).toString();
                const color_phydro = tinycolor(Palette.circles[1]).setAlpha(0.4).toString();
                const color_head_flow = tinycolor(Palette.named.black).setAlpha(0.4).toString();
                const color_efficiency = tinycolor(Palette.named.green).setAlpha(0.4).toString();

                // Graphic detail p_act/p_hydro/efficiency
                const pElec = {
                    name: "p_act",
                    disabled: false,
                    strokeWidth: 20,
                    color: color_pelec,
                    unit: "kW",
                    isEvent: false,
                    data: []
                };

                const phydro = {
                    name: "p_hydro",
                    disabled: false,
                    strokeWidth: 20,
                    color: color_phydro,
                    unit: "kW",
                    isEvent: false,
                    data: []
                };

                const pefficiency = {
                    name: "efficiency",
                    disabled: false,
                    strokeWidth: 20,
                    color: color_efficiency,
                    unit: "%",
                    isEvent: false,
                    data: []
                };

                const max_y_pelec = _.maxBy(response?.elec_hydro, (record) => record[1])?.[1] ?? null;

                _.map(response?.elec_hydro, (record) => {
                    /*ELEC PART */
                    const elec_y_val = _.isFinite(record[1]) ? record[1] * factor : null;
                    pElec.data.push({
                        name: "p_act",
                        x: record[0] * 1000,
                        t: record[0] * 1000,
                        y: elec_y_val,
                        y_real: elec_y_val,
                        color: color_pelec,
                        unit: "kW"
                    });
                    /* HYDRO PART */
                    const hydro_y_val = _.isFinite(record[2]) ? record[2] * factor : null;
                    phydro.data.push({
                        name: "p_hydro",
                        x: record[0] * 1000,
                        t: record[0] * 1000,
                        y: hydro_y_val,
                        y_real: hydro_y_val,
                        color: color_phydro,
                        unit: "kW"
                    });
                    /* EFFICIENCY PART */
                    let efficency_y_real = null;
                    let efficency_y = null;
                    //Process effiency between 0 to 100
                    if (_.isFinite(record[1]) && _.isFinite(record[2]) && record[1] > 0) {
                        efficency_y_real = _.clamp(record[2] / record[1], 0, 1);
                    }
                    if (_.isFinite(max_y_pelec) && _.isFinite(efficency_y_real)) {
                        efficency_y = efficency_y_real * max_y_pelec;
                    }

                    pefficiency.data.push({
                        name: "efficency",
                        x: record[0] * 1000,
                        t: record[0] * 1000,
                        y: efficency_y,
                        y_real: _.isFinite(efficency_y_real) ? efficency_y_real * 100 : null,
                        color: color_efficiency,
                        unit: "%"
                    });
                });

                //Power loss radial graphic
                let power_loss_repartition = [];
                if (_.isFinite(available_energy)) {
                    const val = _.round(available_energy, 2);
                    //change available_energy name by hydraulic_energy
                    power_loss_repartition.push({
                        name: t`hydraulic_energy`,
                        angle: val,
                        percent: val,
                        color: tinycolor(Palette.circles[0]).setAlpha(0.4).toString()
                    });
                }
                if (_.isFinite(motor_loss)) {
                    const val = _.round(motor_loss, 2);
                    power_loss_repartition.push({
                        name: t`motor_loss`,
                        angle: val,
                        percent: val,
                        color: tinycolor(Palette.circles[1]).setAlpha(0.4).toString()
                    });
                }
                if (_.isFinite(pump_loss)) {
                    const val = _.round(pump_loss, 2);
                    power_loss_repartition.push({
                        name: t`pump_loss`,
                        angle: val,
                        percent: val,
                        color: tinycolor(Palette.circles[2]).setAlpha(0.4).toString()
                    });
                }
                if (_.isFinite(drive_loss)) {
                    const val = _.round(drive_loss, 2);
                    power_loss_repartition.push({
                        name: t`drive_loss`,
                        angle: val,
                        percent: val,
                        color: tinycolor(Palette.circles[3]).setAlpha(0.4).toString()
                    });
                }

                let head_flow = {
                    name: "head_flow",
                    disabled: false,
                    strokeWidth: 20,
                    data: _.chain(flow_head)
                        .reduce((res, value, idx) => {
                            const x_val = _.isFinite(value[1]) ? value[1] : null;
                            const y_val = _.isFinite(value[2]) ? value[2] : null;
                            res.push({
                                name: "head_flow",
                                x: x_val,
                                t: value[0] * 1000,
                                y: y_val,
                                y_real: y_val,
                                color: color_head_flow,
                                unit: "m",
                                unit_x: "m³/h"
                            });
                            return res;
                        }, [])
                        .value(),
                    color: color_head_flow,
                    unit: "m",
                    unit_x: "m³/h"
                };

                let histogram = null;
                const edges_len = _.size(bep_dist_bins);
                const weights_len = _.size(bep_dist);
                const bep_dist_sum = _.sum(bep_dist);
                if (weights_len > 0 && edges_len === weights_len + 1) {
                    histogram = {
                        name: "pump_histogram",
                        title: "pump_histogram",
                        disabled: false,
                        strokeWidth: 20,
                        color: color_pelec,
                        data: _.chain(response)
                            .get("bep_dist_bins", [])
                            .reduce((res, value, idx) => {
                                if (bep_dist[idx] !== undefined) {
                                    const y = _.round(Math.min(100, (bep_dist[idx] / bep_dist_sum) * 100), 3);
                                    res.push({
                                        b0: _.round(value, 3),
                                        b1: _.round(bep_dist_bins[idx + 1], 3),
                                        x: _.round(_.mean([value, bep_dist_bins[idx + 1]]), 3),
                                        y: _.isFinite(y) ? y : null
                                    });
                                }

                                return res;
                            }, [])
                            .value()
                    };
                }

                return {
                    detail: [pElec, phydro, pefficiency],
                    power_loss_repartition,
                    head_flow,
                    histogram,
                    ..._.omit(response, ["pelec", "phydro"]),
                    spot_ts: _.isFinite(response?.spot_ts) ? response.spot_ts * 1000 : null
                };
            }
        }),
        getBearings: build.mutation({
            query: ({ org, search }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `bearings`,
                    method: "GET",
                    params: {
                        search,
                        org: current_org
                    }
                };
            },
            transformResponse: (response) => {
                return _.map(response?.bearings, (bearing, idx) => {
                    //use index list of bearing because "id" of bearing not uniq
                    return {
                        ...bearing,
                        key: idx,
                        text: `${bearing.designation} - ${bearing.manufacturer}`,
                        value: idx
                    };
                });
            }
        }),
        getPumpBackground: build.query({
            query: ({ id_machine, org, theme }) => {
                const current_org = org?.name ?? null;
                const theme_param = theme ? `&theme=${theme}` : "";

                return {
                    url: `/machines/${id_machine}/pump_background?org=${current_org}&img=png${theme_param}&${refreshTimestampUrlParam()}`,
                    method: "GET",
                    responseHandler: (response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        return response.blob();
                    }
                };
            },
            transformResponse: (blob) => {
                // Convert blob to object URL
                const objectUrl = URL.createObjectURL(blob);
                return objectUrl;
            }
        })
    }),
    overrideExisting: false
});

export const {
    useGetMachinesQuery,
    useGetMachineEventsQuery,
    useGetMachineEventQuery,
    useDeleteMachineEventMutation,
    useCreateMachineEventMutation,
    useGetMaintenanceActionTypesQuery,
    useGetAvailableMaintenanceActionsQuery,
    useDeclareMaintenanceActionsMutation,
    useGetPumpMonitoringQuery,
    useKinematicChangeMutation,
    useGetBearingsMutation,
    useGetPumpBackgroundQuery
} = machineApi;
