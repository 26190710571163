import React, { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Segment, Checkbox, Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteImportedDataSetMutation } from "../importedDataSetsService";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeleteImportedDataSet = (props) => {
    const { item, setOpen } = props;

    const [confirm, setConfirm] = useState(false);

    const { org } = useSelector((state) => state);

    const [deleteImportedDataSet, deleted] = useDeleteImportedDataSetMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete dataset`), toast_options);
            setOpen(false);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete dataset`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted, setOpen]);

    const handleDelete = async (e) => {
        await deleteImportedDataSet({ org: org.current, id: item.id });
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => {
                setOpen(false);
            }}
            open={true}
        >
            <Modal.Header>
                <Trans>want delete dataset {item.name}</Trans>
            </Modal.Header>
            <Modal.Content>
                <Segment basic>
                    <Grid>
                        <Grid.Column style={{ color: "#794b02", fontStyle: "italic" }} verticalAlign="bottom" width={16}>
                            <Trans>Deleting this dataset may lead to the deletion of the imported data that is linked to it.</Trans>
                        </Grid.Column>
                        <Grid.Column style={{ marginTop: ".5rem" }} width={16}>
                            <Checkbox
                                label={
                                    <label>
                                        <Trans>yes, i confirm deletion</Trans>
                                    </label>
                                }
                                onChange={(e, data) => setConfirm(data.checked)}
                                checked={confirm}
                            />
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>

                <Button disabled={!confirm} labelPosition="right" icon positive onClick={handleDelete}>
                    <Icon name="check" />
                    <Trans>validate</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteImportedDataSet;
