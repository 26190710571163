import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import moment from "moment";
import "moment/min/locales.min.js";
import { Card, Button, Popup, Label, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";

/**
 * Get correct image based on time interval between now and last_tmst of dataflow
 * @function renderDispoIcon
 * @param {string} last_tmst_str - datetime string represention
 */
const renderDispo = (last_tmst_str, sensor_type) => {
    if (!last_tmst_str) {
        return { icon: null, color: "grey" };
    }
    const now = moment();
    const last_tmst = moment(last_tmst_str);

    if (!last_tmst.isValid()) {
        return { icon: null, color: "grey" };
    }

    const low_level = sensor_type === "imp" ? 24 : 3;
    const mid_level = sensor_type === "imp" ? 48 : 24;

    const renderLabel = (color) => {
        return {
            icon: <div className={`pwaPuce ${color}`}></div>,
            color: color
        };
    };

    if (now.diff(last_tmst, "hours") <= low_level) {
        return renderLabel("green");
    } else if (now.diff(last_tmst, "hours") <= mid_level) {
        return renderLabel("orange");
    } else {
        return renderLabel("red");
    }
};

export const DispoInfo = React.memo((props) => {
    const { display, equipment, lastActivity } = props;
    const current_lng = useSelector((state) => state.i18n.current);

    const renderLabels = useMemo(() => {
        const renderLabels = [];
        if (!lastActivity?.isFetching && lastActivity?.isSuccess) {
            const sensors = lastActivity.data?.[equipment.id]?.sensors ?? [];
            _.each(sensors, (sensor) => {
                //for each sensor, we define icon type in switch/case. We push "name" of Icon
                switch (sensor?.type) {
                    case "imp":
                        renderLabels.push({ name: "file alternate outline", icon: true });
                        break;
                    case "syn":
                        renderLabels.push({ name: "code branch", icon: true });
                        break;
                    case "none":
                        renderLabels.push({ name: "unlink", icon: true });
                        break;
                    default:
                        break;
                }
                if (sensor?.identifier && sensor?.last_tmst !== undefined) {
                    //for sensor with identifier and last_tmst defined (null or ISO8601) with push 'Label' options
                    renderLabels.push({ color: renderDispo(sensor.last_tmst).color, content: sensor.identifier });
                }
            });
        }
        return _.chain(renderLabels)
            .uniq() //Use uniq to prevent duplication of Label icon type
            .sortBy((element) => !element.icon) // Put element with element.icon = true first
            .map((item, idx) => {
                if (item.icon === true) {
                    return (
                        <Label key={idx} basic>
                            <Icon name={item.name} style={{ margin: 0 }} />
                        </Label>
                    );
                } else {
                    return <Label key={idx} color={item.color} content={item.content} />;
                }
            })
            .value();
    }, [lastActivity, equipment.id]);

    if (lastActivity?.isFetching) {
        return (
            <>
                {_.isEqual(display, "card") && (
                    <MessageDisplay message={i18n._(t`update`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                )}
                {_.isEqual(display, "button") && (
                    <Popup content={i18n._(t`update`)} trigger={<Button compact basic icon="circle notched" loading />} />
                )}
            </>
        );
    }

    if (lastActivity?.isError) {
        return (
            <>
                {_.isEqual(display, "card") && (
                    <MessageDisplay message={i18n._(t`unavailable`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />
                )}
                {_.isEqual(display, "button") && (
                    <Popup content={i18n._(t`unavailable`)} trigger={<Button compact basic icon="warning" color="red" />} />
                )}
            </>
        );
    }

    if (!lastActivity?.isFetching && lastActivity?.isSuccess) {
        const sensors = lastActivity.data?.[equipment.id]?.sensors ?? [];

        let renderIcon = null;
        let renderHeader = null;
        let renderSensors = null;

        if (_.size(sensors) === 0) {
            renderHeader = (
                <Card.Header textAlign="left">
                    <Trans>no data on server</Trans>
                </Card.Header>
            );
        }

        //Most recent sensor with a last_tmst string
        const recent_sensor = _.chain(sensors)
            .filter((item) => !_.includes([undefined, null], item.last_tmst))
            .orderBy(["last_tmst"], ["desc"])
            .head()
            .defaultTo(null)
            .value();

        const display_icon = renderDispo(recent_sensor?.last_tmst, recent_sensor?.type);

        renderSensors = (
            <Card.Content extra textAlign="right">
                <Label.Group size={"small"}>{renderLabels}</Label.Group>
            </Card.Content>
        );

        renderIcon = display_icon?.icon !== null && display_icon.icon;
        renderHeader = (
            <>
                {_.every(sensors, { type: "syn" }) && (
                    <Card.Header textAlign="left">
                        <Trans>calculated measure</Trans>
                    </Card.Header>
                )}
                {_.every(sensors, { type: "none" }) && (
                    <>
                        <Card.Header textAlign="left">
                            {_.includes([undefined, null], recent_sensor?.last_tmst) && <Trans>no data on server</Trans>}
                            {!_.includes([undefined, null], recent_sensor?.last_tmst) &&
                                moment(recent_sensor.last_tmst).locale(current_lng).fromNow()}
                        </Card.Header>
                        {!_.includes([undefined, null], recent_sensor?.last_tmst) && (
                            <Card.Content extra>{moment(recent_sensor.last_tmst).locale(current_lng).format("LLL")}</Card.Content>
                        )}
                    </>
                )}
                {!_.every(sensors, { type: "syn" }) && !_.every(sensors, { type: "none" }) && (
                    <>
                        <Card.Header textAlign="left">
                            {!_.includes([undefined, null], recent_sensor?.last_tmst) &&
                                moment(recent_sensor.last_tmst).locale(current_lng).fromNow()}
                            {_.includes([undefined, null], recent_sensor?.last_tmst) && <Trans>no data on server</Trans>}
                            {!_.includes([undefined, null], recent_sensor?.last_tmst) && _.isEqual(display, "card") && (
                                <Popup position="top center" trigger={<Icon name="question circle" color="grey" size="small" />}>
                                    <Popup.Content>
                                        {_.every(sensors, { type: "imp" }) && <Trans>Last date from imp</Trans>}
                                        {!_.every(sensors, { type: "imp" }) && <Trans>Last value from sensor</Trans>}
                                    </Popup.Content>
                                </Popup>
                            )}
                        </Card.Header>
                        {!_.includes([undefined, null], recent_sensor?.last_tmst) && (
                            <Card.Content extra>{moment(recent_sensor.last_tmst).locale(current_lng).format("LLL")}</Card.Content>
                        )}
                    </>
                )}
            </>
        );

        if (display === "card") {
            return (
                <Card fluid className="pwaDispoInfoCard">
                    <Card.Content>
                        {renderIcon}
                        {renderHeader}
                    </Card.Content>
                    {renderSensors}
                </Card>
            );
        } else if (display === "button") {
            return (
                <Popup trigger={renderIcon} position="bottom right" className="pwaDispoInfoPopup">
                    <Popup.Content>
                        <Card fluid className="pwaDispoInfoCard">
                            <Card.Content>{renderHeader}</Card.Content>
                            {renderSensors}
                        </Card>
                    </Popup.Content>
                </Popup>
            );
        } else {
            return null;
        }
    }
    return null;
});

DispoInfo.defaultProps = {
    display: "card"
};

DispoInfo.propTypes = {
    display: PropTypes.string.isRequired,
    equipment: PropTypes.object.isRequired
};

export default DispoInfo;
