import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { Field, Form } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import { Button, Grid, Segment, Icon, Modal, Divider, Checkbox } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { deleteExclusionDetach, main_default_equipment, modifiedExclusionDetach, transferTypeOptions } from "modules/equipment/utils";
import { identity } from "modules/common/utils/form";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDetachDataflowMutation, useGetEquipmentsQuery } from "modules/equipment/equipmentService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import EquipmentMainFields from "modules/equipment/components/form/EquipmentMainFields";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";
import history_app from "history_app";

const DetachForm = (props) => {
    const { measurement = null, setOpen, equipment } = props;
    const [confirm, setConfirm] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });

    const [detachDataflow, detach] = useDetachDataflowMutation();
    const [waitingDetachDataflow, wDetach] = useDetachDataflowMutation();

    useEffect(() => {
        if (detach.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (detach.isSuccess) {
            toast.success(i18n._(t`successful detach measurement`), toast_options);
            history_app.push(`/equipments/${detach.data?.id}#measurements`);
            setOpen && setOpen(null);
        }
        if (detach.isError) {
            let error = i18n._(t`cannot detach measurement`);
            if (detach?.error?.data && !_.includes(detach.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={detach?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [setOpen, detach]);

    useEffect(() => {
        if (wDetach.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (wDetach.isSuccess) {
            setOpenModalConfirm(true);
        }
        if (wDetach.isError) {
            let error = i18n._(t`cannot detach measurement`);
            if (wDetach?.error?.data && !_.includes(wDetach.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={wDetach?.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [wDetach]);

    const { delete_objects, modified_objects } = useMemo(() => {
        if (wDetach.isSuccess) {
            const delete_objects = _.chain(wDetach.data)
                .get("objects", {})
                .reduce((res, item, key) => {
                    if (!_.includes(deleteExclusionDetach, key)) {
                        res.push(
                            <tr key={key}>
                                <td>{i18n._(key)}:</td>
                                <td>
                                    <strong>{item}</strong>
                                </td>
                            </tr>
                        );
                    }
                    return res;
                }, [])
                .value();
            const modified_objects = _.chain(wDetach.data)
                .get("modified", {})
                .reduce((res, item, key) => {
                    if (!_.includes(modifiedExclusionDetach, key)) {
                        res.push(
                            <tr key={key}>
                                <td>{i18n._(key)}:</td>
                                <td>
                                    <strong>{item}</strong>
                                </td>
                            </tr>
                        );
                    }
                    return res;
                }, [])
                .value();
            return { delete_objects, modified_objects };
        }
        return { delete_objects: [], modified_objects: [] };
    }, [wDetach]);

    const equipmentsOptions = useMemo(() => {
        if (equipments.isSuccess) {
            return _.chain(equipments.data)
                .reduce((res, { key, text, value, site }) => {
                    if (value === equipment.id) return res;
                    if (site === equipment.site) {
                        res.push({
                            key,
                            value,
                            text: i18n._(text)
                        });
                    }
                    return res;
                }, [])
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [equipments, current_lng, equipment]);

    const initialValues = useMemo(() => {
        return {
            measurement,
            transfer_type: 1,
            equipment: { ...main_default_equipment, site: equipment?.site ?? null },
            equipment_id: null,
            dataflow: {
                name: measurement?.name ?? "-"
            }
        };
    }, [measurement, equipment]);

    const submitForm = (formData) => {
        switch (formData?.transfer_type) {
            case 1: {
                const data = {
                    equipment: formData?.equipment ?? null
                };

                waitingDetachDataflow({ org: org.current, dataflow_id: measurement.dataflow, transfer_type_str: "to_new", data });
                break;
            }
            case 2: {
                const data = {
                    destination: formData?.equipment_id,
                    name: formData?.dataflow?.name ?? null
                };
                waitingDetachDataflow({ org: org.current, dataflow_id: measurement.dataflow, transfer_type_str: "to_existing", data });
                break;
            }
            default:
                break;
        }
    };

    return (
        <>
            <Form
                onSubmit={submitForm}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Segment attached={"top"}>
                                <Grid centered verticalAlign="top">
                                    <Grid.Column width={16}>
                                        <Field
                                            name="transfer_type"
                                            placeholder={i18n._(t`select a type of transfer`)}
                                            noResultsMessage={i18n._(t`no result found`)}
                                            options={transferTypeOptions}
                                            isRequired={true}
                                            component={DropDownAdapter}
                                        />
                                    </Grid.Column>
                                </Grid>
                                {values.transfer_type === 1 && (
                                    <EquipmentMainFields form={form} values={values} can_change={true} site_disabled={true} />
                                )}
                                {values.transfer_type === 2 && (
                                    <Grid verticalAlign="top">
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment_id"
                                                label={i18n._(t`Equipment`)}
                                                placeholder={i18n._(t`Choose an equipment`)}
                                                options={equipmentsOptions}
                                                isRequired={true}
                                                component={DropDownAdapter}
                                                noResultsMessage={i18n._(t`no result found`)}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="dataflow.name"
                                                placeholder={i18n._(t`enter name of dataflow`)}
                                                label={i18n._(t`name`)}
                                                parse={identity}
                                                component={InputAdapter}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                )}
                            </Segment>
                            <Segment attached basic textAlign="right">
                                <Button type="button" negative icon labelPosition="left" onClick={(e) => setOpen(null)}>
                                    <Icon name="arrow left" />
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button positive type="submit" disabled={submitting || pristine || invalid}>
                                    <Trans>Confirm</Trans>
                                </Button>
                            </Segment>
                        </form>
                    );
                }}
            />
            {openModalConfirm && (
                <Modal
                    centered={false}
                    closeOnDimmerClick={false}
                    onClose={() => {
                        setOpenModalConfirm(false);
                        setConfirm(false);
                    }}
                    open
                >
                    <Modal.Header>
                        <Trans>want detach measurement {measurement?.name ?? ""}</Trans>
                    </Modal.Header>
                    <Modal.Content>
                        {wDetach.isError && (
                            <MessageDisplay
                                message={
                                    <>
                                        {/* check 404 not found to prevent incorrect message display */}
                                        <Trans>You can't detach this measurement.</Trans>
                                        <Divider />
                                        {wDetach.error?.originalStatus !== 404 && wDetach?.error?.data !== undefined && (
                                            <RequestErrorRender errors={wDetach?.error?.data} />
                                        )}
                                    </>
                                }
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                            />
                        )}
                        {wDetach.isFetching && (
                            <MessageDisplay message={i18n._(t`send request to server`)} level="info" iconName="info circle" isLoading={false} />
                        )}
                        {wDetach.isSuccess && (
                            <>
                                <Segment basic>
                                    <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                        <Grid centered>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Trans>
                                                        detach this measurement leads to the deletion of several elements related to this measurement
                                                        and all data <strong>will be erased</strong>
                                                    </Trans>
                                                </Grid.Column>
                                            </Grid.Row>
                                            {!_.isEmpty(modified_objects) && (
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Trans>Related data that will be modified:</Trans>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <table>
                                                            <tbody>{modified_objects}</tbody>
                                                        </table>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            {!_.isEmpty(delete_objects) && (
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Trans>Related data that will be deleted:</Trans>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <table>
                                                            <tbody>{delete_objects}</tbody>
                                                        </table>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                        </Grid>
                                    </div>
                                </Segment>
                                <Segment basic>
                                    <Checkbox
                                        label={
                                            <label>
                                                <Trans>yes, i confirm detach</Trans>
                                            </label>
                                        }
                                        onChange={(e, data) => setConfirm(data.checked)}
                                        checked={confirm}
                                    />
                                </Segment>
                            </>
                        )}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            onClick={() => {
                                setOpenModalConfirm(false);
                                setConfirm(false);
                            }}
                        >
                            <Trans>cancel</Trans>
                        </Button>
                        {wDetach.data?.objects && (
                            <Button
                                labelPosition="right"
                                icon
                                positive
                                onClick={(e) => {
                                    detachDataflow({ ...wDetach.originalArgs, confirm: true });
                                }}
                                disabled={!confirm}
                            >
                                <Icon name="check" />
                                <Trans>validate</Trans>
                            </Button>
                        )}
                    </Modal.Actions>
                </Modal>
            )}
        </>
    );
};

export default DetachForm;
