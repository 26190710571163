import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Field, Form } from "react-final-form";
import { Button, Modal, Grid, Popup, Segment, Label, Header, Table } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";

import googleplay from "images/googleplay.png";
import appstore from "images/appstore.png";
import * as extern from "extern";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import i18n from "modules/i18n/i18nConfig";
import { useOtpGenerateQuery, useOtpQrCodeQuery, useOtpSetMutation } from "../userService";
import { identity } from "modules/common/utils/form";

import { InputAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const OtpUser = (props) => {
    const { item } = props;
    const [open, setOpen] = useState(false);
    const { org } = useSelector((state) => state);

    const otpGenerate = useOtpGenerateQuery({ org: org.current, user_id: item.id }, { skip: item.has_otp || open === false });
    const otpQrCode = useOtpQrCodeQuery(
        { org: org.current, user_id: item.id, secret: otpGenerate.data?.secret },
        { skip: item.has_otp || open === false || otpGenerate.isSuccess !== true }
    );

    const [setupOtp, otp] = useOtpSetMutation();

    useEffect(() => {
        if (otp.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (otp.isSuccess) {
            toast.success(i18n._(t`successful set otp`), toast_options);
            setOpen(false);
        }
        if (otp.isError) {
            let error = i18n._(t`cannot set otp`);
            if (otp.error?.data && !_.includes(otp.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={otp.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [otp]);

    const submitForm = async (formData) => {
        setupOtp({ org: org.current, user_id: item.id, data: formData, from_users: true });
    };

    const initialValues = {
        token: "",
        secret: otpGenerate.data?.secret
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            centered={false}
            trigger={
                <Popup
                    trigger={
                        <Button type="button" icon onClick={(e) => setOpen(true)}>
                            <FontAwesomeIcon icon={faMobileScreen} className="icon" />
                        </Button>
                    }
                >
                    <Popup.Content>
                        <Trans>Set otp</Trans>
                    </Popup.Content>
                </Popup>
            }
        >
            <Form
                onSubmit={submitForm}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, invalid }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>Two-Factor authentication (2FA)</Trans>
                            </Modal.Header>
                            <Modal.Content>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid centered verticalAlign="top">
                                        {otpQrCode.isSuccess && (
                                            <>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Segment padded attached="top">
                                                            <Label attached="top left" color="blue">
                                                                <Trans>Scan QrCode</Trans>
                                                            </Label>
                                                            <Header as="h3">
                                                                <Trans>Install one of the following applications on your mobile</Trans>
                                                            </Header>
                                                            <Table definition compact>
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.Cell>
                                                                            <Trans>Microsoft Authenticator</Trans>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <a
                                                                                href={extern.microsoftAuthGooglePlay}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <img src={googleplay} alt="" />
                                                                            </a>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <a
                                                                                href={extern.microsoftAuthAppStore}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <img src={appstore} alt="" />
                                                                            </a>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>
                                                                            <Trans>Google Authenticator</Trans>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <a
                                                                                href={extern.googleAuthGoogleplay}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <img src={googleplay} alt="" />
                                                                            </a>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <a
                                                                                href={extern.googleAuthAppStore}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <img src={appstore} alt="" />
                                                                            </a>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>
                                                            <Header as="h3">
                                                                <Trans>Open the application and scan the barcode</Trans>
                                                            </Header>
                                                            <Segment basic textAlign="center">
                                                                <img src={otpQrCode.data} alt="QR Code" />
                                                            </Segment>
                                                        </Segment>
                                                        <Segment padded attached>
                                                            <Label attached="top left" color="blue">
                                                                <Trans>Or enter code in your app</Trans>
                                                            </Label>
                                                            <Segment basic textAlign="center">
                                                                <Trans>Secret</Trans>: {otpGenerate.data.secret}
                                                            </Segment>
                                                        </Segment>
                                                        <Segment padded attached>
                                                            <Label attached="top left" color="blue">
                                                                <Trans>Verify Code</Trans>
                                                            </Label>
                                                            <Segment basic>
                                                                <p>
                                                                    <Trans>Please set the authentication code</Trans>
                                                                </p>
                                                                <Segment basic textAlign="center">
                                                                    <Field
                                                                        name="token"
                                                                        placeholder={i18n._(t`Authentication code`)}
                                                                        onKeyDown={(e) => e.keyCode === 69 && e.preventDefault()}
                                                                        component={InputAdapter}
                                                                        parse={identity}
                                                                        isRequired={true}
                                                                        validate={(value) => {
                                                                            if (!/^[0-9]{6}$/.test(value)) {
                                                                                return <Trans>invalid token</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Segment>
                                                            </Segment>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="button"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

OtpUser.propTypes = {
    item: PropTypes.object.isRequired
};

export default OtpUser;
