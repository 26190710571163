import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Grid, Header } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { dateRangeOptions, getTimedelta } from "modules/time/utils";
import { setTime } from "modules/overview/overviewSlice";
import { useGetConsumptionQuery, useGetDetailQuery, useGetSummaryQuery, useGetTotalConsumptionQuery } from "modules/overview/overviewService";
import { useGetStandbyThresholdQuery } from "modules/measurement/measurementService";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicDetail from "../graphic/GraphicDetail";
import GraphicSummary from "../graphic/GraphicSummary";
import EnergyHeaderText from "../EnergyHeaderText";
import GraphicConsumption from "../graphic/GraphicConsumption";

const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));

const RenderGeneric = (props) => {
    const { org, measurement = null, rangeTime, equipment } = props;

    const detail = useGetDetailQuery(
        { org: org.current, measurement, start: rangeTime?.start?.toISOString(), end: rangeTime?.end?.toISOString() },
        { skip: !org.current || measurement === null }
    );

    const datapoint_type = measurement?.measurementtype?.datapoint_type ?? null;

    //Call total_consumption only on datapoint_type = 3 (see skip param)
    const total_consumption = useGetTotalConsumptionQuery(
        { org: org.current, measurement, start: rangeTime?.start?.toISOString(), end: rangeTime?.end?.toISOString() },
        { skip: !org.current || measurement === null || datapoint_type !== 3 }
    );

    //Call standbyThreshold only if threshold defined and datapoint_type = 3
    const standby_threshold = measurement?.full_df?.standby_threshold ?? null;
    const standby_threshold_query = useGetStandbyThresholdQuery(
        { org: org.current, measure: measurement, start: rangeTime?.start?.toISOString(), end: rangeTime?.end?.toISOString() },
        { skip: !org.current || measurement === null || datapoint_type !== 3 || !_.isFinite(standby_threshold) }
    );

    const new_range_time = getTimedelta(rangeTime?.start.toISOString(), rangeTime?.end?.toISOString());
    const summary = useGetSummaryQuery(
        { org: org.current, measurement, start: new_range_time?.start?.format("YYYY-MM-DD"), end: new_range_time?.end?.format("YYYY-MM-DD") },
        { skip: !org.current || measurement === null }
    );
    const consumption = useGetConsumptionQuery(
        { org: org.current, measurement, start: new_range_time?.start?.format("YYYY-MM-DD"), end: new_range_time?.end?.format("YYYY-MM-DD") },
        { skip: !org.current || measurement === null || datapoint_type !== 3 }
    );

    return (
        <>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    <Header as="h3" block textAlign="center" attached="top">
                        {datapoint_type !== 3 && <Trans>detail</Trans>}
                        {datapoint_type === 3 && (
                            <>
                                {(total_consumption.isFetching || standby_threshold_query.isFetching) && <Trans>detail</Trans>}
                                {total_consumption.isSuccess && (
                                    <EnergyHeaderText
                                        total_consumption={total_consumption}
                                        standby_threshold={standby_threshold_query.data}
                                        measure={measurement}
                                    />
                                )}
                            </>
                        )}
                    </Header>
                    {detail.isFetching && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {detail.isError && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {detail.isSuccess && !detail.isFetching && (
                        <GraphicDetail
                            time={rangeTime}
                            data={detail.data}
                            csvName={equipment?.name ?? "export"}
                            threshold={_.isFinite(standby_threshold) ? standby_threshold : undefined}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                {datapoint_type === 3 && (
                    <Grid.Column mobile={16} tablet={7} computer={7} textAlign="center" className="pwaGraph">
                        <Header as="h3" block textAlign="center" attached="top">
                            <Trans>consumptions historic</Trans>
                        </Header>
                        {measurement === null && (
                            <MessageDisplay message={i18n._(t`unknown measurement`)} level="warning" iconName="warning circle" isLoading={false} />
                        )}
                        {consumption.isFetching && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {consumption.isError && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {consumption.isSuccess && !consumption.isFetching && (
                            <GraphicConsumption
                                time={rangeTime}
                                consumption={consumption}
                                csvName={equipment?.name ?? "export"}
                                measure={measurement}
                            />
                        )}
                    </Grid.Column>
                )}
                <Grid.Column
                    mobile={16}
                    tablet={datapoint_type === 3 ? 7 : 14}
                    computer={datapoint_type === 3 ? 7 : 14}
                    textAlign="center"
                    className="pwaGraph"
                >
                    <Header as="h3" block textAlign="center" attached="top">
                        <Trans>long term trend</Trans>
                    </Header>
                    {summary.isFetching && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {summary.isError && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {summary.isSuccess && !summary.isFetching && (
                        <GraphicSummary time={rangeTime} data={summary.data} csvName={equipment?.name ?? "export"} />
                    )}
                </Grid.Column>
            </Grid.Row>
        </>
    );
};

const TabGeneric = (props) => {
    const { default_time, measurements = [], equipment } = props;
    const dispatch = useDispatch();
    const { org } = useSelector((state) => state);
    const { powertime } = useSelector((state) => state.overview);

    const rangeTime = powertime.time
        ? {
              start: moment(powertime.time.start),
              end: moment(powertime.time.end),
              plage: powertime.time.plage
          }
        : default_time;

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "powertime" }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            {_.map(measurements, (measurement, idx) => {
                return <RenderGeneric key={idx} org={org} measurement={measurement} rangeTime={rangeTime} equipment={equipment} />;
            })}
        </Grid>
    );
};

export default TabGeneric;
