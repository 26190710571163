import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field } from "react-final-form";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetEquipmenttypesQuery } from "modules/equipment/equipmenttypeService";
import { useGetMachinetypesQuery } from "modules/machine/machinetypeService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import TagAddModalForm from "modules/tag/components/TagAddModalForm";

/* Extract Equipment fields for generic usage in :
    - main form of equipment update
    - add calculated measurement
    - detach dataflow for new equipment
*/
const EquipmentMainFields = (props) => {
    const { form, values, can_change, site_disabled } = props;
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const equipmenttypes = useGetEquipmenttypesQuery({ org: org.current }, { skip: !org.current });
    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const has_elec = useMemo(() => {
        if (!_.isFinite(values?.equipment?.id)) {
            return false;
        }
        if (dataflows.isSuccess && measurements.isSuccess) {
            return (
                _.chain(measurements.data)
                    .reduce((res, measurement) => {
                        const df = _.find(dataflows.data, { id: measurement?.dataflow });
                        if (
                            df === undefined ||
                            df?.equipment !== values?.equipment?.id ||
                            !_.includes([9, 48], measurement?.measurementtype_tech) ||
                            df?.syndataflow !== null
                        ) {
                            // 9: p_act_impot|48: e_act_counter
                            return res;
                        }
                        res.push(measurement);
                        return res;
                    }, [])
                    .size()
                    .value() > 0
            );
        }
        return false;
    }, [values, dataflows, measurements]);

    const eqtTypesOptions = useMemo(() => {
        if (equipmenttypes.isSuccess) {
            return _.chain(equipmenttypes.data)
                .map(({ key, text, value }) => ({
                    key,
                    value,
                    text: i18n._(text)
                }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [equipmenttypes, current_lng]);

    const machineTypesOptions = useMemo(() => {
        if (machinetypes.isSuccess) {
            return _.chain(machinetypes.data)
                .map(({ key, text, value }) => ({
                    key,
                    value,
                    text: i18n._(text)
                }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
        // eslint-disable-next-line
    }, [machinetypes, current_lng]);

    const siteOptions = useMemo(() => {
        if (sites.isSuccess) {
            return _.chain(sites.data)
                .map(({ key, text, value }) => ({ key, text, value }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [sites]);
    const usageOptions = useMemo(() => {
        if (usages.isSuccess) {
            return _.chain(usages.data)
                .map(({ key, text, value }) => ({ key, text, value }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [usages]);

    const retrieveZoneOptionsFromSite = useCallback(
        (site_id) => {
            if (zones.isSuccess && site_id) {
                return _.chain(zones.data)
                    .filter((zone) => {
                        return zone.site_id === site_id;
                    })
                    .map(({ key, text, value }) => ({
                        key,
                        value,
                        text: i18n._(text)
                    }))
                    .orderBy((item) => {
                        return removeAccents(item.text).toLowerCase();
                    }, "asc")
                    .value();
            }
            return [];
        },
        [zones]
    );

    const tagOptions = useMemo(() => {
        if (tags.isSuccess) {
            return _.chain(tags.data)
                .map(({ key, text, value }) => ({ key, text, value }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [tags]);

    return (
        <Grid verticalAlign="top">
            <Grid.Column width={16}>
                <Field
                    name="equipment.name"
                    placeholder={i18n._(t`enter name of equipment`)}
                    label={i18n._(t`name`)}
                    isRequired={true}
                    defaultValue=""
                    component={InputAdapter}
                    validate={(value) => (!value ? <Trans>name is required</Trans> : undefined)}
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <Field
                    name="equipment.equipment_type"
                    label={i18n._(t`equipment_type`)}
                    placeholder={i18n._(t`select equipment_type of equipment`)}
                    options={eqtTypesOptions}
                    isRequired={true}
                    component={DropDownAdapter}
                    //PE6 case: check if we let disable or not ?
                    //disabled={id_eqpmt !== undefined && props?.equipment.machine !== null && has_pe6 === false}
                    validate={(value) => (!value ? <Trans>equipment type is required</Trans> : undefined)}
                />
            </Grid.Column>
            {/*Equipment_type === 7 means rotating machine */}
            {(values?.equipment?.machine !== null || (has_elec && values?.equipment?.equipment_type === 7)) && (
                <>
                    <Grid.Column width={16}>
                        <Field
                            name="equipment.machine.machine_type"
                            label={i18n._(t`machine_type`)}
                            placeholder={i18n._(t`select machine_type of equipment`)}
                            options={machineTypesOptions}
                            isRequired={true}
                            component={DropDownAdapter}
                            validate={(value) => (!value ? <Trans>machine type is required</Trans> : undefined)}
                        />
                    </Grid.Column>
                </>
            )}
            <Grid.Column width={16}>
                <Field
                    name="equipment.site"
                    label={i18n._(t`site`)}
                    options={siteOptions}
                    placeholder={i18n._(t`select site of equipment`)}
                    isRequired={true}
                    disabled={_.isFinite(values?.equipment?.id) || site_disabled === true}
                    component={DropDownAdapter}
                    validate={(value) => {
                        if (!_.isFinite(value)) {
                            return <Trans>site is required</Trans>;
                        } else if (!_.includes(auth.rights?.sites_rw, value)) {
                            return <Trans>insufficient site rights</Trans>;
                        } else {
                            return undefined;
                        }
                    }}
                    customAction={(data) => {
                        //reset zone field
                        form.change("equipment.zone", null);
                    }}
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <Field
                    name="equipment.zone"
                    label={i18n._(t`zone`)}
                    placeholder={i18n._(t`select zone of equipment`)}
                    options={retrieveZoneOptionsFromSite(values.equipment.site)}
                    isRequired={true}
                    disabled={!values?.equipment?.site}
                    component={DropDownAdapter}
                    validate={(value) => (!value ? <Trans>zone is required</Trans> : undefined)}
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <Field
                    name="equipment.usage"
                    label={i18n._(t`usage`)}
                    placeholder={i18n._(t`select usage of equipment`)}
                    options={usageOptions}
                    isRequired={true}
                    component={DropDownAdapter}
                    validate={(value) => (!value ? <Trans>usage is required</Trans> : undefined)}
                />
            </Grid.Column>
            <Grid.Column width={can_change ? 15 : 16}>
                <Grid verticalAlign="bottom">
                    <Grid.Row>
                        <Grid.Column mobile={can_change ? 14 : 16} tablet={can_change ? 14 : 16} computer={can_change ? 15 : 16}>
                            <Field
                                name="equipment.tag_set"
                                label={i18n._(t`tags`)}
                                placeholder={i18n._(t`choose tags`)}
                                options={tagOptions}
                                multipleselect={1}
                                component={DropDownAdapter}
                                noResultsMessage={i18n._(t`no tags found`)}
                            />
                        </Grid.Column>
                        {notification.srv_status.db_status === "rw" && can_change && (
                            <Grid.Column width={1}>
                                <TagAddModalForm form={form} />
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
            </Grid.Column>
            <Grid.Column width={16}>
                <Field
                    name="equipment.external_id"
                    placeholder={i18n._(t`enter external_id of equipment`)}
                    label={i18n._(t`external_id`)}
                    isRequired={false}
                    defaultValue={null}
                    component={InputAdapter}
                    helperText={`${i18n._(t`Custom identifier used in external APIs`)}`}
                />
            </Grid.Column>
        </Grid>
    );
};

EquipmentMainFields.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    can_change: PropTypes.bool.isRequired,
    site_disabled: PropTypes.bool
};

export default React.memo(EquipmentMainFields);
