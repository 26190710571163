import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Modal, Icon, Segment, Checkbox } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteSensorMutation } from "../sensorService";

import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DeleteSensor = (props) => {
    const { item, setDeleteSensor } = props;
    const [confirm, setConfirm] = useState(false);
    const { org } = useSelector((state) => state);
    const [deleteSensor, deleted] = useDeleteSensorMutation();

    useEffect(() => {
        if (deleted.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete sensor`), toast_options);
            setDeleteSensor(null);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete sensor`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted, setDeleteSensor]);

    return (
        <Modal open closeOnDimmerClick={false} closeOnEscape={false} centered={false}>
            <Modal.Header>
                <Trans>want delete sensor {item?.identifier}</Trans>
            </Modal.Header>
            <Modal.Content>
                <Segment basic>
                    <div style={{ color: "#794b02", fontStyle: "italic" }}>
                        <Trans>Once deleted, you will no longer receive data from this sensor</Trans>
                    </div>
                </Segment>
                <Segment basic>
                    <Checkbox
                        label={
                            <label>
                                <Trans>yes, i confirm deletion</Trans>
                            </label>
                        }
                        onChange={(e, data) => setConfirm(data.checked)}
                        checked={confirm}
                    />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setDeleteSensor(null)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    labelPosition="right"
                    icon
                    positive
                    onClick={async (e) => {
                        if (confirm) {
                            deleteSensor({ org: org.current, data: item.id });
                        }
                    }}
                    disabled={!confirm}
                >
                    <Trans>validate</Trans>
                    <Icon name="trash" inverted />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DeleteSensor.propTypes = {
    item: PropTypes.object.isRequired,
    setDeleteSensor: PropTypes.func.isRequired
};

export default DeleteSensor;
