import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import { Button, Modal, Icon, Popup, Segment, Checkbox } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useDeleteCalendarMutation } from "../calendarService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

export const DeleteCalendar = (props) => {
    const today = moment().startOf("d");
    const { item, id_site } = props;
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { org } = useSelector((state) => state);

    const can_change = props.can_change; //default is site's rights
    const past_schedule =
        item.validity_end !== null && item.validity_start <= today.format("YYYY-MM-DD") && item.validity_end <= today.format("YYYY-MM-DD");

    const [deleteCalendar, deleted] = useDeleteCalendarMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`Successful delete opening calendar`), toast_options);
            setOpen(false);
        }
        if (deleted.isError) {
            let error = i18n._(t`can't delete opening calendar`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted]);

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            trigger={
                <Popup
                    trigger={
                        <Button
                            type="button"
                            icon="delete"
                            onClick={async (e) => {
                                setOpen(true);
                            }}
                        />
                    }
                >
                    <Popup.Content>
                        <Trans>Deletion of opening calendar</Trans>
                    </Popup.Content>
                </Popup>
            }
            onClose={() => {
                setOpen(false);
                setConfirm(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Trans>want delete opening calendar {item.name}</Trans>
            </Modal.Header>
            <Modal.Content>
                {past_schedule && (
                    <MessageDisplay
                        message={i18n._(t`Opening calendar with dates in the past can't be deleted`)}
                        level="info"
                        iconName="info circle"
                        isLoading={false}
                    />
                )}
                {can_change && !past_schedule && (
                    <Segment basic>
                        <Checkbox
                            label={
                                <label>
                                    <Trans>yes, i confirm deletion</Trans>
                                </label>
                            }
                            onChange={(e, data) => setConfirm(data.checked)}
                            checked={confirm}
                        />
                    </Segment>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setOpen(false);
                        setConfirm(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                {can_change && !past_schedule && (
                    <Button
                        type="button"
                        labelPosition="right"
                        icon
                        positive
                        onClick={(e) => {
                            deleteCalendar({ org: org.current, id_site, data: item.id });
                        }}
                        disabled={!confirm}
                    >
                        <Icon name="check" />
                        <Trans>validate</Trans>
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteCalendar;
