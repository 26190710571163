import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { t } from "@lingui/macro";
import { Button, Grid } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

import i18n from "modules/i18n/i18nConfig";
import { useGetCalendarsQuery } from "modules/calendar/calendarService";

import TableEnhanced from "modules/common/components/TableEnhanced";
import AddCalendarModalForm from "modules/calendar/component/AddCalendarModalForm";
import UpdateCalendarModalForm from "modules/calendar/component/UpdateCalendarModalForm";
import DeleteCalendar from "modules/calendar/component/DeleteCalendar";
import MessageDisplay from "modules/common/components/MessageDisplay";

const SiteCalendarWeeks = (props) => {
    const { id_site, active, org } = props;
    const current_lng = useSelector((state) => state.i18n.current);

    //retrieve all calendars weeks
    const calendars = useGetCalendarsQuery({ org: org.current, id_site }, { skip: !org.current || active === false || id_site === undefined });

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "start_date", label: i18n._(t`Start date`), textAlign: "left" },
        { id: "end_date", label: i18n._(t`End date`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const local_calendars = useMemo(() => {
        if (calendars.isSuccess) {
            return calendars.data;
        }
        return [];
    }, [calendars]);

    const weeks_list = useMemo(() => {
        return _.chain(local_calendars)
            .reduce((res, item) => {
                const customStyle = { cursor: "default", whiteSpace: "nowrap" };
                res.push({
                    ...item,
                    id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                    name: {
                        render: _.get(item, "name", "-"),
                        value: _.get(item, "name", "-"),
                        textAlign: "left",
                        datatype: "string"
                    },
                    start_date: {
                        render: (() => {
                            const start = moment(item.validity_start, "YYYY-MM-DD", true);
                            if (start.isValid()) {
                                return <span style={{ customStyle }}>{start.locale(current_lng).format("L")}</span>;
                            }
                            return <span style={{ customStyle }}>-</span>;
                        })(),
                        value: moment(item.validity_start, "YYYY-MM-DD", true),
                        textAlign: "left",
                        datatype: "date"
                    },
                    end_date: {
                        render: (() => {
                            const end = moment(item.validity_end, "YYYY-MM-DD", true);
                            if (end.isValid()) {
                                return <span style={{ customStyle }}>{end.locale(current_lng).format("L")}</span>;
                            }
                            return <span style={{ customStyle }}>-</span>;
                        })(),
                        value: moment(item.validity_end, "YYYY-MM-DD", true),
                        textAlign: "left",
                        datatype: "date"
                    },
                    actions: {
                        render: (() => (
                            <Button.Group>
                                {<UpdateCalendarModalForm {...props} item={item} calendars={local_calendars || []} />}
                                {props.can_change && <DeleteCalendar {...props} item={item} />}
                            </Button.Group>
                        ))(),
                        value: null,
                        textAlign: "right",
                        datatype: null
                    }
                });
                return res;
            }, [])
            .value();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [local_calendars, current_lng]);

    return (
        <Grid celled style={{ margin: 0 }}>
            {(calendars.isFetching || calendars.isFetching) && (
                <Grid.Column width={16}>
                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                </Grid.Column>
            )}
            {calendars.isError && (
                <Grid.Column width={16}>
                    <MessageDisplay
                        message={i18n._(t`error loading data`)}
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                        attache={false}
                    />
                </Grid.Column>
            )}
            {calendars.isSuccess && (
                <>
                    {props.can_change && (
                        <Grid.Column width={16}>
                            <AddCalendarModalForm {...props} calendars={calendars.data || []} />
                        </Grid.Column>
                    )}
                    <Grid.Column width={16}>
                        <TableEnhanced
                            headCells={headCells}
                            rows={weeks_list}
                            order="desc"
                            orderBy="start_date"
                            page={1}
                            rowsPerPage={10}
                            textItemPerPages={i18n._(t`items per page`)}
                        />
                    </Grid.Column>
                </>
            )}
        </Grid>
    );
};

export default React.memo(SiteCalendarWeeks);
