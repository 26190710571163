import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Button, Divider, Dropdown, Grid } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetBearingsMutation } from "modules/machine/machineService";
import MessageDisplay from "modules/common/components/MessageDisplay";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

const KinematicSearchBearings = (props) => {
    const { bearing, changeForm, b_name } = props;
    const { org } = useSelector((state) => state);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [getBearingsModel, bearingsModel] = useGetBearingsMutation();
    const prevProps = usePrevious(props);

    useEffect(() => {
        if (bearingsModel.isSuccess) {
            if (prevProps !== undefined) {
                const { bearing: nextBearing } = props;
                const { bearing: prevBearing } = prevProps;
                if (prevBearing.by_order === false && nextBearing?.by_order === true) {
                    return;
                } else if (
                    nextBearing?.by_order === false ||
                    (typeof prevBearing?.order?.BPFI === "number" && typeof nextBearing?.order?.BPFI !== typeof prevBearing?.order?.BPFI) ||
                    (typeof prevBearing?.order?.BPF0 === "number" && typeof nextBearing?.order?.BPFO !== typeof prevBearing?.order?.BPFO) ||
                    (typeof prevBearing?.order?.FTF === "number" && typeof nextBearing?.order?.FTF !== typeof prevBearing?.order?.FTF) ||
                    (typeof prevBearing?.order?.BSF === "number" && typeof nextBearing?.order?.BSF !== typeof prevBearing?.order?.BSF)
                ) {
                    setSelectedLabel(null);
                }
            }
        }
    }, [props, prevProps, bearingsModel.isSuccess]);

    let err = i18n._(t`error during bearings retrieve`);
    if (bearingsModel?.error?.data?.err) {
        err = i18n._(bearingsModel?.error?.data?.err);
    }

    return (
        <Grid centered verticalAlign="top">
            <Grid.Column width={16} textAlign="center">
                <Button
                    color="blue"
                    type="button"
                    onClick={(e) => {
                        getBearingsModel({
                            search: bearing.model,
                            org: org.current
                        });
                    }}
                    disabled={!(typeof bearing.model === "string" && bearing.model.length > 0)}
                >
                    <Trans>Search bearing model</Trans>
                </Button>
            </Grid.Column>
            {bearingsModel.isFetching && (
                <Grid.Column width={16}>
                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                </Grid.Column>
            )}
            {bearingsModel.isError && (
                <Grid.Column width={16}>
                    <MessageDisplay message={err} level="error" iconName="warning circle" isLoading={false} attached={false} />
                </Grid.Column>
            )}
            {bearingsModel.isSuccess && (
                <Grid.Column width={16}>
                    <Divider horizontal>
                        <Trans>Result</Trans>
                    </Divider>
                    <Dropdown
                        icon=""
                        open={false}
                        multiple
                        selection
                        options={bearingsModel?.data ?? []}
                        value={_.map(bearingsModel?.data ?? [], (item) => {
                            return item.value;
                        })}
                        renderLabel={(label) => {
                            return {
                                color: selectedLabel === label.value ? "blue" : null,
                                content: label.text,
                                onRemove: null,
                                onClick: (e) => {
                                    setSelectedLabel(label.value);
                                    changeForm(`${b_name}.by_order`, true);
                                    changeForm(`${b_name}.order.BPFI`, label.bpfi);
                                    changeForm(`${b_name}.order.BPFO`, label.bpfo);
                                    changeForm(`${b_name}.order.FTF`, label.ftf);
                                    changeForm(`${b_name}.order.BSF`, label.bsf);
                                    changeForm(`${b_name}.maker`, label.manufacturer);
                                }
                            };
                        }}
                    />
                </Grid.Column>
            )}
        </Grid>
    );
};

export default React.memo(KinematicSearchBearings);
