import React, { useEffect, useMemo } from "react";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid, Header, Segment, Container, Divider, Button, Dimmer } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { regexNumberFloat, reparseNumber, validateNumber } from "modules/common/utils";
import { identityNull } from "modules/common/utils/form";
import { denominatorUnitsOptions } from "modules/analysisAdvanced/utils";
import {
    useCreateEnergySavingMutation,
    useGetEnergySavingQuery,
    useGetEnergySavingsQuery,
    useUpdateEnergySavingMutation
} from "../energySavingService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import history_app from "history_app";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import Back from "modules/common/components/back";
import { DateTimeAdapter, DropDownAdapter, InputAdapter, TextAreaAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DropdownAdapterMeasurement from "modules/export/components/DropdownAdapterMeasurement";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const EnergySaving = () => {
    const now = moment().startOf("day");
    const { id } = useParams();
    const { org, auth, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);

    const user_id = _.get(auth, "user.user_id", null);

    const energySavings = useGetEnergySavingsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });

    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const nrjSaving = useGetEnergySavingQuery({ org: org.current, energysaving_id: id }, { skip: !org.current || id === undefined });

    const [createEnergySaving, create] = useCreateEnergySavingMutation();
    const [updateEnergySaving, update] = useUpdateEnergySavingMutation();

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`Send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful create energy performance action plan`), toast_options);
            history_app.push(`/energysavings`);
        }
        if (create.isError) {
            let error = i18n._(t`Can't create energy performance action plan`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create]);

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`Send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update energy performance action plan`), toast_options);
            history_app.push(`/energysavings`);
        }
        if (update.isError) {
            let error = i18n._(t`Can't update energy performance action plan`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    let err_list = [
        energySavings.isError,
        sites.isError,
        equipments.isError,
        categories.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        units.isError,
        dataflows.isError,
        measurementtypes.isError,
        measurements.isError
    ];

    let status_list = [
        energySavings.isSuccess,
        sites.isSuccess,
        equipments.isSuccess,
        categories.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        dataflows.isSuccess,
        measurementtypes.isSuccess,
        measurements.isSuccess
    ];
    if (id !== undefined) {
        err_list = [...err_list, nrjSaving.isError];
        status_list = [...status_list, nrjSaving.isSuccess];
    }

    const default_ape = useMemo(() => {
        //Don't put 'now' in dependencies array
        return {
            name: "",
            description: "",
            site: null,
            numerator_measurements: [],
            reference_period_start: now.clone().subtract(7, "day"),
            reference_period_end: now.clone(),
            implementation_date: now.clone().add(1, "day"),
            tracking_date: now.clone().add(2, "day"),
            denominator_type: "no denominator type",
            denominator_measurement: null,
            denominator_factor: null,
            denominator_factor_unit: -1,
            owner: user_id
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = useMemo(() => {
        if (nrjSaving.isSuccess) {
            return {
                ...default_ape,
                owner: user_id, //default owner (for create)
                ...nrjSaving.data, //override owner here (for update)
                denominator_type: nrjSaving.data.denominator_type === null ? "no denominator type" : nrjSaving.data.denominator_type,
                denominator_factor_unit: nrjSaving.data.denominator_factor_unit === null ? -1 : nrjSaving.data.denominator_factor_unit,
                reference_period_start: nrjSaving.data?.reference_period_start
                    ? moment(nrjSaving.data.reference_period_start, "YYYY-MM-DD", true)
                    : default_ape.reference_period_start,
                reference_period_end: nrjSaving.data?.reference_period_end
                    ? moment(nrjSaving.data.reference_period_end, "YYYY-MM-DD", true)
                    : default_ape.reference_period_end,
                implementation_date: nrjSaving.data?.implementation_date
                    ? moment(nrjSaving.data.implementation_date, "YYYY-MM-DD", true)
                    : default_ape.implementation_date,
                tracking_date: nrjSaving.data?.tracking_date ? moment(nrjSaving.data.tracking_date, "YYYY-MM-DD", true) : default_ape.tracking_date
            };
        } else {
            return default_ape;
        }
    }, [default_ape, user_id, nrjSaving]);

    const remapUnits = useMemo(() => {
        if (units.data) {
            return [
                { key: -1, text: i18n._(t`no unit`), value: -1 },
                ..._.chain(units.data)
                    .reduce((res, item) => {
                        if (item.key === -1) return res;
                        // Restricted list of unit
                        if (_.includes(["kWh", "Wh", "MWh", "Nm³", "dm³", "l", "m²", "m³"], item.symbol)) {
                            res.push(item);
                        }
                        return res;
                    }, [])
                    .orderBy("symbol", "desc")
                    .value()
            ];
        } else {
            return [];
        }
    }, [units]);

    const submitForm = async (formData, form) => {
        try {
            if (notification.srv_status.db_status === "rw") {
                const { id } = formData;

                let data = {
                    ...formData,
                    denominator_type: formData.denominator_type === "no denominator type" ? null : formData.denominator_type,
                    denominator_measurement: formData.denominator_type !== "measurement" ? null : formData.denominator_measurement,
                    denominator_factor: reparseNumber(formData.denominator_factor),
                    denominator_factor_unit:
                        formData.denominator_factor_unit === -1 || !_.isFinite(reparseNumber(formData.denominator_factor))
                            ? null
                            : formData.denominator_factor_unit,
                    implementation_date: formData.implementation_date.format("YYYY-MM-DD"),
                    tracking_date: formData.tracking_date.format("YYYY-MM-DD"),
                    reference_period_start: formData.reference_period_start.format("YYYY-MM-DD"),
                    reference_period_end: formData.reference_period_end.format("YYYY-MM-DD")
                };
                if (id) {
                    updateEnergySaving({ org: org.current, data });
                } else {
                    createEnergySaving({ org: org.current, data });
                }
            }
        } catch (error) {}
    };

    const validate = (values) => {
        const errors = {};
        const { reference_period_start, reference_period_end, implementation_date, tracking_date } = values;

        if (reference_period_start === undefined) {
            errors.reference_period_start = <Trans>Required field</Trans>;
            return errors;
        }
        if (reference_period_end === undefined) {
            errors.reference_period_end = <Trans>Required field</Trans>;
            return errors;
        }
        if (implementation_date === undefined) {
            errors.implementation_date = <Trans>Required field</Trans>;
            return errors;
        }
        if (tracking_date === undefined) {
            errors.tracking_date = <Trans>Required field</Trans>;
            return errors;
        }

        if (typeof reference_period_start === "string") {
            errors.reference_period_start = <Trans>invalid format</Trans>;
            return errors;
        }
        if (typeof reference_period_end === "string") {
            errors.reference_period_end = <Trans>invalid format</Trans>;
            return errors;
        }
        if (typeof implementation_date === "string") {
            errors.implementation_date = <Trans>invalid format</Trans>;
            return errors;
        }
        if (typeof tracking_date === "string") {
            errors.tracking_date = <Trans>invalid format</Trans>;
            return errors;
        }

        //Here, all date supposed to be moment object
        /* Reference period start */
        if (reference_period_start.isAfter(reference_period_end)) {
            errors.reference_period_start = <Trans>Start date period can't be after end date</Trans>;
        }
        if (reference_period_start.isAfter(implementation_date)) {
            errors.reference_period_start = <Trans>Start date period can't be after implementation start date</Trans>;
        }
        if (reference_period_start.isAfter(tracking_date)) {
            errors.reference_period_start = <Trans>Start date period can't be after follow-up start date</Trans>;
        }
        /* Reference period end */
        if (reference_period_end.isBefore(reference_period_start)) {
            errors.reference_period_end = <Trans>End date period can't be before start date</Trans>;
        }
        if (reference_period_end.isAfter(implementation_date)) {
            errors.reference_period_end = <Trans>End date period can't be after implementation start date</Trans>;
        }
        if (reference_period_end.isAfter(tracking_date)) {
            errors.reference_period_end = <Trans>End date period can't be after follow-up start date</Trans>;
        }
        if (reference_period_end.isSame(implementation_date)) {
            errors.reference_period_end = <Trans>End date period can't be the same as implementation start date</Trans>;
        }
        /* Implementation date */
        if (implementation_date.isAfter(tracking_date)) {
            errors.implementation_date = <Trans>Implementation start date can't be after follow-up start date</Trans>;
        }
        if (implementation_date.isBefore(reference_period_start)) {
            errors.implementation_date = <Trans>Implementation start date can't be before start date period</Trans>;
        }
        if (implementation_date.isBefore(reference_period_end)) {
            errors.implementation_date = <Trans>Implementation start date can't be before end date period</Trans>;
        }
        if (implementation_date.isSame(reference_period_end)) {
            errors.implementation_date = <Trans>Implementation start date can't be the same as end date period</Trans>;
        }
        /* tracking date */
        if (tracking_date.isBefore(implementation_date)) {
            errors.tracking_date = <Trans>Follow-up start date can't be before implementation start date</Trans>;
        }
        if (tracking_date.isBefore(reference_period_start)) {
            errors.tracking_date = <Trans>Follow-up start date can't be before start date period</Trans>;
        }
        if (tracking_date.isBefore(reference_period_end)) {
            errors.tracking_date = <Trans>Follow-up start date can't be before end date period</Trans>;
        }
        return errors;
    };

    const disabled =
        notification.srv_status.db_status !== "rw" ||
        (id === undefined && _.size(auth.rights?.sites_rw) === 0) ||
        (id !== undefined && !_.includes(auth.rights?.sites_rw, nrjSaving.data?.site));

    const sitesOptions = useMemo(() => {
        return _.chain(sites.data)
            .reduce((res, site) => {
                if (id !== undefined) {
                    //update mode
                    const { key, text, value } = site;
                    res.push({
                        key,
                        text: i18n._(text),
                        value
                    });
                    return res;
                }
                //add mode
                if (_.includes(auth.rights?.sites_rw, site.id)) {
                    const { key, text, value } = site;
                    res.push({
                        key,
                        text: i18n._(text),
                        value
                    });
                    return res;
                }

                return res;
            }, [])
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [sites.data, id, auth.rights]);

    //limit category for dropdown measurement
    const restricted_cats_id = useMemo(() => {
        if (categories.isSuccess) {
            return _.reduce(
                categories.data,
                (res, category) => {
                    if (_.includes(["elec", "gas", "water"], category.name)) {
                        res.push(category.id);
                    }
                    return res;
                },
                []
            );
        }
        return [];
    }, [categories]);

    //limit measurementtype form dropdown measurement
    const restricted_mttypes_id = useMemo(() => {
        if (measurementtypes.isSuccess) {
            return _.reduce(
                measurementtypes.data,
                (res, mttype) => {
                    if (_.includes(["p_act_import", "index_nm3", "water_import"], mttype.name)) {
                        res.push(mttype.id);
                    }
                    return res;
                },
                []
            );
        }
        return [];
    }, [measurementtypes]);

    const restricted_measurements = useMemo(() => {
        if (measurements.isSuccess) {
            return _.chain(measurements)
                .get("data", [])
                .reduce((res, measure) => {
                    if (_.includes(restricted_mttypes_id, measure?.measurementtype)) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .value();
        }
        return [];
    }, [measurements, restricted_mttypes_id]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id === undefined && <Trans>Add energy saving action</Trans>}
                            {id !== undefined && !disabled && <Trans>Update energy performance action plan</Trans>}
                            {id !== undefined && disabled && <Trans>View energy performance action plan</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {(() => {
                            if (_.some(err_list)) {
                                return (
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                );
                            } else if (_.every(status_list)) {
                                return (
                                    <Container>
                                        <Form
                                            onSubmit={submitForm}
                                            initialValues={initialValues}
                                            validate={validate}
                                            render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                                //Disable denominator dropdown unit when facotr is invalid
                                                const disabled_denominator_factor_unit =
                                                    values.denominator_factor === null || !regexNumberFloat.test(values.denominator_factor);

                                                return (
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                        <Grid centered>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="name"
                                                                        placeholder={i18n._(t`enter name of action plan`)}
                                                                        label={i18n._(t`name`)}
                                                                        isRequired={true}
                                                                        component={InputAdapter}
                                                                        validate={(value) => {
                                                                            const existing_name = _.chain(energySavings.data)
                                                                                .filter((saving) => {
                                                                                    return saving.id !== parseInt(id);
                                                                                })
                                                                                .find({ name: value })
                                                                                .value();

                                                                            if (existing_name) {
                                                                                return <Trans>a action plan with this name exists</Trans>;
                                                                            }
                                                                            if (!value) {
                                                                                return <Trans>name is required</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                        disabled={disabled}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="site"
                                                                        label={i18n._(t`site`)}
                                                                        placeholder={i18n._(t`select site`)}
                                                                        options={sitesOptions}
                                                                        component={DropDownAdapter}
                                                                        isRequired={true}
                                                                        disabled={disabled || id !== undefined}
                                                                        customAction={(data) => {
                                                                            const measures = form.getFieldState("numerator_measurements").value;
                                                                            if (!_.isEmpty(measures)) {
                                                                                form.change("numerator_measurements", []);
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="description"
                                                                        placeholder={i18n._(t`enter description of your action plan`)}
                                                                        label={i18n._(t`description`)}
                                                                        component={TextAreaAdapter}
                                                                        disabled={disabled}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Divider horizontal>
                                                                        <Trans>Reference period</Trans>
                                                                    </Divider>
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="reference_period_start"
                                                                        component={DateTimeAdapter}
                                                                        locale={current_lng}
                                                                        disabled={disabled}
                                                                        date_limit={null}
                                                                        labeled={true}
                                                                        label={i18n._(t`from`)}
                                                                        labelPosition={"left"}
                                                                        isRequired={true}
                                                                        dateFormat={true}
                                                                        timeFormat={false}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="reference_period_end"
                                                                        component={DateTimeAdapter}
                                                                        locale={current_lng}
                                                                        disabled={disabled}
                                                                        now={now.clone()}
                                                                        labeled={true}
                                                                        label={i18n._(t`to`)}
                                                                        labelPosition={"left"}
                                                                        isRequired={true}
                                                                        date_limit={null}
                                                                        dateFormat={true}
                                                                        timeFormat={false}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="implementation_date"
                                                                        component={DateTimeAdapter}
                                                                        locale={current_lng}
                                                                        disabled={disabled}
                                                                        now={now.clone()}
                                                                        labeled={false}
                                                                        label={i18n._(t`Implementation start date`)}
                                                                        isRequired={true}
                                                                        date_limit={null}
                                                                        dateFormat={true}
                                                                        timeFormat={false}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <Field
                                                                        name="tracking_date"
                                                                        component={DateTimeAdapter}
                                                                        locale={current_lng}
                                                                        disabled={disabled}
                                                                        now={now.clone()}
                                                                        labeled={false}
                                                                        label={i18n._(t`Follow-up start date`)}
                                                                        isRequired={true}
                                                                        date_limit={null}
                                                                        dateFormat={true}
                                                                        timeFormat={false}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <Header size="tiny" attached="top" block textAlign="center">
                                                                        <Trans>Perimeter</Trans>
                                                                    </Header>
                                                                    <Segment attached>
                                                                        <Grid centered verticalAlign="bottom">
                                                                            <Grid.Row>
                                                                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                                    <Dimmer.Dimmable
                                                                                        dimmed={true}
                                                                                        style={{ zIndex: 0, overflow: "visible" }}
                                                                                    >
                                                                                        <Dimmer inverted active={values.site === null}>
                                                                                            <MessageDisplay
                                                                                                message={i18n._(t`select site`)}
                                                                                                level="warning"
                                                                                                iconName="warning circle"
                                                                                                isLoading={false}
                                                                                                attached={false}
                                                                                            />
                                                                                        </Dimmer>
                                                                                        <Field
                                                                                            name="numerator_measurements"
                                                                                            label={i18n._(t`measurements`)}
                                                                                            placeholder={i18n._(t`choose your measurement here`)}
                                                                                            options={restricted_measurements}
                                                                                            component={DropdownAdapterMeasurement}
                                                                                            disabled={disabled}
                                                                                            sites={sites.data}
                                                                                            usages={usages.data}
                                                                                            siteDisabled={true}
                                                                                            sites_filter={[values.site]}
                                                                                            restricted_cats_id={restricted_cats_id}
                                                                                            restricted_mttypes_id={restricted_mttypes_id}
                                                                                            multipleselect={1}
                                                                                            displayAddBtn={false}
                                                                                            noResultsMessage={i18n._(t`no result found`)}
                                                                                            open={false}
                                                                                            validate={(value) => {
                                                                                                if (_.size(value) === 0) {
                                                                                                    return <Trans>measurement is required</Trans>;
                                                                                                }
                                                                                                const first_measure = _.head(restricted_measurements);
                                                                                                const first_mttype = _.find(measurementtypes.data, {
                                                                                                    id: first_measure?.measurementtype
                                                                                                });
                                                                                                const same_unit = _.every(value, (id_measure) => {
                                                                                                    const measure = _.find(restricted_measurements, {
                                                                                                        id: id_measure
                                                                                                    });
                                                                                                    const mttype = _.find(measurementtypes.data, {
                                                                                                        id: measure?.measurementtype
                                                                                                    });
                                                                                                    if (
                                                                                                        first_measure === undefined ||
                                                                                                        mttype === undefined
                                                                                                    ) {
                                                                                                        return false;
                                                                                                    }
                                                                                                    return first_mttype?.unit === mttype?.unit;
                                                                                                });

                                                                                                if (!same_unit) {
                                                                                                    return (
                                                                                                        <Trans>
                                                                                                            One or more measurement(s) don't have the
                                                                                                            same unit
                                                                                                        </Trans>
                                                                                                    );
                                                                                                }
                                                                                                return undefined;
                                                                                            }}
                                                                                        />
                                                                                    </Dimmer.Dimmable>
                                                                                </Grid.Column>
                                                                            </Grid.Row>
                                                                        </Grid>
                                                                    </Segment>
                                                                    <Header size="tiny" attached block textAlign="center">
                                                                        <Trans>Adjustment factor</Trans>
                                                                    </Header>
                                                                    <Segment attached>
                                                                        <Grid centered verticalAlign="bottom">
                                                                            <Grid.Row>
                                                                                <Grid.Column width={7}>
                                                                                    <Field
                                                                                        name="denominator_type"
                                                                                        placeholder={i18n._(t`select denominator type`)}
                                                                                        noResultsMessage={i18n._(t`no result found`)}
                                                                                        options={denominatorUnitsOptions}
                                                                                        component={DropDownAdapter}
                                                                                        upward
                                                                                        disabled={disabled}
                                                                                        defaultValue={denominatorUnitsOptions[0].value}
                                                                                    />
                                                                                </Grid.Column>
                                                                                {values?.denominator_type === "measurement" && (
                                                                                    <Grid.Column width={7}>
                                                                                        <Dimmer.Dimmable
                                                                                            dimmed={true}
                                                                                            style={{ zIndex: 0, overflow: "visible" }}
                                                                                        >
                                                                                            <Dimmer inverted active={values.site === null}>
                                                                                                <MessageDisplay
                                                                                                    message={i18n._(t`select site`)}
                                                                                                    level="warning"
                                                                                                    iconName="warning circle"
                                                                                                    isLoading={false}
                                                                                                    attached={false}
                                                                                                />
                                                                                            </Dimmer>
                                                                                            <Field
                                                                                                name="denominator_measurement"
                                                                                                placeholder={i18n._(t`choose your measurement here`)}
                                                                                                options={restricted_measurements}
                                                                                                component={DropdownAdapterMeasurement}
                                                                                                sites={sites.data}
                                                                                                usages={usages.data}
                                                                                                siteDisabled={true}
                                                                                                sites_filter={[values.site]}
                                                                                                restricted_cats_id={restricted_cats_id}
                                                                                                restricted_mttypes_id={restricted_mttypes_id}
                                                                                                displayAddBtn={false}
                                                                                                disabled={disabled}
                                                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                                                open={false}
                                                                                                validate={(value) => {
                                                                                                    if (!value) {
                                                                                                        return <Trans>measurement is required</Trans>;
                                                                                                    }
                                                                                                    return undefined;
                                                                                                }}
                                                                                            />
                                                                                        </Dimmer.Dimmable>
                                                                                    </Grid.Column>
                                                                                )}
                                                                            </Grid.Row>
                                                                            <Grid.Row>
                                                                                <Grid.Column width={7}>
                                                                                    <Field
                                                                                        name="denominator_factor"
                                                                                        placeholder={i18n._(t`enter denominator factor`)}
                                                                                        type="text"
                                                                                        label={i18n._(t`denominator_factor`)}
                                                                                        component={InputAdapter}
                                                                                        parse={identityNull}
                                                                                        inputMode="decimal"
                                                                                        disabled={disabled}
                                                                                        defaultValue={null}
                                                                                        validate={(value) => {
                                                                                            return validateNumber(value, i18n, false, true);
                                                                                        }}
                                                                                    />
                                                                                </Grid.Column>
                                                                                <Grid.Column width={7}>
                                                                                    <Field
                                                                                        name="denominator_factor_unit"
                                                                                        label={i18n._(t`denominator_factor_unit`)}
                                                                                        placeholder={i18n._(t`select denominator factor unit`)}
                                                                                        options={remapUnits} //exclude 'dynamic_unit'
                                                                                        component={DropDownAdapter}
                                                                                        upward
                                                                                        disabled={disabled || disabled_denominator_factor_unit}
                                                                                    />
                                                                                </Grid.Column>
                                                                            </Grid.Row>
                                                                        </Grid>
                                                                    </Segment>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                        {!disabled && (
                                                            <>
                                                                <Divider />
                                                                <Button
                                                                    type="submit"
                                                                    content={i18n._(t`validate`)}
                                                                    disabled={submitting || pristine || invalid}
                                                                />
                                                            </>
                                                        )}
                                                    </form>
                                                );
                                            }}
                                        />
                                    </Container>
                                );
                            } else {
                                return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
                            }
                        })()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(EnergySaving);
