import React, { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Grid, Segment, Header, Divider, Button, Modal, Icon, Table, Container } from "semantic-ui-react";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { checkRights } from "modules/auth/utils";
import history_app from "history_app";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useUpdateSensorMutation } from "../sensorService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { DropDownAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const validate = (values) => {
    const errors = {};
    if (_.get(values, "site", null) === null) {
        errors.site = <Trans>site is required</Trans>;
    }
    return errors;
};

const Sensor = (props) => {
    const { id } = useParams();
    const { org, auth, sensor, sensortype, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const translate = i18n.use(current_lng);
    const [open, setOpen] = useState(false);

    const current_sensor = _.find(sensor.sensors, { id: parseInt(id) });

    const sensor_type = _.find(sensortype.sensortypes, { name: _.get(current_sensor, "sensor_type_name", "") });
    const sensor_site = _.find(props.sites, { id: _.get(current_sensor, "site") });
    const rights = checkRights(current_sensor, auth.rights);

    const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");

    const [updateSensor, update] = useUpdateSensorMutation();

    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update sensor site`), toast_options);
            history_app.push("/sensors");
        }
        if (update.isError) {
            let error = i18n._(t`cannot change site of sensor`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
    }, [update]);

    const onSubmitForm = async (data) => {
        await updateSensor({ org: org.current, data });
    };

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>sensor edition</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                {!current_sensor && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={translate._(t`unknown sensor`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {current_sensor && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Container>
                                <Form
                                    onSubmit={onSubmitForm}
                                    initialValues={current_sensor}
                                    validate={validate}
                                    render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                        return (
                                            <>
                                                <Table celled striped compact attached>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width={5}>
                                                                <Header as="h4">
                                                                    <Trans>identifier</Trans>
                                                                </Header>
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                                            >
                                                                {_.get(current_sensor, "identifier", "-") || "-"}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                <Header as="h4">
                                                                    <Trans>sensor_type</Trans>
                                                                </Header>
                                                            </Table.Cell>
                                                            <Table.Cell>{i18n._(_.get(sensor_type, "text", "-"))}</Table.Cell>
                                                        </Table.Row>
                                                        {!can_change && (
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <Header as="h4">
                                                                        <Trans>site</Trans>
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>{i18n._(_.get(sensor_site, "text", "-"))}</Table.Cell>
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                                <form onSubmit={handleSubmit} className="ui error form">
                                                    {can_change && (
                                                        <Field
                                                            name="site"
                                                            label={translate._(t`site`)}
                                                            placeholder={translate._(t`select site`)}
                                                            options={_.chain(props.sites)
                                                                .map(({ key, text, value }) => ({ key, text, value }))
                                                                .orderBy((item) => {
                                                                    return removeAccents(item.text).toLowerCase();
                                                                }, "asc")
                                                                .value()}
                                                            component={DropDownAdapter}
                                                            isRequired={true}
                                                            validate={(value) => {
                                                                if (!_.isFinite(value)) {
                                                                    return <Trans>site is required</Trans>;
                                                                } else if (!_.includes(auth.rights?.sites_rw, value)) {
                                                                    return <Trans>insufficient site rights</Trans>;
                                                                } else {
                                                                    return undefined;
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    {can_change && (
                                                        <>
                                                            <Divider />
                                                            <Segment basic>
                                                                <Modal
                                                                    onClose={() => setOpen(false)}
                                                                    onOpen={() => setOpen(true)}
                                                                    open={open}
                                                                    trigger={
                                                                        <Button
                                                                            color="green"
                                                                            type="button"
                                                                            content={i18n._(t`validate`)}
                                                                            disabled={submitting || pristine || invalid}
                                                                        />
                                                                    }
                                                                >
                                                                    <Modal.Header>
                                                                        <Trans>change site validation</Trans>
                                                                    </Modal.Header>
                                                                    <Modal.Content>
                                                                        <Segment basic>
                                                                            <b>
                                                                                <Trans>are you sure you want to change the sensor site for</Trans>
                                                                                {` "${current_sensor.identifier}" ?`}
                                                                            </b>
                                                                        </Segment>
                                                                        <Segment basic color="orange">
                                                                            <div style={{ color: "#F2711C", fontStyle: "italic" }}>
                                                                                <Trans>
                                                                                    changing the sensor site might lead to the modification and/or
                                                                                    deletion of several elements related to this sensor
                                                                                </Trans>
                                                                            </div>
                                                                        </Segment>
                                                                    </Modal.Content>
                                                                    <Modal.Actions>
                                                                        <Button type="button" negative onClick={() => setOpen(false)}>
                                                                            <Trans>cancel</Trans>
                                                                        </Button>
                                                                        <Button
                                                                            type="button"
                                                                            labelPosition="right"
                                                                            icon
                                                                            positive
                                                                            onClick={async (e) => {
                                                                                await form.submit();
                                                                                await setOpen(false);
                                                                            }}
                                                                        >
                                                                            <Icon name="check" />
                                                                            <Trans>validate</Trans>
                                                                        </Button>
                                                                    </Modal.Actions>
                                                                </Modal>
                                                            </Segment>
                                                        </>
                                                    )}
                                                </form>
                                            </>
                                        );
                                    }}
                                />
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default Sensor;
