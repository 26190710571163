import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Icon, Menu, Popup, Tab } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { useGetEquipmentQuery } from "../equipmentService";

import { Media } from "App";
import MessageDisplay from "modules/common/components/MessageDisplay";
import PaneMeasurements from "./PaneMeasurements";
import EquipmentMainForm from "./form/EquipmentMainForm";
import EquipmentSecondaryForm from "./form/EquipmentSecondaryForm";
import PaneEfficiency from "./PaneEfficiency";
import PaneEvents from "./PaneEvents";
import PaneKinematic from "./PaneKinematic";

const EquipmentTab = (props) => {
    const { id_eqpmt, section } = useParams(); //id_eqmt === undefined for add
    const org = useSelector((state) => state.org);
    useSelector((state) => state.i18n.current); //force refresh for lng
    const [tabIndex, setTabIndex] = useState(0);

    const equipment = useGetEquipmentQuery({ org: org.current, data: id_eqpmt }, { skip: !org.current || id_eqpmt === undefined });

    const panes = useMemo(() => {
        let panes = [];
        if (equipment.isSuccess) {
            panes.push({
                key: "general_information",
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="general_information" {...props}>
                            <Media lessThan="computer">
                                <Popup
                                    content={i18n._(t`Informations`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="info" />
                                        </Icon.Group>
                                    }
                                />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && (
                                    <Popup
                                        content={i18n._(t`Informations`)}
                                        trigger={
                                            <Icon.Group>
                                                <Icon name="building" />
                                                <Icon corner="top right" name="info" />
                                            </Icon.Group>
                                        }
                                    />
                                )}
                                {active && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="info" />
                                        </Icon.Group>
                                        <Trans>Informations</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon.Group>
                                    <Icon name="building" />
                                    <Icon corner="top right" name="info" />
                                </Icon.Group>
                                <Trans>Informations</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <Tab.Pane>
                        <EquipmentMainForm equipment={equipment.data} />
                    </Tab.Pane>
                )
            });
            panes.push({
                key: "additional_information",
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="additional_information" {...props}>
                            <Media lessThan="computer">
                                <Popup
                                    content={i18n._(t`Description`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="plus" />
                                        </Icon.Group>
                                    }
                                />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && (
                                    <Popup
                                        content={i18n._(t`Description`)}
                                        trigger={
                                            <Icon.Group>
                                                <Icon name="building" />
                                                <Icon corner="top right" name="plus" />
                                            </Icon.Group>
                                        }
                                    />
                                )}
                                {active && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="plus" />
                                        </Icon.Group>
                                        <Trans>Description</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon.Group>
                                    <Icon name="building" />
                                    <Icon corner="top right" name="plus" />
                                </Icon.Group>
                                <Trans>Description</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <Tab.Pane>
                        <EquipmentSecondaryForm equipment={equipment.data} />
                    </Tab.Pane>
                )
            });

            panes.push({
                key: "measurements",
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="measurements" {...props}>
                            <Media lessThan="computer">
                                <Popup
                                    content={i18n._(t`Measurements`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="plus" />
                                        </Icon.Group>
                                    }
                                />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && (
                                    <Popup
                                        content={i18n._(t`Measurements`)}
                                        trigger={
                                            <Icon.Group>
                                                <Icon name="building" />
                                                <Icon corner="top right" name="plus" />
                                            </Icon.Group>
                                        }
                                    />
                                )}
                                {active && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="plus" />
                                        </Icon.Group>
                                        <Trans>Measurements</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon.Group>
                                    <Icon name="building" />
                                    <Icon corner="top right" name="plus" />
                                </Icon.Group>
                                <Trans>Measurements</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <>
                        <Tab.Pane>
                            <PaneMeasurements equipment={equipment.data} />
                        </Tab.Pane>
                    </>
                )
            });

            if (_.isFinite(equipment.data?.efficiency)) {
                panes.push({
                    key: "efficiency",
                    menuItem: (_, props) => {
                        const { active } = props;
                        return (
                            <Menu.Item key="efficiency" {...props}>
                                <Media lessThan="computer">
                                    <Popup content={i18n._(t`Efficiency`)} trigger={<Icon name="gg" />} />
                                </Media>
                                <Media between={["computer", "bigScreen"]}>
                                    {!active && <Popup content={i18n._(t`Efficiency`)} trigger={<Icon name="gg" />} />}
                                    {active && (
                                        <>
                                            <Icon name="gg" />
                                            <Trans>Efficiency</Trans>
                                        </>
                                    )}
                                </Media>
                                <Media greaterThanOrEqual="bigScreen">
                                    <Icon name="gg" />
                                    <Trans>Efficiency</Trans>
                                </Media>
                            </Menu.Item>
                        );
                    },
                    render: () => (
                        <>
                            <Tab.Pane>
                                <PaneEfficiency equipment={equipment.data} />
                            </Tab.Pane>
                        </>
                    )
                });
            }

            if (equipment.data?.machine !== null) {
                panes.push({
                    key: "machineKinematic",
                    menuItem: (_, props) => {
                        const { active } = props;
                        return (
                            <Menu.Item key="machineKinematic" {...props}>
                                <Media lessThan="computer">
                                    <Popup content={i18n._(t`Machine kinematic`)} trigger={<Icon name="gg" />} />
                                </Media>
                                <Media between={["computer", "bigScreen"]}>
                                    {!active && <Popup content={i18n._(t`Machine kinematic`)} trigger={<Icon name="gg" />} />}
                                    {active && (
                                        <>
                                            <Icon name="gg" />
                                            <Trans>Machine kinematic</Trans>
                                        </>
                                    )}
                                </Media>
                                <Media greaterThanOrEqual="bigScreen">
                                    <Icon name="gg" />
                                    <Trans>Machine kinematic</Trans>
                                </Media>
                            </Menu.Item>
                        );
                    },
                    render: () => (
                        <>
                            <Tab.Pane>
                                <PaneKinematic equipment={equipment.data} />
                            </Tab.Pane>
                        </>
                    )
                });
                panes.push({
                    key: "machineEvents",
                    menuItem: (_, props) => {
                        const { active } = props;
                        return (
                            <Menu.Item key="machineEvents" {...props}>
                                <Media lessThan="computer">
                                    <Popup content={i18n._(t`Machine events`)} trigger={<Icon name="calendar alternate outline" />} />
                                </Media>
                                <Media between={["computer", "bigScreen"]}>
                                    {!active && <Popup content={i18n._(t`Machine events`)} trigger={<Icon name="calendar alternate outline" />} />}
                                    {active && (
                                        <>
                                            <Icon.Group>
                                                <Icon name="building" />
                                                <Icon corner="top right" name="plus" />
                                            </Icon.Group>
                                            <Trans>Machine events</Trans>
                                        </>
                                    )}
                                </Media>
                                <Media greaterThanOrEqual="bigScreen">
                                    <Icon name="calendar alternate outline" />
                                    <Trans>Machine events</Trans>
                                </Media>
                            </Menu.Item>
                        );
                    },
                    render: () => (
                        <>
                            <Tab.Pane>
                                <PaneEvents equipment={equipment.data} />
                            </Tab.Pane>
                        </>
                    )
                });
            }
        }
        return panes;
    }, [equipment]);

    useEffect(() => {
        const tabIndex = _.findIndex(panes, { key: section });
        if (tabIndex >= 0) setTabIndex(tabIndex);
    }, [section, panes]);

    if (equipment.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (equipment.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }
    if (equipment.isSuccess) {
        return (
            <Tab
                panes={panes}
                activeIndex={tabIndex}
                onTabChange={(e, data) => {
                    const { activeIndex } = data;
                    const key = data.panes?.[activeIndex]?.key ?? null;
                    if (key) {
                        return history_app.push(`/equipments/${id_eqpmt}/${key}`);
                    }
                }}
                menu={{ attached: true, tabular: true, className: "pwaOverviewTabMenu" }}
            />
        );
    }
    return null;
};

export default React.memo(EquipmentTab);
