import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import { Grid, Segment, Header, Button, Icon, Popup, Divider, Accordion, Message } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import {
    advancedUnitsOptions,
    graphicTypeOptions,
    denominatorUnitsOptions,
    comparison_options,
    aggregate_options
} from "../../../analysisAdvanced/utils";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { historic_options_widget, keyToReparseInDnD, WidgetTypeOptions } from "modules/dashboardDragNDrop/utils";
import { groupByOptions } from "../../../analysisAdvanced/utils";
import { identity, identityNull } from "modules/common/utils/form";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useCreateWidgetMutation, useUpdateWidgetMutation } from "../../dashboardDndService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DropdownAdapterMeasurement from "modules/export/components/DropdownAdapterMeasurement";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const StackBarRatio = (props) => {
    const { current_dashboard, onClose, widgetChoice, widget, disabled } = props;

    const { org } = useSelector((state) => state);

    const [createWidget, create] = useCreateWidgetMutation();
    const [updateWidget, update] = useUpdateWidgetMutation();

    // Create Widget
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful create widget`), toast_options);
            onClose();
        }
        if (create.isError) {
            let error = i18n._(t`Can't create widget`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
            onClose();
        }
    }, [create, onClose]);

    // Update Widget
    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update widget`), toast_options);
            onClose();
        }
        if (update.isError) {
            let error = i18n._(t`Can't update widget`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
            onClose();
        }
    }, [update, onClose]);

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const err_list = [sites.isError, zones.isError, usages.isError, tags.isError, categories.isError, units.isError, measurements.isError];

    const status_list = [
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        units.isSuccess,
        measurements.isSuccess,
        equipments.isSuccess,
        dataflows.isSuccess
    ];

    const remapUnits = useMemo(() => {
        return [{ key: -1, text: i18n._(t`no unit`), value: -1 }, ..._.filter(units?.data, (item) => item.key !== -1)];
    }, [units]);

    const initialValues = useMemo(() => {
        const defaultValues = {
            toggle_accordion: false,
            name: null,
            numerator_unit: null,
            widget_type: widgetChoice,
            historic: null,
            sites: null,
            zones: [],
            usages: [],
            tags: [],
            categories: [],
            reference_level: null,
            threshold_min: null,
            threshold_max: null,
            dashboard: current_dashboard.id,
            numerator_factor: null,
            numerator_factor_unit: null,
            denominator: null,
            denominator_measurement: null,
            denominator_factor: null,
            aggregation: 1,
            x: 0,
            y: 0,
            w: 5,
            h: 5
        };

        return {
            ...defaultValues,
            denominator: defaultValues.denominator === null ? denominatorUnitsOptions[0].value : defaultValues.denominator,
            ...widget,
            denominator_factor_unit: (widget?.denominator_factor_unit ?? null) === null ? -1 : widget.denominator_factor_unit,
            numerator_factor_unit: (widget?.numerator_factor_unit ?? null) === null ? -1 : widget.numerator_factor_unit,
            sites: _.chain(widget)
                .get("sites", [])
                .head()
                .defaultTo(_.size(sites.data) === 1 && sites.data[0].id ? sites.data?.[0].id : null)
                .value()
        };
    }, [widget, widgetChoice, current_dashboard.id, sites.data]);

    //limit category for dropdown measurement
    const { restricted_cats_id, filtered_cats } = useMemo(() => {
        if (categories.isSuccess) {
            const cats = _.chain(categories.data)
                .reduce((res, category) => {
                    if (_.includes(["elec", "gas", "water", "calories", "frigories"], category.name)) {
                        const { key, text, value } = category;
                        res.push({
                            key,
                            text: i18n._(text),
                            value
                        });
                    }
                    return res;
                }, [])
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
            return {
                filtered_cats: cats,
                restricted_cats_id: _.map(cats, "value")
            };
        }
        return { restricted_cats_id: [], filtered_cats: [] };
    }, [categories]);

    //limit measurementtype form dropdown measurement
    const restricted_mttypes_id = useMemo(() => {
        if (measurementtypes.isSuccess) {
            return _.reduce(
                measurementtypes.data,
                (res, mttype) => {
                    if (_.includes(["p_act_import", "index_nm3", "water_import"], mttype.name)) {
                        res.push(mttype.id);
                    }
                    return res;
                },
                []
            );
        }
        return [];
    }, [measurementtypes]);

    const restricted_measurements = useMemo(() => {
        if (measurements.isSuccess) {
            return _.chain(measurements)
                .get("data", [])
                .reduce((res, measure) => {
                    if (_.includes(restricted_mttypes_id, measure?.measurementtype)) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .value();
        }
        return [];
    }, [measurements, restricted_mttypes_id]);

    const submitForm = async (formdata) => {
        let data = _.mapValues(formdata, (item, key) => {
            if (_.includes(keyToReparseInDnD, key)) {
                return reparseNumber(item);
            }
            return item;
        });

        data = {
            ..._.omit(data, ["toggle_accordion"]),
            denominator: data.denominator === denominatorUnitsOptions[0].value ? null : data.denominator,
            //rewrite factor because final_form return string instead of number
            denominator_factor: !_.isFinite(data.denominator_factor) ? null : data.denominator_factor,
            numerator_factor: !_.isFinite(data.numerator_factor) ? null : data.numerator_factor,
            denominator_factor_unit:
                data.denominator_factor_unit === -1 || !_.isFinite(data.denominator_factor) ? null : data.denominator_factor_unit,
            numerator_factor_unit: data.numerator_factor_unit === -1 || !_.isFinite(data.numerator_factor) ? null : data.numerator_factor_unit,
            sites: [data?.sites ?? null]
        };

        if (widget) {
            await updateWidget({ formdata: data, org: org.current, dashboard_id: current_dashboard.id });
        } else {
            await createWidget({ formdata: data, org: org.current, dashboard_id: current_dashboard.id });
        }
    };

    const title = i18n._(_.chain(WidgetTypeOptions).find({ value: widgetChoice }).get("text", "-").value());

    const MyHeader = () => (
        <Header attached="top" block style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {title} 
            <Popup position="bottom center" trigger={<Icon name="question circle" />}>
                <Segment basic style={{ width: "50vh" }}>
                    <Trans>Key performance indicator over a period, displayed in a stack bar chart</Trans>
                </Segment>
            </Popup>
        </Header>
    );

    const sites_id = useMemo(() => {
        return _.map(sites.data, (item) => item.id);
    }, [sites.data]);

    return (
        <>
            {(() => {
                if (_.some(err_list)) {
                    return (
                        <>
                            <MyHeader />
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={true}
                            />
                            <Segment attached textAlign="right" basic>
                                <Button type="button" negative onClick={() => onClose()}>
                                    <Trans>cancel</Trans>
                                </Button>
                            </Segment>
                        </>
                    );
                } else if (_.every(status_list)) {
                    return (
                        <Form
                            onSubmit={submitForm}
                            initialValues={initialValues}
                            render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                                const { aggregation, comparison } = values;

                                const filter_historic_options = _.chain(historic_options_widget)
                                    .filter((item) => ![0, 8].includes(item.value)) //exclude first choice 'yesterday' and last choice 'since midnight'
                                    .reduce((res, item) => {
                                        if (aggregation === 1) {
                                            if (item.value === 7) return res;
                                            if (comparison !== 0 && item.value === 6) return res;
                                        } else if (aggregation === 2) {
                                            if (item.value === 8) return res;
                                            //exclude choices lower than aggregation period
                                            if (item.value < 4) return res;
                                        } else if (aggregation === 3) {
                                            if (item.value === 8) return res;
                                            if (item.value < 6) return res;
                                        }
                                        res.push(item);
                                        return res;
                                    }, [])
                                    .value();

                                const not_visible_sites = values.id && !_.includes(sites_id, values.sites);

                                return (
                                    <form onSubmit={handleSubmit} className="ui form" style={{ marginTop: "1em" }}>
                                        <MyHeader />
                                        {!disabled && !values.sites && (
                                            <MessageDisplay message={i18n._(t`select site`)} level="info" iconName="info circle" isLoading={false} />
                                        )}
                                        <Segment attached>
                                            <Field
                                                name="name"
                                                label={i18n._(t`widget name`)}
                                                placeholder={i18n._(t`enter name of widget`)}
                                                disabled={disabled}
                                                isRequired={true}
                                                component={InputAdapter}
                                                validate={(value) => {
                                                    if (!disabled) {
                                                        if (!value) {
                                                            return <Trans>Required field</Trans>;
                                                        }
                                                    }
                                                    return undefined;
                                                }}
                                                onKeyDown={(e) => {
                                                    //Hack to prevent parent dropdown tabspace interaction
                                                    if (e.keyCode === 32) {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </Segment>

                                        <Header attached block textAlign="center">
                                            <Trans>Numerator section</Trans>
                                        </Header>
                                        <Segment attached>
                                            <Grid stackable centered verticalAlign="top">
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Field
                                                            name="numerator_unit"
                                                            disabled={disabled}
                                                            className="icon"
                                                            icon="fire"
                                                            button
                                                            labeled
                                                            search={false}
                                                            label={i18n._(t`select unit`)}
                                                            isRequired={true}
                                                            placeholder={i18n._(t`select unit`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={advancedUnitsOptions}
                                                            component={DropDownAdapter}
                                                            defaultValue={graphicTypeOptions[0].value}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!value) {
                                                                        return <Trans>Required field</Trans>;
                                                                    }
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="numerator_factor"
                                                            disabled={disabled}
                                                            placeholder={i18n._(t`enter numerator factor`)}
                                                            label={i18n._(t`numerator_factor`)}
                                                            component={InputAdapter}
                                                            parse={identityNull}
                                                            defaultValue={null}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    return validateNumber(value, i18n, false, true);
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="numerator_factor_unit"
                                                            label={i18n._(t`numerator_factor_unit`)}
                                                            isRequired={_.isFinite(values.numerator_factor)}
                                                            placeholder={i18n._(t`select numerator factor unit`)}
                                                            options={remapUnits} //exclude 'dynamic_unit'
                                                            component={DropDownAdapter}
                                                            disabled={disabled || !_.isFinite(reparseNumber(values.numerator_factor))}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="historic"
                                                            disabled={disabled}
                                                            className="icon"
                                                            icon="calendar"
                                                            button
                                                            labeled
                                                            label={i18n._(t`historic`)}
                                                            isRequired={true}
                                                            search={false}
                                                            placeholder={i18n._(t`historic`)}
                                                            options={filter_historic_options}
                                                            component={DropDownAdapter}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!value && value !== 0) return <Trans>Required field</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="aggregation"
                                                            disabled={disabled}
                                                            className="icon"
                                                            icon="object group"
                                                            button
                                                            labeled
                                                            search={false}
                                                            label={i18n._(t`aggregation`)}
                                                            options={aggregate_options}
                                                            component={DropDownAdapter}
                                                            placeholder={i18n._(t`select aggregation`)}
                                                            isRequired={true}
                                                            customAction={(data) => {
                                                                //reset historic choice based on aggregate && comparison
                                                                const historic = _.get(form.getFieldState("historic"), "value", 1);
                                                                const comparison = _.get(form.getFieldState("comparison"), "value", 0);
                                                                if (data === 1) {
                                                                    // day aggregation
                                                                    if (historic === 7 || (comparison !== 0 && historic === 6)) {
                                                                        form.change("historic", 1);
                                                                    }
                                                                } else if (data === 2) {
                                                                    //week aggregation
                                                                    if (historic < 4 || historic === 8) form.change("historic", 4);
                                                                } else if (data === 3) {
                                                                    //month aggregation
                                                                    if (historic < 6 || historic === 8) form.change("historic", 6);
                                                                }
                                                            }}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!value) return <Trans>Required field</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Field
                                                            label={i18n._(t`select comparison`)}
                                                            name="comparison"
                                                            className="icon"
                                                            disabled={disabled}
                                                            icon="law"
                                                            button
                                                            labeled
                                                            search={false}
                                                            placeholder={i18n._(t`select comparison`)}
                                                            options={comparison_options}
                                                            value={values.tags}
                                                            component={DropDownAdapter}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Field
                                                            name="equipment_name"
                                                            disabled={disabled}
                                                            label={i18n._(t`equipment name filter`)}
                                                            placeholder={i18n._(t`Enter a filter on the name of equipments`)}
                                                            component={InputAdapter}
                                                            parse={identity}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="sites"
                                                            disabled={disabled}
                                                            label={i18n._(t`select site`)}
                                                            placeholder={i18n._(t`select site`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={_.chain(sites.data)
                                                                .map(({ key, text, value }) => ({ key, text, value }))
                                                                .orderBy((item) => {
                                                                    return removeAccents(item.text).toLowerCase();
                                                                }, "asc")
                                                                .value()}
                                                            isRequired={true}
                                                            component={DropDownAdapter}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!_.isFinite(value)) return <Trans>Required field</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                            customAction={(data) => {
                                                                form.change("zones", []);
                                                                if (values.denominator === "measurement") {
                                                                    form.change("denominator_measurement", null);
                                                                }
                                                            }}
                                                        />
                                                        {not_visible_sites && (
                                                            <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                                <Trans>You do not have permission to view site</Trans>
                                                            </div>
                                                        )}
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            label={i18n._(t`Grouping`)}
                                                            name="group_by"
                                                            disabled={disabled}
                                                            className="icon"
                                                            icon="gitter"
                                                            button
                                                            labeled
                                                            search={false}
                                                            placeholder={i18n._(t`aggregate_type_placeholder`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={groupByOptions}
                                                            component={DropDownAdapter}
                                                            defaultValue={groupByOptions[0].value}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Divider horizontal>
                                                            <Trans>Additional filters</Trans>
                                                        </Divider>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="zones"
                                                            placeholder={i18n._(t`select zones`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={_.chain(zones.data)
                                                                .filter((item) => item?.site_id === values.sites)
                                                                .map(({ key, text, value }) => ({ key, text, value }))
                                                                .orderBy((item) => {
                                                                    return removeAccents(item.text).toLowerCase();
                                                                }, "asc")
                                                                .value()}
                                                            multipleselect={1}
                                                            disabled={disabled || !values.sites}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "teal",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="usages"
                                                            disabled={disabled}
                                                            placeholder={i18n._(t`select usages`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={_.chain(usages.data)
                                                                .map(({ key, text, value }) => ({ key, text, value }))
                                                                .orderBy((item) => {
                                                                    return removeAccents(item.text).toLowerCase();
                                                                }, "asc")
                                                                .value()}
                                                            multipleselect={1}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "violet",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="tags"
                                                            disabled={disabled}
                                                            placeholder={i18n._(t`select tags`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={_.chain(tags.data)
                                                                .map(({ key, text, value }) => ({ key, text, value }))
                                                                .orderBy((item) => {
                                                                    return removeAccents(item.text).toLowerCase();
                                                                }, "asc")
                                                                .value()}
                                                            multipleselect={1}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "grey",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="categories"
                                                            disabled={disabled}
                                                            placeholder={i18n._(t`select categories`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={filtered_cats}
                                                            multipleselect={1}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                return {
                                                                    ...defaultProps,
                                                                    color: "purple",
                                                                    content: i18n._(label.text)
                                                                };
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                        {/* Denominator  */}
                                        <Header attached block textAlign="center">
                                            <Trans>Denominator section</Trans>
                                        </Header>
                                        <Segment attached>
                                            <Grid stackable centered verticalAlign="top">
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="denominator"
                                                            disabled={disabled}
                                                            placeholder={i18n._(t`select denominator type`)}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={denominatorUnitsOptions}
                                                            component={DropDownAdapter}
                                                            defaultValue={graphicTypeOptions[0].value}
                                                        />
                                                    </Grid.Column>
                                                    {values?.denominator === "measurement" && (
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                name="denominator_measurement"
                                                                disabled={disabled || !values.sites}
                                                                placeholder={i18n._(t`choose your measurement here`)}
                                                                options={restricted_measurements}
                                                                component={DropdownAdapterMeasurement}
                                                                sites={sites.data}
                                                                usages={usages.data}
                                                                siteDisabled={true}
                                                                sites_filter={[values.sites]}
                                                                restricted_cats_id={restricted_cats_id}
                                                                displayAddBtn={false}
                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                open={false}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (!value) {
                                                                            return <Trans>Required field</Trans>;
                                                                        }
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="denominator_factor"
                                                            disabled={disabled}
                                                            placeholder={i18n._(t`enter denominator factor`)}
                                                            label={i18n._(t`denominator_factor`)}
                                                            component={InputAdapter}
                                                            parse={identityNull}
                                                            inputMode="decimal"
                                                            defaultValue={null}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    return validateNumber(value, i18n, false, true);
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="denominator_factor_unit"
                                                            label={i18n._(t`denominator_factor_unit`)}
                                                            placeholder={i18n._(t`select denominator factor unit`)}
                                                            options={remapUnits} //exclude 'dynamic_unit'
                                                            component={DropDownAdapter}
                                                            disabled={disabled || !_.isFinite(reparseNumber(values.denominator_factor))}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Accordion fluid styled>
                                                            <Accordion.Title
                                                                active={values.toogle_accordion || false}
                                                                onClick={() => form.change("toogle_accordion", !values.toogle_accordion)}
                                                            >
                                                                <Icon name="dropdown" />
                                                                <Trans>Threshold setup</Trans>
                                                            </Accordion.Title>
                                                            <Accordion.Content active={values.toogle_accordion || false}>
                                                                <Message info attached="top">
                                                                    <Trans>Thresholds are displayed on the graph</Trans>
                                                                </Message>
                                                                <Segment attached>
                                                                    <Field
                                                                        name="reference_level"
                                                                        disabled={disabled}
                                                                        placeholder={i18n._(t`enter reference level`)}
                                                                        label={i18n._(t`reference_level`)}
                                                                        component={InputAdapter}
                                                                        parse={identityNull}
                                                                        inputMode="decimal"
                                                                        defaultValue={null}
                                                                        validate={(value) => {
                                                                            if (!disabled) {
                                                                                return validateNumber(value, i18n, true, true);
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                    <Field
                                                                        name="threshold_min"
                                                                        disabled={disabled}
                                                                        placeholder={i18n._(t`enter threshold min`)}
                                                                        label={i18n._(t`threshold_min`)}
                                                                        component={InputAdapter}
                                                                        parse={identityNull}
                                                                        inputMode="decimal"
                                                                        defaultValue={null}
                                                                        validate={(value) => {
                                                                            if (!disabled) {
                                                                                return validateNumber(value, i18n, true, true);
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                    <Field
                                                                        name="threshold_max"
                                                                        disabled={disabled}
                                                                        placeholder={i18n._(t`enter threshold max`)}
                                                                        label={i18n._(t`threshold_max`)}
                                                                        component={InputAdapter}
                                                                        parse={identityNull}
                                                                        inputMode="decimal"
                                                                        defaultValue={null}
                                                                        validate={(value) => {
                                                                            if (!disabled) {
                                                                                return validateNumber(value, i18n, true, true);
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Segment>
                                                            </Accordion.Content>
                                                        </Accordion>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                        <Segment attached basic textAlign="right">
                                            <Button type="button" negative onClick={() => onClose()}>
                                                <Trans>cancel</Trans>
                                            </Button>
                                            {!disabled && (
                                                <Button
                                                    type="submit"
                                                    positive
                                                    icon
                                                    labelPosition="right"
                                                    disabled={submitting || pristine || invalid}
                                                >
                                                    <Icon name="check" />
                                                    <Trans>validate</Trans>
                                                </Button>
                                            )}
                                        </Segment>
                                    </form>
                                );
                            }}
                        />
                    );
                }
                return (
                    <>
                        <MyHeader />
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        <Segment attached textAlign="right" basic>
                            <Button type="button" negative onClick={() => onClose()}>
                                <Trans>cancel</Trans>
                            </Button>
                        </Segment>
                    </>
                );
            })()}
        </>
    );
};

export default StackBarRatio;
