import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { t } from "@lingui/macro";
import { Button, Grid, Label } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

import i18n from "modules/i18n/i18nConfig";
import { useGetCalendarExceptionsQuery } from "modules/calendar/calendarService";

import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import AddCalendarExceptionModalForm from "modules/calendar/component/AddCalendarExceptionModalForm";
import UpdateCalendarExceptionModalForm from "modules/calendar/component/UpdateCalendarExceptionModalForm";
import DeleteCalendarException from "modules/calendar/component/DeleteCalendarException";

const SiteCalendarExceptions = (props) => {
    const { id_site, active, org } = props;
    const current_lng = useSelector((state) => state.i18n.current);

    const exceptions = useGetCalendarExceptionsQuery(
        { org: org.current, id_site },
        { skip: !org.current || active === false || id_site === undefined }
    );

    const exeption_headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "day", label: i18n._(t`day`), textAlign: "left" },
        { id: "hour", label: i18n._(t`Opening hours`), textAlign: "left", ordering: false },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const renderHours = (hours) => {
        if (_.size(hours) === 0) {
            return (
                <MessageDisplay message={i18n._(t`Site closed this day`)} level="info" iconName="info circle" isLoading={false} attached={false} />
            );
        }
        return (
            <div style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "start" }}>
                {_.map(hours, (hour, idx) => {
                    return <Label size="large" style={{ marginRight: idx % 2 === 1 ? "20px" : "5px" }} key={idx} content={hour || "-"} />;
                })}
            </div>
        );
    };

    const local_exceptions = useMemo(() => {
        if (exceptions.isSuccess) {
            return exceptions.data;
        }
        return [];
    }, [exceptions]);

    const exeption_list = useMemo(() => {
        return _.chain(local_exceptions)
            .reduce((res, item) => {
                const customStyle = { cursor: "default", whiteSpace: "nowrap" };
                res.push({
                    ...item,
                    id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                    name: {
                        render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                        value: _.get(item, "name", "-"),
                        textAlign: "left",
                        datatype: "string"
                    },
                    day: {
                        render: (() => {
                            const day = moment(item.day, "YYYY-MM-DD", true);
                            if (day.isValid()) {
                                return <span style={{ customStyle }}>{day.locale(current_lng).format("L")}</span>;
                            }
                            return <span style={{ customStyle }}>-</span>;
                        })(),
                        value: moment(item.day, "YYYY-MM-DD", true),
                        textAlign: "left",
                        datatype: "date"
                    },
                    hour: {
                        render: renderHours(item.hours),
                        value: _.join(item.hours, "-"), //put string for ordering but here ordering is falsey (cf.header)
                        textAlign: "left",
                        datatype: "string"
                    },
                    actions: {
                        render: (() => (
                            <>
                                <Button.Group>
                                    {<UpdateCalendarExceptionModalForm {...props} item={item} exceptions={local_exceptions || []} />}
                                    {props.can_change && <DeleteCalendarException {...props} item={item} />}
                                </Button.Group>
                            </>
                        ))(),
                        value: null,
                        textAlign: "right",
                        datatype: null
                    }
                });
                return res;
            }, [])
            .value();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_lng, local_exceptions]);

    return (
        <Grid celled style={{ margin: 0 }}>
            {(exceptions.isFetching || exceptions.isFetching) && (
                <Grid.Column width={16}>
                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                </Grid.Column>
            )}
            {exceptions.isError && (
                <Grid.Column width={16}>
                    <MessageDisplay
                        message={i18n._(t`error loading data`)}
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                        attache={false}
                    />
                </Grid.Column>
            )}
            {exceptions.isSuccess && (
                <>
                    {props.can_change && (
                        <Grid.Column width={16}>
                            <AddCalendarExceptionModalForm {...props} exceptions={exceptions.data || []} />
                        </Grid.Column>
                    )}
                    <Grid.Column width={16}>
                        <TableEnhanced
                            headCells={exeption_headCells}
                            rows={exeption_list}
                            order="asc"
                            orderBy="day"
                            page={1}
                            rowsPerPage={10}
                            textItemPerPages={i18n._(t`items per page`)}
                        />
                    </Grid.Column>
                </>
            )}
        </Grid>
    );
};

export default SiteCalendarExceptions;
