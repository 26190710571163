import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Checkbox } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteEfficiencyMutation } from "modules/equipment/equipmentService";

import RequestErrorRender from "modules/common/components/RequestErrorRender";
import history_app from "history_app";

export const DeleteEfficiencyModal = (props) => {
    const { efficiency, setOpen } = props;
    const [confirm, setConfirm] = useState(false);
    const { org } = useSelector((state) => state);

    const [deleteEfficiency, deleted] = useDeleteEfficiencyMutation();

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete efficiency`), toast_options);
            setOpen(null);
            history_app.push(`/equipments/${efficiency?.equipment}#measurements`);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete efficiency`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [deleted, setOpen, efficiency]);

    return (
        <Modal
            centered={false}
            onClose={() => {
                setOpen(null);
            }}
            open
        >
            <Modal.Header>
                <Trans>want delete efficiency {efficiency?.name}</Trans>
            </Modal.Header>
            <Modal.Content>
                <Checkbox
                    label={
                        <label>
                            <Trans>yes, i confirm deletion</Trans>
                        </label>
                    }
                    onChange={(e, data) => setConfirm(data.checked)}
                    checked={confirm}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setOpen(null);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    type="button"
                    labelPosition="right"
                    icon
                    positive
                    onClick={(e) => {
                        deleteEfficiency({ org: org.current, equipment_id: efficiency?.equipment, data: efficiency.id, confirm: true });
                    }}
                    disabled={!confirm}
                >
                    <Icon name="check" />
                    <Trans>validate</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteEfficiencyModal;
