import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Segment, Grid, Header, Button, Input, Label, Icon, Dropdown, Popup } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude } from "modules/common/utils";
import { setSearchEmailFilter, setItemsPerPage, setPage, resetFilterWithPage, setSearchRoleFilter } from "../userSlice";
import { useGetUsersQuery } from "../userService";
import { isAdminRoleOptions } from "../utils";
import history_app from "history_app";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import LoginHistoryModal from "./LoginHistoryModal";
import MessageDisplay from "modules/common/components/MessageDisplay";

import DeleteUser from "./DeleteUser";

const Users = () => {
    const dispatch = useDispatch();
    const { user, org, auth, notification } = useSelector((state) => state);

    const users = useGetUsersQuery({ org: org.current }, { skip: !org.current });

    //Only admin can add users
    const can_add = notification.srv_status.db_status === "rw" && auth.rights?.is_admin;

    const renderIs2FA = (item) => {
        if (item?.has_otp) {
            return (
                <Label basic color="green">
                    {i18n._(t`2FA enabled`)}
                </Label>
            );
        }
        return (
            <Label basic color="red">
                {i18n._(t`2FA not configured`)}
            </Label>
        );
    };

    const renderIsAdmin = (item) => {
        if (item?.is_admin) {
            return <Icon name="checkmark" color="green" />;
        }
        return <Icon name="delete" color="red" />;
    };

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "is_admin", label: i18n._(t`is_admin`), textAlign: "left", collapsing: true },
        { id: "email", label: i18n._(t`email`), textAlign: "left" },
        { id: "first_name", label: i18n._(t`first_name`), textAlign: "left" },
        { id: "last_name", label: i18n._(t`last_name`), textAlign: "left" },
        { id: "has_otp", label: i18n._(t`has_otp`), textAlign: "left" },
        { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const users_list = _.chain(users.data)
        .reduce((res, item) => {
            // Remove current user. Only update with 'My account'
            if (item.id !== auth?.user?.user_id) res.push(item);
            return res;
        }, [])
        .reduce((res, item) => {
            if (user.filter.searchEmail === "") {
                res.push(item);
            } else if (checkStringInclude(user.filter.searchEmail, item.email)) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            switch (user.filter.searchRole) {
                case 1: {
                    res.push(item);
                    break;
                }
                case 2: {
                    if (item?.is_admin) res.push(item);
                    break;
                }
                case 3: {
                    if (!item?.is_admin) res.push(item);
                    break;
                }
                default: {
                    break;
                }
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const can_change = notification.srv_status.db_status === "rw" && (item?.id === auth.user.user_id || auth.rights?.is_admin);
            const can_delete = notification.srv_status.db_status === "rw" && item?.id !== auth.user.user_id && auth.rights?.is_admin;
            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                is_admin: {
                    render: <span style={customStyle}>{renderIsAdmin(item)}</span>,
                    value: +_.get(item, "is_admin", null),
                    textAlign: "left",
                    datatype: "number"
                },
                email: {
                    render: <span style={customStyle}>{_.get(item, "email", "-")}</span>,
                    value: _.get(item, "email", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                first_name: {
                    render: <span style={customStyle}>{_.get(item, "first_name", "-")}</span>,
                    value: _.get(item, "first_name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                last_name: {
                    render: <span style={customStyle}>{_.get(item, "last_name", "-")}</span>,
                    value: _.get(item, "last_name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                has_otp: {
                    render: <span style={customStyle}>{renderIs2FA(item)}</span>,
                    value: +_.get(item, "has_otp", null),
                    textAlign: "left",
                    datatype: "number"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Popup
                                    trigger={
                                        <Button
                                            icon={can_change ? "edit" : "eye"}
                                            onClick={async (e) => {
                                                history_app.push(`/users/${item.id}/${can_change ? "change" : "view"}`);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        />
                                    }
                                >
                                    <Popup.Content>
                                        {can_change && <Trans>Edit user</Trans>}
                                        {!can_change && <Trans>View user</Trans>}
                                    </Popup.Content>
                                </Popup>
                                {notification.srv_status.db_status === "rw" && can_delete && <DeleteUser item={item} />}
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>users management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {users.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {users.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {users.isSuccess && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <Grid celled>
                                <Grid.Column mobile={16} tablet={3} computer={3}>
                                    <Input
                                        fluid
                                        icon="search"
                                        placeholder={i18n._(t`search identifier`)}
                                        onChange={(e, { value }) => {
                                            dispatch(setSearchEmailFilter(value));
                                            dispatch(setPage(1));
                                        }}
                                        value={user.filter.searchEmail}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={3} computer={3}>
                                    <Dropdown
                                        fluid
                                        options={_.map(isAdminRoleOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                        placeholder={i18n._(t`select type of users`)}
                                        selection
                                        search
                                        onChange={(e, { value }) => {
                                            dispatch(setSearchRoleFilter(value));
                                            dispatch(setPage(1));
                                        }}
                                        value={user.filter.searchRole}
                                    />
                                </Grid.Column>
                                {can_add && (
                                    <Grid.Column width={16}>
                                        <Button
                                            icon
                                            labelPosition="left"
                                            onClick={async (e) => {
                                                await dispatch(resetFilterWithPage());
                                                history_app.push(`users/add`);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Icon name="add" />
                                            <Trans>add user</Trans>
                                        </Button>
                                        <LoginHistoryModal />
                                    </Grid.Column>
                                )}
                                <Grid.Column width={16}>
                                    <TableEnhanced
                                        headCells={headCells}
                                        rows={users_list}
                                        textItemPerPages={i18n._(t`items per page`)}
                                        page={user.pagination.page}
                                        rowsPerPage={user.pagination.itemsPerPage}
                                        setPage={(page) => {
                                            dispatch(setPage(page));
                                        }}
                                        setItemsPerPage={(items) => {
                                            dispatch(setItemsPerPage(items));
                                        }}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(Users);
