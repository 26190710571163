import commonReducer from "modules/common/reducer";
//Slice migration
import authReducer from "modules/auth/authSlice";
import i18nReducer from "modules/i18n/i18nSlice";
import sensorTypeReducer from "modules/sensor/sensorTypeSlice";
import sensorReducer from "modules/sensor/sensorSlice";
import siteReducer from "modules/site/siteSlice";
import orgReducer from "modules/organization/orgSlice";
import notificationReducer from "modules/notification/notificationSlice";
import unitReducer from "modules/unit/unitSlice";
import categoryReducer from "modules/category/categorySlice";
import areaReducer from "modules/area/areaSlice";
import usageReducer from "modules/usage/usageSlice";
import tagReducer from "modules/tag/tagSlice";
import equipmentReducer from "modules/equipment/equipmentSlice";
import equipmentTypeReducer from "modules/equipment/equipmentTypeSlice";
import dataflowReducer from "modules/dataflow/dataflowSlice";
import dashReducer from "modules/dashboard/dashboardSlice";
import exportReducer from "modules/export/exportSlice";
import mailingReducer from "modules/mailing/mailingSlice";
import measurementReducer from "modules/measurement/measurementSlice";
import overviewReducer from "modules/overview/overviewSlice";
import measurementtypeReducer from "modules/measurement/measurementtypeSlice";
import alertReducer from "modules/alert/alert/alertSlice";
import alertEventReducer from "modules/alert/alert_event/alerteventSlice";
import alertPackReducer from "modules/alert/alert_pack/alertPackSlice";
import alertPackTypeReducer from "modules/alert/alert_pack/alertPackTypeSlice";
import reportReducer from "modules/report/reportSlice";
import globalviewReducer from "modules/globalview/globalViewSlice";
import importsReducer from "modules/import/importSlice";
import apiImportsReducer from "modules/import/apiImportSlice";
import importedDataSetsReducer from "modules/importedDataset/importedDataSetsSlice";
import loginHistoryReducer from "modules/user/loginHistorySlice";
//predict part
import machineReducer from "modules/machine/machineSlice";
import machineTypeReducer from "modules/machine/machinetypeSlice";
import componentReducer from "modules/machine/componentSlice";
import componentTypeReducer from "modules/machine/componenttypeSlice";
import healthscoreTypeReducer from "modules/machine/healthscoretypeSlice";
import kinematicReducer from "modules/predict/cinematic/kinematicSlice";
import userReducer from "modules/user/userSlice";
import energySavingReducer from "modules/energysaving/energySavingSlice";
import dashboardDndSlice from "modules/dashboardDragNDrop/dashboardDndSlice";

export default {
    common: commonReducer,
    org: orgReducer,
    i18n: i18nReducer,
    auth: authReducer,
    user: userReducer,
    site: siteReducer,
    unit: unitReducer,
    sensor: sensorReducer,
    sensortype: sensorTypeReducer,
    notification: notificationReducer,
    category: categoryReducer,
    zone: areaReducer,
    tag: tagReducer,
    usage: usageReducer,
    equipment: equipmentReducer,
    equipmenttype: equipmentTypeReducer,
    dataflow: dataflowReducer,
    expt: exportReducer,
    mailing: mailingReducer,
    export: exportReducer,
    measurement: measurementReducer,
    measurementtype: measurementtypeReducer,
    overview: overviewReducer,
    alert: alertReducer,
    alertpack: alertPackReducer,
    alertpacktype: alertPackTypeReducer,
    report: reportReducer,
    globalview: globalviewReducer,
    imports: importsReducer,
    apiImports: apiImportsReducer,
    importedDataSets: importedDataSetsReducer,
    alertevent: alertEventReducer,
    loginHistory: loginHistoryReducer,
    //predict part
    machine: machineReducer,
    machinetype: machineTypeReducer,
    component: componentReducer,
    componenttype: componentTypeReducer,
    healthscoretype: healthscoreTypeReducer,
    kinematic: kinematicReducer,
    energysaving: energySavingReducer,
    dash: dashReducer,
    dashDnd: dashboardDndSlice
};
