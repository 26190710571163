import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Segment, Checkbox, Grid, Divider } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteEquipmentMeasurementMutation } from "../equipmentService";
import { deleteExclusion, modifiedExclusion } from "../utils";

import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

export const DeleteEquipmentMeasurement = (props) => {
    const { measurement, equipment, setOpen } = props;
    const [confirm, setConfirm] = useState(false);
    const org = useSelector((state) => state.org);

    const [deleteEquipmentMeasurement, deleted] = useDeleteEquipmentMeasurementMutation();
    const [waitingDeleteMeasurement, wDeleted] = useDeleteEquipmentMeasurementMutation();

    useEffect(() => {
        waitingDeleteMeasurement({ org: org.current, equipment_id: equipment?.id, measurement_id: measurement?.id });
    }, [waitingDeleteMeasurement, measurement, equipment, org, setOpen]);

    useEffect(() => {
        if (deleted.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete measurement`), toast_options);
            setOpen(null);
        }
        if (deleted.isError) {
            let error = i18n._(t`cannot delete measurement`);
            if (deleted.error?.data && !_.includes(deleted.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={deleted.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [setOpen, deleted]);

    const { delete_objects, modified_objects } = useMemo(() => {
        if (wDeleted.isSuccess) {
            const delete_objects = _.chain(wDeleted.data)
                .get("objects", {})
                .reduce((res, item, key) => {
                    if (!_.includes(deleteExclusion, key)) {
                        res.push(
                            <tr key={key}>
                                <td>{i18n._(key)}:</td>
                                <td>
                                    <strong>{item}</strong>
                                </td>
                            </tr>
                        );
                    }
                    return res;
                }, [])
                .value();
            const modified_objects = _.chain(wDeleted.data)
                .get("modified", {})
                .reduce((res, item, key) => {
                    if (!_.includes(modifiedExclusion, key)) {
                        res.push(
                            <tr key={key}>
                                <td>{i18n._(key)}:</td>
                                <td>
                                    <strong>{item}</strong>
                                </td>
                            </tr>
                        );
                    }
                    return res;
                }, [])
                .value();
            return { delete_objects, modified_objects };
        }
        return { delete_objects: [], modified_objects: [] };
    }, [wDeleted]);

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => {
                setOpen(null);
            }}
            open
        >
            <Modal.Header>
                <Trans>want delete measurement {measurement?.name}</Trans>
            </Modal.Header>
            <Modal.Content>
                {wDeleted.isError && (
                    <MessageDisplay
                        message={
                            <>
                                {/* check 404 not found to prevent incorrect message display */}
                                <Trans>You can't delete this measurement.</Trans>
                                <Divider />
                                {wDeleted.error?.originalStatus !== 404 && wDeleted?.error?.data !== undefined && (
                                    <RequestErrorRender errors={wDeleted.error?.data} />
                                )}
                            </>
                        }
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                    />
                )}
                {wDeleted.isFetching && (
                    <MessageDisplay message={i18n._(t`send request to server`)} level="info" iconName="info circle" isLoading={false} />
                )}
                {wDeleted.isSuccess && (
                    <>
                        <Segment basic>
                            <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                <Grid centered>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Trans>
                                                deleting the measurement leads to the deletion of several elements related to this measurement and all
                                                data <strong>will be erased</strong>
                                            </Trans>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {!_.isEmpty(modified_objects) && (
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Trans>Related data that will be modified:</Trans>
                                            </Grid.Column>
                                            <Grid.Column width={6}>
                                                <table>
                                                    <tbody>{modified_objects}</tbody>
                                                </table>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    {!_.isEmpty(delete_objects) && (
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Trans>Related data that will be deleted:</Trans>
                                            </Grid.Column>
                                            <Grid.Column width={6}>
                                                <table>
                                                    <tbody>{delete_objects}</tbody>
                                                </table>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </div>
                        </Segment>
                        <Segment basic>
                            <Checkbox
                                label={
                                    <label>
                                        <Trans>yes, i confirm deletion</Trans>
                                    </label>
                                }
                                onChange={(e, data) => setConfirm(data.checked)}
                                checked={confirm}
                            />
                        </Segment>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setOpen(null);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                {wDeleted.data?.objects && (
                    <Button
                        type="button"
                        labelPosition="right"
                        icon
                        positive
                        onClick={(e) => {
                            deleteEquipmentMeasurement({
                                org: org.current,
                                equipment_id: equipment?.id,
                                measurement_id: measurement?.id,
                                confirm: true
                            });
                        }}
                        disabled={!confirm}
                    >
                        <Icon name="check" />
                        <Trans>validate</Trans>
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

DeleteEquipmentMeasurement.propTypes = {
    measurement: PropTypes.object.isRequired,
    equipment: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default DeleteEquipmentMeasurement;
