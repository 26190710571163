import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import _ from "lodash";
import { Modal, Button, Icon, Grid, Divider, Popup, Segment, Table } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { roundedDate } from "modules/time/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useAddCalendarExceptionMutation } from "../calendarService";

import { InputAdapter, DateTimeAdapter, ToggleAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const AddCalendarExceptionModalForm = (props) => {
    const { id_site, can_change, exceptions } = props;
    const [open, setOpen] = useState(false);
    const { org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const [createException, create] = useAddCalendarExceptionMutation();

    // Create exception
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`New exception added`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            let error = i18n._(t`Can't add new exception`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
            setOpen(false);
        }
    }, [create]);

    const initialValues = useMemo(
        () => ({
            exception: {
                name: "",
                hours: [],
                day: moment().startOf("day")
            },
            isClosed: true
        }),
        []
    );

    const validate = (values) => {
        const errors = {};

        let exception = {};
        if (typeof values.exception.day === "string") {
            exception.day = <Trans>invalid format</Trans>;
            errors.exception = exception;
        } else {
            const existing_day = _.chain(exceptions)
                .find({ day: values.exception.day.format("YYYY-MM-DD") })
                .value();
            if (existing_day) {
                exception.day = <Trans>An exception with this day already exists</Trans>;
                errors.exception = exception;
            }
        }
        if (!values.isClosed) {
            if (_.size(values.exception.hours) === 0) {
                errors.global = <Trans>You need to set time ranges for an opening day</Trans>;
            } else {
                exception["hours"] = [];
                _.each(values.exception.hours, (time_range, idx, all_values) => {
                    exception["hours"][idx] = {};
                    if (time_range.start === undefined) {
                        exception["hours"][idx].start = <Trans>Invalid format</Trans>;
                        errors.exception = exception;
                        return false;
                    }
                    if (time_range.end === undefined) {
                        exception["hours"][idx].end = <Trans>Invalid format</Trans>;
                        errors.exception = exception;
                        return false;
                    } else if (time_range.end === "00:00" && idx !== _.size(all_values) - 1) {
                        exception["hours"][idx].end = <Trans>End time can't be 00:00</Trans>;
                        errors.exception = exception;
                        return false;
                    }
                    if (time_range.start >= time_range.end && time_range.end !== "00:00") {
                        exception["hours"][idx].start = <Trans>Start time must be before end time</Trans>;
                        errors.exception = exception;
                        return false;
                    }

                    if (idx > 0) {
                        const prev_range = all_values[idx - 1];
                        if (time_range.start === prev_range.end) {
                            exception["hours"][idx].start = <Trans>A start time must be different from an end time</Trans>;
                            errors.exception = exception;
                            return false;
                        } else if (time_range.start < prev_range.end) {
                            exception["hours"][idx].start = <Trans>2 opening time ranges can't overlap</Trans>;
                            errors.exception = exception;
                            return false;
                        }
                    }
                });
            }
        }
        return errors;
    };

    const submitForm = async (formData) => {
        if (can_change) {
            const { exception, isClosed } = formData;
            const remap_hours = isClosed
                ? []
                : _.reduce(
                      exception.hours,
                      (res, range, idx) => {
                          res.push(range.start);
                          if (idx === _.size(exception.hours) - 1 && range.end === "00:00") {
                              res.push(null);
                          } else {
                              res.push(range.end);
                          }
                          return res;
                      },
                      []
                  );

            const data = {
                ...exception,
                day: exception.day.format("YYYY-MM-DD"),
                site: id_site,
                hours: remap_hours
            };
            await createException({ org: org.current, id_site, data });
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Button disabled={!can_change} type="button" icon labelPosition="left">
                    <Icon name="add" />
                    <Trans>Add a new exception</Trans>
                </Button>
            }
        >
            <Modal.Header>
                <Trans>Add an exception</Trans>
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    mutators={{ ...arrayMutators }}
                    validate={validate}
                    render={({
                        handleSubmit,
                        form: {
                            mutators: { push, pop }
                        },
                        submitting,
                        pristine,
                        invalid,
                        values
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid>
                                    <Grid.Column width={16}>
                                        <Field
                                            name="exception.name"
                                            placeholder={i18n._(t`enter exception name`)}
                                            label={i18n._(t`name`)}
                                            isRequired={true}
                                            component={InputAdapter}
                                            validate={(value) => {
                                                const existing_name = _.chain(exceptions).find({ name: value }).value();

                                                if (existing_name) {
                                                    return <Trans>An exception with this name already exists</Trans>;
                                                }
                                                if (!value) {
                                                    return <Trans>Name is required</Trans>;
                                                }
                                                return undefined;
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column mobile={16} computer={6}>
                                        <Field
                                            name="exception.day"
                                            component={DateTimeAdapter}
                                            locale={current_lng}
                                            date_limit={null}
                                            labeled={false}
                                            label={i18n._(t`day`)}
                                            labelPosition={"left"}
                                            isRequired={true}
                                            dateFormat={true}
                                            timeFormat={false}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Field
                                            name="isClosed"
                                            label={i18n._(t`Site closed today ?`)}
                                            component={ToggleAdapter}
                                            disabled={!can_change}
                                        />
                                    </Grid.Column>
                                    {!values.isClosed && (
                                        <Segment basic attached style={{ overflowX: "auto" }}>
                                            <Table unstackable striped celled attached>
                                                <Table.Header fullWidth>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            <Trans>Opening hours</Trans>
                                                        </Table.HeaderCell>
                                                        {can_change && (
                                                            <Table.HeaderCell collapsing>
                                                                <Trans>actions</Trans>
                                                            </Table.HeaderCell>
                                                        )}
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <FieldArray name="exception.hours">
                                                            {(c_field) => {
                                                                return (
                                                                    <>
                                                                        <Table.Cell>
                                                                            {c_field.fields.length === 0 && (
                                                                                <MessageDisplay
                                                                                    message={i18n._(t`You need to add time ranges for this day`)}
                                                                                    level="info"
                                                                                    iconName="info circle"
                                                                                    isLoading={false}
                                                                                    attached={false}
                                                                                />
                                                                            )}
                                                                            {c_field.fields.length > 0 && (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        alignContent: "center",
                                                                                        justifyContent: "start"
                                                                                    }}
                                                                                >
                                                                                    {c_field.fields.map((c_name, c_index) => {
                                                                                        return (
                                                                                            <React.Fragment key={c_name}>
                                                                                                <div key={`${c_index}.start`}>
                                                                                                    <Field
                                                                                                        name={`${c_name}.start`}
                                                                                                        component={InputAdapter}
                                                                                                        type="time"
                                                                                                        style={{
                                                                                                            marginRight: "2px"
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div key={`${c_index}.end`}>
                                                                                                    <Field
                                                                                                        name={`${c_name}.end`}
                                                                                                        component={InputAdapter}
                                                                                                        type="time"
                                                                                                        style={{
                                                                                                            marginRight: "20px"
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            )}
                                                                        </Table.Cell>
                                                                        {can_change && (
                                                                            <Table.Cell collapsing>
                                                                                <Button.Group>
                                                                                    <Popup
                                                                                        on={"hover"}
                                                                                        content={i18n._(t`Add a new time range`)}
                                                                                        trigger={
                                                                                            <Button
                                                                                                disabled={!can_change}
                                                                                                type="button"
                                                                                                icon="add"
                                                                                                onClick={(e) => {
                                                                                                    push(`exception.hours`, {
                                                                                                        start: roundedDate(
                                                                                                            moment().startOf("d")
                                                                                                        ).format("HH:mm"),
                                                                                                        end: roundedDate(
                                                                                                            moment().startOf("d")
                                                                                                        ).format("HH:mm")
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                    <Popup
                                                                                        on={"hover"}
                                                                                        content={i18n._(t`Clear last time range`)}
                                                                                        trigger={
                                                                                            <Button
                                                                                                disabled={c_field.fields.length === 0 || !can_change}
                                                                                                negative
                                                                                                type="button"
                                                                                                icon="trash"
                                                                                                onClick={(e) => {
                                                                                                    pop(`exception.hours`);
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </Button.Group>
                                                                            </Table.Cell>
                                                                        )}
                                                                    </>
                                                                );
                                                            }}
                                                        </FieldArray>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Segment>
                                    )}
                                </Grid>
                                <Grid.Column>
                                    <Divider />
                                </Grid.Column>
                                <Grid>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            type="button"
                                            negative
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {can_change && (
                                            <Button type="submit" positive icon labelPosition="right" disabled={submitting || pristine || invalid}>
                                                <Icon name="check" />
                                                <Trans>validate</Trans>
                                            </Button>
                                        )}
                                    </Grid.Column>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default AddCalendarExceptionModalForm;
