import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { Grid, Header, Icon, Segment, Step } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { checkRights } from "modules/auth/utils";
import { resetKinematicForm } from "../kinematicSlice";

import Back from "modules/common/components/back";
import KinematicSupplyForm from "./KinematicSupplyForm";
import KinematicMotorForm from "./KinematicMotorForm";
import KinematicTransmissionForm from "./KinematicTransmissionForm";
import KinematicAdditionalBearingForm from "./KinematicAdditionalBearingForm";
import MessageDisplay from "modules/common/components/MessageDisplay";
import KinematicSubmitForm from "./KinematicSubmitForm";
import KinematicPumpForm from "./KinematicPumpForm";
import { Media } from "App";

const KinematicForm = (props) => {
    const urlParams = useParams();

    const { id_machine, mode } = useMemo(() => {
        if (_.isFinite(props?.id_machine)) {
            return { id_machine: props.id_machine, mode: props.mode };
        }
        return { id_machine: urlParams.id_machine, mode: urlParams.mode };
    }, [props, urlParams]);

    const dispatch = useDispatch();
    const { machine, kinematic, auth } = useSelector((state) => state);

    const current_machine = _.find(machine.machines, { id: parseInt(id_machine) });

    const rights = checkRights(current_machine, auth.rights);
    const is_predict = _.get(current_machine, "is_predict", null);
    const is_pump = _.includes([6, 7], _.get(current_machine, "machine_type"));

    useEffect(() => {
        return () => {
            //reset Kinematic form when we leave page
            dispatch(resetKinematicForm());
        };
    }, [dispatch]);

    //Declare all available steps
    //Set null based on same case predict and/or pump
    const stepListGlobal = [
        {
            stepHead: { icon: "bolt", title: i18n._(t`supply`), description: i18n._(t`supply information for machine`) },
            stepItem: <KinematicSupplyForm machine={current_machine} mode={mode} />
        },
        {
            stepHead: { icon: "medium", title: i18n._(t`motor`), description: i18n._(t`your motor description`) },
            stepItem: <KinematicMotorForm machine={current_machine} mode={mode} />
        },
        is_predict
            ? {
                  stepHead: { icon: "crop", title: i18n._(t`transmission`), description: i18n._(t`your transmission`) },
                  stepItem: <KinematicTransmissionForm machine={current_machine} mode={mode} />
              }
            : null,
        is_predict
            ? {
                  stepHead: { icon: "cogs", title: i18n._(t`additonal bearings`), description: i18n._(t`your additonal bearings`) },
                  stepItem: <KinematicAdditionalBearingForm machine={current_machine} mode={mode} is_pump={is_pump} />
              }
            : null,
        is_pump
            ? {
                  stepHead: { icon: "bolt", title: i18n._(t`pump`), description: i18n._(t`pump information for machine`) },
                  stepItem: <KinematicPumpForm machine={current_machine} mode={mode} />
              }
            : null,
        mode === "change" && _.includes(rights, "can_change")
            ? {
                  stepHead: { icon: "save", title: i18n._(t`registration`), description: i18n._(t`record your cinematic`) },
                  stepItem: <KinematicSubmitForm machine={current_machine} mode={mode} />
              }
            : null
    ];

    const stepList = _.chain(stepListGlobal)
        .filter((item) => item !== null)
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                {!_.isFinite(props?.id_machine) && (
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">{<Trans>kinematic edition</Trans>}</Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={14} computer={14}>
                        <Media lessThan="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Step.Group stackable="tablet" fluid>
                                        {_.map(stepList, (item, idx) => {
                                            return (
                                                <Step
                                                    key={idx}
                                                    active={kinematic.step === idx}
                                                    completed={kinematic.step > idx}
                                                    disabled={kinematic.step !== idx}
                                                >
                                                    <Icon name={item.stepHead.icon} />
                                                    <Step.Content>
                                                        <Step.Title>{item.stepHead.title}</Step.Title>
                                                        <Step.Description>{item.stepHead.description}</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                            );
                                        })}
                                    </Step.Group>
                                )
                            }
                        </Media>
                        <Media between={["computer", "wideScreen"]}>
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Step.Group stackable="tablet" fluid widths={_.size(stepList)}>
                                        {_.map(stepList, (item, idx) => {
                                            return (
                                                <Step
                                                    key={idx}
                                                    active={kinematic.step === idx}
                                                    completed={kinematic.step > idx}
                                                    disabled={kinematic.step !== idx}
                                                >
                                                    <Icon name={item.stepHead.icon} />
                                                    <Step.Content>
                                                        <Step.Title>{item.stepHead.title}</Step.Title>
                                                        <Step.Description>{item.stepHead.description}</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                            );
                                        })}
                                    </Step.Group>
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="wideScreen">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Step.Group stackable="tablet" fluid>
                                        {_.map(stepList, (item, idx) => {
                                            return (
                                                <Step
                                                    key={idx}
                                                    active={kinematic.step === idx}
                                                    completed={kinematic.step > idx}
                                                    disabled={kinematic.step !== idx}
                                                >
                                                    <Icon name={item.stepHead.icon} />
                                                    <Step.Content>
                                                        <Step.Title>{item.stepHead.title}</Step.Title>
                                                        <Step.Description>{item.stepHead.description}</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                            );
                                        })}
                                    </Step.Group>
                                )
                            }
                        </Media>
                    </Grid.Column>
                </Grid.Row>
                {!_.isUndefined(current_machine) && !_.isNull(is_predict) && (
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            {_.get(stepList, `[${kinematic.step}].stepItem`, null)}
                        </Grid.Column>
                    </Grid.Row>
                )}
                {(_.isUndefined(current_machine) || _.isNull(is_predict)) && (
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <MessageDisplay
                                message={i18n._(t`unknown machine`)}
                                level="warning"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default KinematicForm;
