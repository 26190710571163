import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Divider, Icon, Modal, Segment, Dropdown } from "semantic-ui-react";
import { Field, Form } from "react-final-form";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useUpdateDashboardMutation } from "../dashboardDndService";
import { identity } from "modules/common/utils/form";

import { InputAdapter, TextAreaAdapter, ToggleAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const DashboardUpdate = (props) => {
    const { current_dashboard, disabled, dashboards } = props;
    const { org } = useSelector((state) => state);
    const [openModal, setOpenModal] = useState(false);
    const [updateDashboard, update] = useUpdateDashboardMutation();

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update dashboard`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`Can't update dashboard`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const onSubmitForm = async (data, form) => {
        try {
            if (!disabled) {
                const action = await updateDashboard({ org: org.current, data });
                const error = _.get(action, "error", null);
                if (!error) {
                    resetForm(form);
                    setOpenModal(false);
                }
            }
        } catch (error) {}
    };

    const resetForm = (form) => {
        form.reset();
        form.getRegisteredFields().forEach((field) => {
            form.resetFieldState(field);
        });
    };

    const initialValues = useMemo(() => {
        return current_dashboard;
    }, [current_dashboard]);

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                return (
                    <Modal
                        open={openModal}
                        centered={false}
                        closeOnDimmerClick
                        closeOnEscape
                        onClose={(e) => {
                            resetForm(form);
                            setOpenModal(false);
                        }}
                        trigger={
                            <Dropdown.Item
                                onClick={(e) => {
                                    setOpenModal(true);
                                }}
                            >
                                <Icon name={disabled ? "eye" : "edit"} />
                                {!disabled && <Trans>Edit dashboard</Trans>}
                                {disabled && <Trans>View dashboard</Trans>}
                            </Dropdown.Item>
                        }
                    >
                        <Modal.Header>
                            {!disabled && <Trans>Edit dashboard</Trans>}
                            {disabled && <Trans>View dashboard</Trans>}
                        </Modal.Header>
                        <Modal.Content>
                            <form onSubmit={handleSubmit} className="ui form">
                                <Field
                                    name="name"
                                    placeholder={i18n._(t`enter name of dashboard`)}
                                    label={i18n._(t`name`)}
                                    isRequired={true}
                                    component={InputAdapter}
                                    validate={(value) => {
                                        if (!value) {
                                            return <Trans>dashboard name is required</Trans>;
                                        }
                                        const existing_name = _.chain(dashboards)
                                            .filter((dash) => {
                                                return dash.id !== current_dashboard?.id;
                                            })
                                            .find({ name: value })
                                            .value();

                                        if (existing_name) {
                                            return <Trans>Dashboard with this name exists</Trans>;
                                        }

                                        return undefined;
                                    }}
                                    onKeyDown={(e) => {
                                        //Hack to prevent parent dropdown tabspace interaction
                                        if (e.keyCode === 32) {
                                            e.stopPropagation();
                                        }
                                    }}
                                    disabled={disabled}
                                />
                                <Field
                                    name="description"
                                    placeholder={i18n._(t`enter description of dashboard`)}
                                    parse={identity}
                                    label={i18n._(t`description`)}
                                    component={TextAreaAdapter}
                                    disabled={disabled}
                                />
                                <Field
                                    name="is_public"
                                    label={i18n._(t`Public dashboard ?`)}
                                    labelposition="right"
                                    component={ToggleAdapter}
                                    disabled={disabled}
                                />
                                <Divider />
                                <Segment attached basic textAlign="right">
                                    <Button
                                        type="button"
                                        negative
                                        icon
                                        labelPosition="left"
                                        onClick={(e) => {
                                            resetForm(form);
                                            setOpenModal(false);
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                    {!disabled && (
                                        <Button positive icon labelPosition="right" type="submit" disabled={submitting || pristine || invalid}>
                                            <Icon name="send" />
                                            <Trans>Confirm</Trans>
                                        </Button>
                                    )}
                                </Segment>
                            </form>
                        </Modal.Content>
                    </Modal>
                );
            }}
        />
    );
};

export default React.memo(DashboardUpdate);
