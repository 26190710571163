import React from "react";
import { t, Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Grid, Icon, Button } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetTestImportMutation } from "../importService";

import MessageDisplay from "modules/common/components/MessageDisplay";

const ConnectionTest = (props) => {
    const { source } = props;
    const { org } = useSelector((state) => state);
    const [testConnection, test] = useGetTestImportMutation();

    return (
        <Grid stackable>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Icon color="grey" name="info circle" />
                    <Trans>Test the connection to the server with the information you provided beforehand</Trans>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Button
                        icon
                        labelPosition="left"
                        type="button"
                        compact
                        onClick={(e, data) => {
                            testConnection({ org: org.current, id: source });
                        }}
                    >
                        <Icon name="exchange" />
                        <Trans>Test connection to server</Trans>
                    </Button>
                </Grid.Column>
                <Grid.Column width={12}>
                    {test.isFetching && (
                        <MessageDisplay
                            message={i18n._(t`send request to server`)}
                            level="info"
                            iconName="circle notched"
                            isLoading={true}
                            attached={false}
                        />
                    )}
                    {test.isError && (
                        <MessageDisplay
                            message={i18n._(t`error loading data`)}
                            level="error"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    )}
                    {test.isSuccess && (
                        <MessageDisplay
                            {...(test.data?.result === "OK"
                                ? {
                                      level: "success",
                                      iconName: "server",
                                      message: i18n._(t`Connection test was successful.`)
                                  }
                                : {
                                      level: "error",
                                      iconName: "ban",
                                      message: i18n._(test.data?.detail)
                                  })}
                            isLoading={false}
                            attached={false}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ConnectionTest;
