import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { toast } from "react-toastify";
import moment from "moment";
import { Dropdown, Grid, Header, Icon, Segment, Menu } from "semantic-ui-react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHolding } from "@fortawesome/free-solid-svg-icons";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import { useGetSitesQuery } from "modules/site/siteService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetDiagramsQuery, usePatchDiagramMutation } from "../hierarchyService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";

import DiagramsChoice from "./DiagramsChoice";
import DiagramDelete from "./DiagramDelete";
import DiagramForm from "./DiagramForm";
import DiagramPanel from "./DiagramPanel";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

export const Hierarchy = (props) => {
    const bottomRef = useRef(null);
    const { id } = useParams();
    const { org, auth, notification } = useSelector((state) => state);
    const [selectedDiagram, setSelectedDiagram] = useState(parseInt(id));

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });

    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const diagrams = useGetDiagramsQuery({ org: org.current }, { skip: !org.current });
    const [changeDefaultDiagram, chg_default] = usePatchDiagramMutation();
    const [changeOwnershipDiagram, chg_owner] = usePatchDiagramMutation();

    useEffect(() => {
        if (chg_default.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (chg_default.isSuccess) {
            toast.success(i18n._(t`successful change default diagram`), toast_options);
        }
        if (chg_default.isError) {
            let error = i18n._(t`cannot change default diagram`);
            if (chg_default.error?.data && !_.includes(chg_default.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={chg_default.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [chg_default]);

    useEffect(() => {
        if (chg_owner.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (chg_owner.isSuccess) {
            toast.success(i18n._(t`successful change owner of diagram`), toast_options);
        }
        if (chg_owner.isError) {
            let error = i18n._(t`cannot change owner of diagram`);
            if (chg_owner.error?.data && !_.includes(chg_owner.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={chg_owner.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [chg_owner]);

    const err_list = [
        sites.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        categories.isError,
        units.isError,
        equipments.isError,
        dataflows.isError,
        measurementtypes.isError,
        measurements.isError,
        diagrams.isError
    ];

    const status_list = [
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        units.isSuccess,
        measurementtypes.isSuccess,
        equipments.isSuccess,
        dataflows.isSuccess,
        measurements.isSuccess,
        diagrams.isSuccess
    ];

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader no-print">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Hierarchy</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {(() => {
                    if (_.some(err_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else if (_.every(status_list)) {
                        const diags = diagrams?.data ?? []; //For semantic ui
                        const owner_diagrams = _.chain(diags).groupBy("owner_email").pick(auth.user.email).value();

                        const chain_wrapper = _.isEmpty(owner_diagrams)
                            ? _.chain(diags) //Display first natural analysis based on request (need ordering ? by id ?)
                            : _.chain(owner_diagrams).get(auth.user.email, []).orderBy("default_date", "desc"); //Display recent 'default_date' first is "owner"

                        const default_diagram_id = chain_wrapper.head().get("id").defaultTo(null).value();

                        const current_diagram = _.find(diags, { id: selectedDiagram || default_diagram_id });
                        const disabled_diagram = auth.user.email !== current_diagram?.owner_email;

                        return (
                            <>
                                <Grid.Row className="no-print">
                                    <Grid.Column width={2} />
                                    <Grid.Column width={12} textAlign="center">
                                        <Menu compact>
                                            <DiagramsChoice
                                                diagrams={diags}
                                                setSelectedDiagram={setSelectedDiagram}
                                                current_diagram={current_diagram}
                                                user_email={auth.user.email}
                                                id={id}
                                            />
                                            {notification.srv_status.db_status === "rw" && (
                                                <>
                                                    <Dropdown item icon="bars" simple>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Header>
                                                                - <Trans>Diagram</Trans> -
                                                            </Dropdown.Header>
                                                            {/* Diagram creation */}
                                                            <DiagramForm
                                                                current_diagram={null}
                                                                setSelectedDiagram={setSelectedDiagram}
                                                                sites={sites.data}
                                                            />
                                                            {current_diagram && (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={(e, data) => {
                                                                            if (!disabled_diagram)
                                                                                changeDefaultDiagram({
                                                                                    org: org.current,
                                                                                    data: {
                                                                                        ..._.pick(current_diagram, ["id", "default_date"]),
                                                                                        default_date: moment().toISOString()
                                                                                    }
                                                                                });
                                                                        }}
                                                                        disabled={disabled_diagram}
                                                                    >
                                                                        <Icon name="mouse pointer" />
                                                                        <Trans>Set diagram default</Trans>
                                                                    </Dropdown.Item>
                                                                    {/* Diagram update */}
                                                                    <DiagramForm
                                                                        current_diagram={current_diagram}
                                                                        disabled={disabled_diagram}
                                                                        sites={sites.data}
                                                                    />
                                                                    {!disabled_diagram && (
                                                                        <DiagramDelete
                                                                            org={org.current}
                                                                            current_diagram={current_diagram}
                                                                            disabled={disabled_diagram}
                                                                            setSelectedDiagram={setSelectedDiagram}
                                                                        />
                                                                    )}
                                                                    {auth.rights?.is_admin && current_diagram?.owner !== auth.user.user_id && (
                                                                        <Dropdown.Item
                                                                            onClick={(e, data) => {
                                                                                if (
                                                                                    auth.rights?.is_admin &&
                                                                                    current_diagram?.owner !== auth.user.user_id
                                                                                )
                                                                                    changeOwnershipDiagram({
                                                                                        org: org.current,
                                                                                        data: {
                                                                                            ..._.pick(current_diagram, ["id", "owner"]),
                                                                                            owner: auth.user?.user_id
                                                                                        }
                                                                                    });
                                                                            }}
                                                                            disabled={
                                                                                !(
                                                                                    auth.rights?.is_admin &&
                                                                                    current_diagram?.owner !== auth.user.user_id
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faHandHolding} className="icon" />
                                                                            <Trans>Take ownership of diagram</Trans>
                                                                        </Dropdown.Item>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )}
                                        </Menu>
                                    </Grid.Column>
                                    <Grid.Column width={2} />
                                </Grid.Row>
                                {id !== undefined && current_diagram === undefined && (
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                attached={false}
                                                level="warning"
                                                message={i18n._(t`The diagram does not exist or is not accessible`)}
                                                isLoading={false}
                                                iconName="info circle"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {current_diagram && (
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <DiagramPanel
                                                diagram={current_diagram}
                                                location={props.location}
                                                equipments={equipments.data}
                                                sites={sites.data}
                                                usages={usages.data}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </>
                        );
                    } else {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                })()}
            </Grid>
            <div ref={bottomRef} />
        </Segment>
    );
};

export default React.memo(Hierarchy);
